import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { Button, Form, Center } from 'components/atoms'
import { Input } from 'components/molecules'
import { FormattedMessage } from 'react-intl'
import { required } from 'redux-form-validators'

const validate = values => {
  let errors = {}
  if (!values.confirmationCode || values.confirmationCode.length !== 6) {
    errors.confirmationCode = 'Enter 6-digit confirmation code'
  }
  return errors
}

const ConfirmEmailFormUnwrapped = ({
  handleSubmit,
  loading,
  pristine,
  invalid,
}) => (
  <Form.Container>
    <Form onSubmit={handleSubmit}>
      <Form.Item>
        <Form.Label htmlFor="confirmationCode">
          <FormattedMessage id="form.confirmationCode" />
        </Form.Label>
        <Field
          component={Input}
          name="confirmationCode"
          type="text"
          testId="register-confirmation-code-input"
          validate={[required()]}
          iconType="user"
        />
      </Form.Item>
      <Form.Button>
        <Center>
          <Button
            type="submit"
            data-test-id="confirm-code-submit"
            disabled={pristine || invalid || loading}
          >
            <FormattedMessage id="button.submit" />
            {loading ? '...' : ''}
          </Button>
        </Center>
      </Form.Button>
    </Form>
  </Form.Container>
)

ConfirmEmailFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export const ConfirmEmailForm = reduxForm({
  form: 'confirm-email-form',
  validate,
  initialValues: {},
})(ConfirmEmailFormUnwrapped)
