import React from 'react'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { enMessages } from './en'

const messages = {
  en: enMessages,
}

const TranslationsProviderUwrapped = ({ children, lang }) => (
  <IntlProvider key={lang} locale="en" messages={messages[lang]}>
    {children}
  </IntlProvider>
)

export const TranslationsProvider = connect(
  state => ({
    lang: state.lang,
  }),
  null,
)(TranslationsProviderUwrapped)
