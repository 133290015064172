import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  align-items:${({ alignItems }) => alignItems || 'center'};
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`}
  width: ${({ width }) => width || '100%'};
   height: ${({ height }) => height || '100%'};
  ${({ gutter }) => gutter && `margin-bottom: ${gutter}px;`}
  & > div {
    margin-right: 8px;
  }
`

Row.Col = styled.div`
  width: ${({ size }) => (100 / 24) * (size || 24)}%;
`
