import React, { useState } from 'react'
import { FormProvider } from 'providers/FormProvider'
import { Icon, Button } from 'components/atoms'
import { Modal } from 'components/molecules'
import { UploadSystemConfigurationForm } from './UploadSystemConfigurationForm'

const applyMultipartPayload = data => {
  const formData = new FormData()
  formData.append('configuration', data.configuration)
  formData.append('comment', data.comment)
  return formData
}

export const UploadConfigurationModal = ({ refresh, systemId }) => {
  const [isOpen, onChange] = useState(false)
  return (
    <>
      <Button
        data-test-id="add-configuration-button"
        buttonType="primary"
        onClick={() => onChange(true)}
      >
        <Icon type="plus" /> Upload configuration
      </Button>
      <Modal
        title="Upload configuration"
        isOpen={isOpen}
        onRequestClose={() => {
          onChange(false)
        }}
      >
        {isOpen && (
          <FormProvider
            onSuccess={() => {
              refresh()
              onChange(false)
            }}
            url={`/v1/systems/${systemId}/configurations`}
            mapFormDataToPayload={applyMultipartPayload}
            multipart
            render={({ updating, handleSubmit }) => (
              <UploadSystemConfigurationForm
                onCancel={() => {
                  onChange(false)
                }}
                onSubmit={values => {
                  handleSubmit(values)
                }}
                loading={updating}
              />
            )}
          />
        )}
      </Modal>
    </>
  )
}
