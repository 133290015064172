export const ModemSystemState = {
  MobileConnectionOk: 'mobile_connection_ok',
  MobileConnectionFailed: 'mobile_connection_failed',
  PanelConfigOk: 'panel_config_ok',
  PanelConfigFailed: 'panel_config_failed',
  LocalNetworkConfigurationOk: 'local_network_config_ok',
  LocalNetworkConfigurationFailed: 'local_network_config_failed',
  SystemConnected: 'system_connected',
  SystemDisconnected: 'system_disconnected',
}
