import React from 'react'
import { MainLayout } from 'layout/MainLayout'
import { Redirect } from 'react-router-dom'
import {
  SizedBox,
  Field,
  Link,
  Address,
  IconDropdown,
  ConfirmationModal,
  Row,
  Divider,
} from 'components/atoms'
import { Tabs } from 'components/molecules/Tabs/Tabs'
import { withRouter } from 'react-router-dom'
import { SiteAccess } from './SiteAccess/SiteAccess'
import { SiteSystems } from './SiteSystems/SiteSystems'
import { SiteModems } from './SiteModems/SiteModems'
import { withSite, deleteSite } from 'api'
import { formatDateTime } from 'utils/formatters'
import { EditSite } from './EditSite/EditSite'
import { AccessLevels } from 'utils/AccesLevels'
import { Protected } from 'components/atoms/Protected/Protected'
import { updateInfo } from 'rdx/actions/authActions'
import { withUserUpdater } from 'api'
import { connect } from 'react-redux'
import { SiteScannedSystems } from './SiteScannedSystems/SiteScannedSystems'
import { isTwoAdressessEqual } from 'utils/helpers'

const SiteTabs = {
  Invitations: '0',
  Systems: '1',
  Modems: '2',
  LoopSystems: '3',
  Edit: '4',
}

export const SitePage = connect(null, { updateInfo })(
  withRouter(({ match, history, updateInfo }) => {
    withUserUpdater(updateInfo)

    const {
      isInitialLoading,
      isLoading,
      refresh,
      pick,
      errorStatus,
    } = withSite(match.params.id)
    const tab = (window.location.hash || SiteTabs.Invitations).replace('#', '')
    const setIndex = value => (window.location.hash = value)
    if (errorStatus) {
      return <Redirect to={`/${errorStatus}`} />
    }
    return (
      <MainLayout
        isLoading={isInitialLoading}
        header={pick(['name']) || pick(['number'])}
        extra={
          <Protected
            resourceId={match.params.id}
            accessLevel={AccessLevels.Admin}
          >
            <IconDropdown
              type={'cog'}
              options={[
                {
                  label: 'button.delete',
                  action: () => {
                    ConfirmationModal.show({
                      title: 'Are you sure?',
                      text: 'Are you sure you want to delete this site?',
                      onSubmit: async () => {
                        const { error } = await deleteSite(pick(['id']))
                        if (!error) {
                          history.push('/sites')
                        } else {
                          throw error
                        }
                      },
                    })
                  },
                },
              ]}
            />
          </Protected>
        }
      >
        <SizedBox height="2" />
        <Divider />
        <SizedBox height="1" />
        <Row>
          {pick(['name']) !== pick(['previousName']) && (
            <Field title="Previous name">{pick(['previousName']) || '-'}</Field>
          )}
          {!!pick(['name']) && (
            <Field title="Identifier">{pick(['number'])}</Field>
          )}
          <Field title="Number">{pick(['siteNumber']) || '-'}</Field>
          <Field title="Description">{pick(['description']) || '-'}</Field>
          <Field title="Address">
            <Address address={pick(['address'])} />
          </Field>
          {!isTwoAdressessEqual(
            pick(['address']),
            pick(['previousAddress']),
          ) && (
            <Field title="Previous address">
              <Address address={pick(['previousAddress'])} />
            </Field>
          )}
          <Field title="Created by">
            <Link to={`/users/${pick(['createdById'])}`}>
              {pick(['createdBy', 'emailAddress'])}
            </Link>
          </Field>
          <Field title="Created at">
            {formatDateTime(pick(['createdAt']))}
          </Field>
          <Field title="Comment">{pick(['comment']) || '-'}</Field>
        </Row>
        <Divider />
        <SizedBox height="3" />
        <Tabs>
          <Tabs.Tab
            onClick={() => setIndex(SiteTabs.Invitations)}
            selected={tab === SiteTabs.Invitations}
            testId="invitations-tab"
          >
            Invitations
          </Tabs.Tab>
          <Tabs.Tab
            onClick={() => setIndex(SiteTabs.Systems)}
            selected={tab === SiteTabs.Systems}
            testId="systems-tab"
          >
            Systems
          </Tabs.Tab>
          <Tabs.Tab
            onClick={() => setIndex(SiteTabs.Modems)}
            selected={tab === SiteTabs.Modems}
            testId="modems-tab"
          >
            Modems
          </Tabs.Tab>
          <Tabs.Tab
            onClick={() => setIndex(SiteTabs.LoopSystems)}
            selected={tab === SiteTabs.LoopSystems}
            testId="systems-tab"
          >
            Loop systems
          </Tabs.Tab>
          <Protected
            resourceId={match.params.id}
            accessLevel={AccessLevels.Admin}
          >
            <Tabs.Tab
              onClick={() => setIndex(SiteTabs.Edit)}
              selected={tab === SiteTabs.Edit}
              testId="edit-tab"
            >
              Edit
            </Tabs.Tab>
          </Protected>
        </Tabs>
        {tab === SiteTabs.Invitations && (
          <SiteAccess siteId={match.params.id} />
        )}
        {tab === SiteTabs.Systems && <SiteSystems siteId={match.params.id} />}
        {tab === SiteTabs.Modems && <SiteModems siteId={match.params.id} />}
        {tab === SiteTabs.LoopSystems && (
          <SiteScannedSystems siteId={match.params.id} />
        )}
        {tab === SiteTabs.Edit && !isLoading && (
          <Protected
            resourceId={match.params.id}
            accessLevel={AccessLevels.Admin}
          >
            <EditSite refresh={refresh} siteId={match.params.id} pick={pick} />
          </Protected>
        )}
      </MainLayout>
    )
  }),
)
