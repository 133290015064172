import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field, reduxForm } from 'redux-form'
import { required } from 'redux-form-validators'
import { Form, Button, SizedBox } from 'components/atoms'
import { Input } from 'components/molecules'
import { ScannedInputLimits } from 'utils/InputLimits'

const validate = values => {
  let errors = {}
  if (
    values.systemName &&
    values.systemName.length > ScannedInputLimits.NameMaxLength
  ) {
    errors.systemName = `Name is too long (should be at max ${ScannedInputLimits.NameMaxLength} characters)`
  }
  return errors
}

const AddScannedSystemFormUnwrapped = ({
  handleSubmit,
  loading,
  pristine,
  invalid,
  onCancel,
}) => (
  <Form
    onSubmit={handleSubmit}
    description={
      <FormattedMessage id="sites.scanned.systems.add.form.description" />
    }
  >
    <SizedBox height="1" />
    <Form.Item width="60%">
      <Form.Label>
        <FormattedMessage id="form.scanned.system.name" />
      </Form.Label>
      <Field
        component={Input}
        name="systemName"
        validate={[required()]}
        testId="site-scanned-system-add-form-name-input"
      />
    </Form.Item>
    <Form.Buttons>
      <Button
        buttonType="secondary"
        onClick={onCancel}
        data-test-id="cancel-button"
      >
        <FormattedMessage id="button.cancel" />
      </Button>
      <Button
        type="submit"
        disabled={pristine || invalid || loading}
        data-test-id="site-system-add-form-submit"
      >
        <FormattedMessage id="site.details.scanned.system.add" />
        {loading ? '...' : ''}
      </Button>
    </Form.Buttons>
  </Form>
)

AddScannedSystemFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export const AddScannedSystemForm = reduxForm({
  form: 'add-scanned-system-form',
  validate,
})(AddScannedSystemFormUnwrapped)
