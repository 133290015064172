import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

export const PrivateRouteUnwrapped = ({
  component: Component,
  isAuthorized,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isAuthorized ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
)

const mapStateToProps = state => ({
  ...state.authentication,
})

export const PrivateRoute = connect(
  mapStateToProps,
  null,
)(PrivateRouteUnwrapped)
