import React from 'react'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import { MainLayout } from 'layout/MainLayout'
import { Error, Link, SizedBox } from 'components/atoms'
import { Table } from 'components/organisms'
import { UserStatus } from 'components/atoms'
import { withUserSystems } from 'api'
import { connect } from 'react-redux'

const columns = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Number',
    key: 'number',
  },
  {
    title: 'Site',
    render: record => (
      <Link to={`/sites/${record.siteId}`}>{record.siteName || 'Site'}</Link>
    ),
    externalLink: true,
  },
  {
    title: 'Issues count',
    key: 'issuesCount',
  },
  {
    title: 'Status',
    key: 'status',
    render: record => <UserStatus status={record.status} />,
  },
]

export const SystemsList = compose(
  withRouter,
  connect(state => ({
    userId: state.authentication.user.id,
  })),
)(({ userId, history }) => {
  const { data, isLoading, error, ...rest } = withUserSystems(userId)()
  return (
    <MainLayout header="Systems">
      <SizedBox height="1" />
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns}
        data={data}
        history={history}
        linkPath={'/systems'}
        {...rest}
      />
    </MainLayout>
  )
})
