import React from 'react'
import { Tag } from '../Tag/Tag'

export const UserStatus = ({ status }) => {
  switch (status) {
    case 'ANONYMOUS':
      return <Tag>Anonymous</Tag>
    case 'BANNED':
      return <Tag>Banned</Tag>
    case 'DELETED':
      return <Tag>Deleted</Tag>
    case 'ACTIVE':
      return <Tag>Active</Tag>
    case 'REGISTRATION':
      return <Tag>Registration</Tag>
    default:
      return <Tag>{status}</Tag>
  }
}
