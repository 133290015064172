import React from 'react'
import styled from 'styled-components'
import { ColorFireRed } from 'style/design-tokens'

const Container = styled.div`
  display: flex;
  justify-content: center;
  color: ${ColorFireRed};
  padding: 1rem 0;
`

const errorMessages = {
  'user.missing_permissions': 'You don\'t have permissions to view this content',
}

export const Error = ({ error }) =>
  error ? <Container>{errorMessages[error] || error}</Container> : null
