import React from 'react'
import { Redirect } from 'react-router-dom'
import { formatDateTime } from 'utils/formatters'
import { AccessLevels } from 'utils/AccesLevels'
import { withSystem, archiveSystem, deleteSystem } from 'api'
import { MainLayout } from 'layout/MainLayout'
import {
  Field,
  Spacing,
  SizedBox,
  Tag,
  IconDropdown,
  ConfirmationModal,
  Link,
  Protected,
  Row,
  Divider,
} from 'components/atoms'
import { Tabs } from 'components/molecules/Tabs/Tabs'
import { withRouter } from 'react-router-dom'
import { SystemIssues } from './SystemIssues/SystemIssues'
import { SystemConfigurations } from './SystemConfigurations/SystemConfigurations'
import { SystemOperators } from './SystemOperators/SystemOperators'
import { SystemAccess } from './SystemAccess/SystemAccess'
import { EditSystem } from './EditSystem/EditSystem'
import { connect } from 'react-redux'

const SystemTabs = {
  Issues: '0',
  Configurations: '1',
  Operators: '2',
  Invitations: '3',
  Edit: '4',
}

export const SystemPage = connect(state => ({
  user: state.authentication.user,
}))(
  withRouter(({ user, match, history }) => {
    const {
      isInitialLoading,
      pick,
      refresh,
      isLoading,
      errorStatus,
    } = withSystem(match.params.id)
    const tab = (window.location.hash || SystemTabs.Issues).replace('#', '')
    const setIndex = value => (window.location.hash = value)
    const isArchived = formatDateTime(pick(['archivedAt'])) !== '-'
    if (errorStatus) {
      return <Redirect to={`/${errorStatus}`} />
    }

    const settingsOptions = []
    const isSuper = user.permissions.isSuperUser
    const isOperator =
      user.permissions.globalAccessLevel >= AccessLevels.Operator &&
      user.permissions.resources[pick(['id'])] >= AccessLevels.Operator
    const isAdmin =
      user.permissions.globalAccessLevel >= AccessLevels.Admin &&
      user.permissions.resources[pick(['id'])] >= AccessLevels.Admin
    const issuesCount = Number(pick(['issuesCount']) ?? 0)
    const configurationsCount = Number(pick(['configurationsCount']) ?? 0)

    const canSeeDeleteButton =
      isSuper || (isOperator && issuesCount === 0 && configurationsCount === 0)
    const canSeeArchiveButton = isSuper || isAdmin

    if (canSeeArchiveButton) {
      settingsOptions.push({
        label: 'button.archive',
        action: () => {
          ConfirmationModal.show({
            title: 'Are you sure?',
            text: 'Are you sure you want to archive this system?',
            onSubmit: async () => {
              const { error } = await archiveSystem(pick(['id']))
              if (!error) {
                history.push('/systems')
              } else {
                throw error
              }
            },
          })
        },
      })
    }
    if (canSeeDeleteButton) {
      settingsOptions.push({
        label: 'button.delete',
        action: () => {
          let text = 'Are you sure you want to delete this system?'
          if (issuesCount > 0 || configurationsCount > 0) {
            text = `This system has ${issuesCount} issues and ${configurationsCount} configurations. Are you sure you want to delete it?`
          }
          ConfirmationModal.show({
            title: 'Are you sure?',
            text,
            onSubmit: async () => {
              const { error } = await deleteSystem(pick(['id']))
              if (!error) {
                history.push('/systems')
              } else {
                throw error
              }
            },
          })
        },
      })
    }
    return (
      <MainLayout
        isLoading={isInitialLoading}
        header={
          <>
            {pick(['name']) || pick(['number'])}
            <Spacing.Span ml="1">
              <Tag>{pick(['status'])}</Tag>
            </Spacing.Span>
          </>
        }
        extra={
          settingsOptions.length > 0 ? (
            <IconDropdown type={'cog'} options={settingsOptions} />
          ) : null
        }
      >
        <SizedBox height="2" />
        <Divider />
        <SizedBox height="1" />
        <Row>
          <Field title="Equipment number">{pick(['equipmentNumber'])}</Field>
          {pick(['name']) !== pick(['previousName']) && (
            <Field title="Previous name">{pick(['previousName']) || '-'}</Field>
          )}
          <Field title="Identifier">{pick(['number'])}</Field>
          <Field title="Type">{pick(['type'])}</Field>
          <Field title="Site">
            <Link to={`/sites/${pick(['siteId'])}`}>
              {pick(['siteName']) || 'Site'}
            </Link>
          </Field>
          <Field title="Created at">
            {formatDateTime(pick(['createdAt']))}
          </Field>
          <Field title="Issues">{pick(['issuesCount'])}</Field>
          <Field title="Comment">{pick(['comment'])}</Field>
        </Row>
        <Divider />
        <SizedBox height="3" />
        <Tabs>
          <Tabs.Tab
            onClick={() => setIndex(SystemTabs.Issues)}
            selected={tab === SystemTabs.Issues}
            testId="issues-tab"
          >
            Issues
          </Tabs.Tab>
          <Tabs.Tab
            onClick={() => setIndex(SystemTabs.Configurations)}
            selected={tab === SystemTabs.Configurations}
            testId="configurations-tab"
          >
            Configurations
          </Tabs.Tab>
          <Tabs.Tab
            onClick={() => setIndex(SystemTabs.Operators)}
            selected={tab === SystemTabs.Operators}
            testId="operators-tab"
          >
            Operators
          </Tabs.Tab>
          <Tabs.Tab
            onClick={() => setIndex(SystemTabs.Invitations)}
            selected={tab === SystemTabs.Invitations}
            testId="invitations-tab"
          >
            Invitations
          </Tabs.Tab>
          {!isArchived ? (
            <Protected
              resourceId={match.params.id}
              accessLevel={AccessLevels.Admin}
            >
              <Tabs.Tab
                onClick={() => setIndex(SystemTabs.Edit)}
                selected={tab === SystemTabs.Edit}
                testId="edit-tab"
              >
                Edit
              </Tabs.Tab>
            </Protected>
          ) : null}
        </Tabs>
        {tab === SystemTabs.Issues && (
          <SystemIssues systemId={match.params.id} />
        )}
        {tab === SystemTabs.Configurations && (
          <SystemConfigurations
            systemId={match.params.id}
            refresh={refresh}
            isArchived={isArchived}
            systemType={pick(['type'])}
          />
        )}
        {tab === SystemTabs.Operators && (
          <SystemOperators
            operators={pick(['operators'], [])}
            systemId={match.params.id}
            refresh={refresh}
          />
        )}
        {tab === SystemTabs.Invitations && !isLoading && (
          <SystemAccess
            refresh={refresh}
            systemId={pick(['id'])}
            isArchived={isArchived}
            pick={pick}
          />
        )}
        {tab === SystemTabs.Edit && !isLoading && !isArchived && (
          <EditSystem refresh={refresh} systemId={pick(['id'])} pick={pick} />
        )}
      </MainLayout>
    )
  }),
)
