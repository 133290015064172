import React from 'react'
import { store } from 'rdx/store'
import { reset } from 'redux-form'
import { Spacing, Typography, Divider } from 'components/atoms'
import { FormProvider } from 'providers/FormProvider'
import { EditSiteForm } from './EditSiteForm'

const getInitialValue = pick => ({
  name: pick(['name']),
  comment: pick(['comment']),
  siteNumber: pick(['siteNumber']),
})

export const EditSite = ({ refresh, siteId, pick }) => (
  <>
    <Divider />
    <Spacing width="100%" mt="3" ml="2">
      <Typography.H3>Details</Typography.H3>
      <FormProvider
        method="put"
        onSuccess={() => {
          refresh(() => {
            store.dispatch(reset('edit-site-form'))
          })
        }}
        url={`/v1/sites/${siteId}`}
        render={({ updating, handleSubmit }) => (
          <EditSiteForm
            loading={updating}
            onSubmit={handleSubmit}
            initialValues={getInitialValue(pick)}
          />
        )}
      />
    </Spacing>
  </>
)
