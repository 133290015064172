import React, { useEffect } from 'react'
import QRCode from 'qrcode'
import { Field, Spacing, Divider, Link } from 'components/atoms'
import { formatDateTime } from 'utils/formatters'
import { Row, Col } from 'react-grid-system'
import { ModemLocationMap } from './ModemLocationMap/ModemLocationMap'

export const ModemDetails = ({ pick }) => {
  const text = `<IMEI>${pick(['imei'])}</IMEI><ICCID>${pick([
    'iccid',
  ])}</ICCID><IMSI>${pick(['imsi'])}</IMSI><WGIP>${pick([
    'wgip',
  ])}</WGIP><CLIENTID>${pick(['clientId'])}</CLIENTID><TS>${pick(['ts'])}</TS>`
  useEffect(() => {
    QRCode.toCanvas(document.getElementById('qr-code-canvas'), text, function(
      error,
    ) {
      if (error) console.error(error)
    })
  }, [text])
  return (
    <>
      <Divider />
      <Row style={{ width: '100%' }}>
        <Col md={4}>
          <Spacing width="100%" mt="3" ml="2">
            <Field title="Number">{pick(['number'])}</Field>
            <Field title="Site">
              <Link to={`/sites/${pick(['siteId'])}`}>
                {pick(['siteName'])}
              </Link>
            </Field>
            <Field title="Registered at">
              {formatDateTime(pick(['createdAt']))}
            </Field>
            <Field title="Registered by">
              <Link to={`/users/${pick(['createdById'])}`}>
                {pick(['createdBy'])}
              </Link>
            </Field>
            <Field title="Topic">{pick(['topic'])}</Field>
            <Field title="Client Id">{pick(['clientId'])}</Field>
            <Field title="ICCID">{pick(['iccid'])}</Field>
            <Field title="IMEI">{pick(['imei'])}</Field>
            <Field title="IMSI">{pick(['imsi'])}</Field>
            <Field title="WGIP">{pick(['wgip'])}</Field>
            <Field title="TS">{pick(['ts'])}</Field>
          </Spacing>
          <Spacing ml="2" mt="2">
            <canvas id="qr-code-canvas" />
          </Spacing>
        </Col>
        <Col md={8}>
          <Spacing mt="5">
            {pick(['lat']) && pick(['lng']) && (
              <ModemLocationMap lat={pick(['lat'])} lng={pick(['lng'])} />
            )}
          </Spacing>
        </Col>
      </Row>
    </>
  )
}
