import React from 'react'
import { Redirect } from 'react-router-dom'
import { MainLayout } from 'layout/MainLayout'
import { SizedBox, IconDropdown, ConfirmationModal } from 'components/atoms'
import { Tabs } from 'components/molecules/Tabs/Tabs'
import { ModemDetails } from './ModemDetails/ModemDetails'
import { EditModem } from './EditModem/EditModem'
import { withModem, deleteModem } from 'api'
import { withRouter } from 'react-router-dom'
import { ModemConfiguration } from './ModemConfiguration/ModemConfiguration'
import { Protected } from 'components/atoms/Protected/Protected'
import { AccessLevels } from 'utils/AccesLevels'

const ModemTabs = {
  Details: '0',
  Configuration: '1',
  Edit: '2',
}

export const ModemPage = withRouter(({ match, history }) => {
  const {
    isInitialLoading,
    isLoading,
    pick,
    data,
    refresh,
    errorStatus,
  } = withModem(match.params.id)
  const tab = (window.location.hash || ModemTabs.Details).replace('#', '')
  const setIndex = value => (window.location.hash = value)
  if (errorStatus) {
    return <Redirect to={`/${errorStatus}`} />
  }
  return (
    <MainLayout
      isLoading={isInitialLoading}
      header={<>Modem in {pick(['siteName'])}</>}
      extra={
        <Protected
          resourceId={match.params.id}
          accessLevel={AccessLevels.Admin}
        >
          <IconDropdown
            type={'cog'}
            options={[
              {
                label: 'button.delete',
                action: () => {
                  ConfirmationModal.show({
                    title: 'Are you sure?',
                    text: 'Are you sure you want to delete this modem?',
                    onSubmit: async () => {
                      const { error } = await deleteModem(pick(['id']))
                      if (!error) {
                        history.push('/modems')
                      } else {
                        throw error
                      }
                    },
                  })
                },
              },
            ]}
          />
        </Protected>
      }
    >
      <SizedBox height="1" />
      <Tabs>
        <Tabs.Tab
          onClick={() => setIndex(ModemTabs.Details)}
          selected={tab === ModemTabs.Details}
          testId="details-tab"
        >
          Details
        </Tabs.Tab>
        <Tabs.Tab
          onClick={() => setIndex(ModemTabs.Configuration)}
          selected={tab === ModemTabs.Configuration}
          testId="configuration-tab"
        >
          Configuration
        </Tabs.Tab>
        <Protected accessLevel={AccessLevels.Admin} resourceId={pick['siteId']}>
          <Tabs.Tab
            onClick={() => setIndex(ModemTabs.Edit)}
            selected={tab === ModemTabs.Edit}
            testId="edit-tab"
          >
            Edit
          </Tabs.Tab>
        </Protected>
      </Tabs>
      {tab === ModemTabs.Details && <ModemDetails modem={data} pick={pick} />}
      {tab === ModemTabs.Configuration && (
        <ModemConfiguration
          refresh={refresh}
          modemId={match.params.id}
          pick={pick}
        />
      )}
      {tab === ModemTabs.Edit && !isLoading && (
        <EditModem modemId={match.params.id} refresh={refresh} pick={pick} />
      )}
    </MainLayout>
  )
})
