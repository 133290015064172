import { combineReducers } from 'redux'
import { langReducer } from './langReducer'
import { authReducer } from './authReducer'
import { reducer as formReducer } from 'redux-form'

export default combineReducers({
  lang: langReducer,
  authentication: authReducer,
  form: formReducer,
})
