import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { Button, Form, Center, Link } from 'components/atoms'
import { Input, Checkbox } from 'components/molecules'
import { FormattedMessage } from 'react-intl'
import { required, email } from 'redux-form-validators'
import {
  NAME_MIN_LENGTH,
  NAME_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  ADDRESS_MIN_LENGTH,
  CITY_MIN_LENGTH,
  COUNTRY_MIN_LENGTH,
  POSTAL_CODE_MIN_LENGTH,
} from './RegisterInputsLimits'

const validate = values => {
  let errors = {}

  const emailRegExp = new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+[a-zA-Z]{2,}$')
  const numbersRegExp = new RegExp('[0-9]')
  const phoneNumberRegExp = new RegExp('^[0-9 #+\\-\\(\\)\\[\\]]{6,32}$')

  if (values.password && values.password.length < 8) {
    errors.password = 'Password length must be at least 8 characters long'
  }

  if (
    (values.firstName &&
      (values.firstName.length < NAME_MIN_LENGTH ||
        values.firstName.length > NAME_MAX_LENGTH)) ||
    numbersRegExp.test(values.firstName)
  ) {
    errors.firstName = 'Invalid first name'
  }

  if (
    (values.lastName &&
      (values.lastName.length < NAME_MIN_LENGTH ||
        values.lastName.length > NAME_MAX_LENGTH)) ||
    numbersRegExp.test(values.lastName)
  ) {
    errors.lastName = 'Invalid last name'
  }

  if (values.password && values.password.length < PASSWORD_MIN_LENGTH) {
    errors.password = `Password length must be at least ${PASSWORD_MIN_LENGTH} characters long`
  }

  if (values.password && values.password.length > PASSWORD_MAX_LENGTH) {
    errors.password = `Password must be less or equal to ${PASSWORD_MAX_LENGTH} characters`
  }

  if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = 'Password confirmation must be the same'
  }

  if (!phoneNumberRegExp.test(values.telephone)) {
    errors.telephone = 'Invalid phone number'
  }

  if (!emailRegExp.test(values.emailAddress)) {
    errors.emailAddress = 'Email address has an invalid format'
  }

  if (values.emailAddress !== values.emailConfirmation) {
    errors.emailConfirmation = 'Email and email confirmation do not match!'
  }

  if (
    (values.address && values.address.length < ADDRESS_MIN_LENGTH) ||
    !RegExp('^[a-zA-Z0-9 ]{2,}$').test(values.address)
  ) {
    errors.address = 'Invalid address'
  }

  if (
    (values.city && values.city.length < CITY_MIN_LENGTH) ||
    numbersRegExp.test(values.city)
  ) {
    errors.city = 'Invalid city name'
  }

  if (
    (values.country && values.country.length < COUNTRY_MIN_LENGTH) ||
    numbersRegExp.test(values.country)
  ) {
    errors.country = 'Invalid country name'
  }

  if (values.postalCode && values.postalCode.length < POSTAL_CODE_MIN_LENGTH) {
    errors.postalCode = `Postal code is too short (should be at least ${POSTAL_CODE_MIN_LENGTH} characters)`
  }

  if (!values.acceptTerms) {
    errors.acceptTerms = 'Required'
  }

  return errors
}

const RegisterFormUnwrapped = ({
  handleSubmit,
  loading,
  pristine,
  invalid,
}) => (
  <Form.Container>
    <Form onSubmit={handleSubmit}>
      <Form.Item>
        <Form.Label htmlFor="firstName">
          <FormattedMessage id="form.firstName" />
        </Form.Label>
        <Field
          component={Input}
          name="firstName"
          type="text"
          testId="register-first-name-input"
          validate={[required()]}
          iconType="user"
        />
      </Form.Item>
      <Form.Item>
        <Form.Label htmlFor="lastName">
          <FormattedMessage id="form.lastName" />
        </Form.Label>
        <Field
          component={Input}
          name="lastName"
          type="text"
          testId="register-last-name-input"
          validate={[required()]}
          iconType="user"
        />
      </Form.Item>
      <Form.Item>
        <Form.Label htmlFor="password">
          <FormattedMessage id="form.password" />
        </Form.Label>
        <Field
          component={Input}
          name="password"
          type="password"
          testId="register-password-input"
          validate={[required()]}
          iconType="padlock"
        />
      </Form.Item>
      <Form.Item>
        <Form.Label htmlFor="passwordConfirmation">
          <FormattedMessage id="form.passwordConfirmation" />
        </Form.Label>
        <Field
          component={Input}
          name="passwordConfirmation"
          type="password"
          testId="register-passwordConfirmation-input"
          validate={[required()]}
          iconType="padlock"
        />
      </Form.Item>
      <Form.Item>
        <Form.Label htmlFor="emailAddress">
          <FormattedMessage id="form.emailAddress" />
        </Form.Label>
        <Field
          component={Input}
          name="emailAddress"
          type="text"
          testId="register-email-input"
          validate={[required(), email()]}
          iconType="email"
        />
      </Form.Item>
      <Form.Item>
        <Form.Label htmlFor="emailConfirmation">
          <FormattedMessage id="form.emailConfirmation" />
        </Form.Label>
        <Field
          component={Input}
          name="emailConfirmation"
          type="text"
          testId="register-emailConfirmation-input"
          validate={[required(), email()]}
          iconType="email"
        />
      </Form.Item>
      <Form.Item>
        <Form.Label htmlFor="telephone">
          <FormattedMessage id="form.telephone" />
        </Form.Label>
        <Field
          component={Input}
          name="telephone"
          type="text"
          testId="register-telephone-input"
          iconType="telephone"
        />
      </Form.Item>
      <Form.Item>
        <Form.Label htmlFor="addressLine1">
          <FormattedMessage id="form.addressLine1" />
        </Form.Label>
        <Field
          component={Input}
          name="addressLine1"
          type="text"
          testId="register-addressLine1-input"
        />
      </Form.Item>
      <Form.Item>
        <Form.Label htmlFor="addressLine2">
          <FormattedMessage id="form.addressLine2" />
        </Form.Label>
        <Field
          component={Input}
          name="addressLine2"
          type="text"
          testId="register-addressLine2-input"
        />
      </Form.Item>
      <Form.Item>
        <Form.Label htmlFor="city">
          <FormattedMessage id="form.city" />
        </Form.Label>
        <Field
          component={Input}
          name="city"
          type="text"
          testId="register-city-input"
        />
      </Form.Item>
      <Form.Item>
        <Form.Label htmlFor="country">
          <FormattedMessage id="form.country" />
        </Form.Label>
        <Field
          component={Input}
          name="country"
          type="text"
          testId="register-country-input"
        />
      </Form.Item>
      <Form.Item>
        <Form.Label htmlFor="postalCode">
          <FormattedMessage id="form.postalCode" />
        </Form.Label>
        <Field
          component={Input}
          name="postalCode"
          type="text"
          testId="register-postalCode-input"
        />
      </Form.Item>
      <Form.Item>
        <Field
          component={Checkbox}
          name="acceptTerms"
          type="checkbox"
          testId="register-acceptTerms-input"
          validate={[required()]}
          iconType="telephone"
          label={
            <>
              I accept
              <Link to="/terms-and-conditions">
                &nbsp;terms and conditions&nbsp;
              </Link>{' '}
              of service
            </>
          }
        />
      </Form.Item>

      <Form.Button>
        <Center>
          <Button
            type="submit"
            data-test-id="register-submit"
            disabled={pristine || invalid || loading}
          >
            <FormattedMessage id="button.register" />
            {loading ? '...' : ''}
          </Button>
        </Center>
      </Form.Button>
    </Form>
    <Form.Links>
      <Link to="/login">
        <FormattedMessage id="user.login" />
      </Link>
    </Form.Links>
  </Form.Container>
)

RegisterFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export const RegisterForm = reduxForm({
  form: 'system-operator-register-form',
  validate,
  initialValues: {},
})(RegisterFormUnwrapped)
