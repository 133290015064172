/* global L */
import React, { useEffect, useRef } from 'react'

export const MapForm = ({ lat, lng, onCoordinatesChange }) => {
  const mapRef = useRef(null)

  useEffect(() => {
    const map = L.map(mapRef.current).setView(
      [lat ?? 63.4225876, lng ?? 10.4680739],
      6,
    )
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
    }).addTo(map)
    const marker = L.marker([lat, lng], {
      draggable: true,
    }).bindPopup('<h2>Grab the marker to change the location.</h2>')
    marker.addTo(map)
    marker.on('dragend', e => {
      const { lat, lng } = e.target.getLatLng()
      onCoordinatesChange({ lat, lng })
    })
  }, [lat, lng])

  return <div style={{ height: '100%', width: '100%' }} ref={mapRef}></div>
}
