import Modal from 'react-modal'
import styled from 'styled-components'
import {
  ColorMisty,
  ColorWhite,
  SizeSpacing03Rem,
  SizeSpacing07Rem,
} from 'style/design-tokens'

export const StyledModal = styled(Modal)`
  background: ${ColorWhite};
  border: 1px solid ${ColorMisty};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  left: 50%;
  outline: none;
  padding: ${SizeSpacing03Rem}rem ${SizeSpacing07Rem}rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  ${({ width }) => (width ? `width: ${width};` : '')}
  & label {
    hight: 40px;
  }
  max-height: calc(100vh - 2rem);
  overflow-y: scroll;
`
