import React from 'react'
import { withRouter } from 'react-router-dom'
import { Error, Address, Link } from 'components/atoms'
import { Table } from 'components/organisms'
import { withUserSites } from 'api'
import { formatDateTime } from 'utils/formatters'

const columns = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Number',
    key: 'number',
  },
  {
    title: 'Address',
    render: record => <Address address={record.address} />,
  },
  {
    title: 'Systems',
    render: record => `${record.systemIds.length}`,
  },
  {
    title: 'Created at',
    render: record => `${formatDateTime(record.createdAt)}`,
  },
  {
    title: 'Created by',
    render: record => (
      <Link to={`/users/${record.createdById}`}>
        {record.createdBy.emailAddress}
      </Link>
    ),
    externalLink: true,
  },
]

export const UserSites = withRouter(({ userId, history }) => {
  const { data, isLoading, error, refresh, ...rest } = withUserSites(userId)()
  return (
    <>
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns}
        data={data}
        history={history}
        linkPath={'/sites'}
        {...rest}
      />
    </>
  )
})
