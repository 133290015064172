import { SET_LANGUAGE } from '../actions/constants'

const saveLocale = locale => {
  if (window.localStorage) {
    window.localStorage.setItem('intl-locale', locale)
  }
}

const getLocale = () => {
  if (window.localStorage) {
    try {
      // return window.localStorage.getItem('intl-locale')
    } catch (err) {
      return
    }
  }
}

const defaultState = getLocale() || 'en'

export const langReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      saveLocale(action.lang)
      return action.lang
    default:
      return state
  }
}
