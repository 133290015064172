import React from 'react'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router'
import { MainLayout } from 'layout/MainLayout'
import { Button, Spacing } from 'components/atoms'

const TermsAndConditionsUnwrapped = withRouter(({ history }) => (
  <MainLayout.FullPageScreen logo={false}>
    <Spacing mt="-5" />
    <embed
      src="https://autro-public-statics.s3.eu-central-1.amazonaws.com/docs/autronicafire/legal/terms-and-conditions/index.html"
      width="100%"
      height="1000"
    />

    <Spacing mt="2" />
    {history.length > 1 && (
      <Button
        data-test-id="back-button"
        buttonType="primary"
        style={{ color: 'white' }}
        onClick={() => history.goBack()}
      >
        <FormattedMessage id="button.back" />
      </Button>
    )}
  </MainLayout.FullPageScreen>
))

export const TermsAndConditions = withRouter(TermsAndConditionsUnwrapped)
