import styled from 'styled-components'
import {
  ColorCarrotOrange,
  ColorAckGreen,
  ColorLegacyBaseGrayLight,
  ColorFireRed,
} from 'style/design-tokens'

export const Diode = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  margin: auto 0;
  margin-right: 0.6rem;
  border-radius: 1rem;
  ${({ color }) => {
    switch (color) {
      case 'green':
        return `
          background-color: ${ColorAckGreen};
        `
      case 'red':
        return `
          background-color: ${ColorFireRed};
        `
      case 'orange':
        return `
          background-color: ${ColorCarrotOrange};
        `
      default:
        return `
          background-color: ${ColorLegacyBaseGrayLight};
        `
    }
  }}
`
