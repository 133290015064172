import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table } from 'components/organisms'
import { TextWrapper, Error, Link, Address } from 'components/atoms'
import { formatDateTime } from 'utils/formatters'
import { withAdminSites } from 'api'

const columns = [
  {
    title: 'Name',
    key: 'name',
    sortable: true,
    searchable: true,
    render: record => <TextWrapper>{record.name}</TextWrapper>,
  },
  {
    title: 'Number',
    key: 'number',
    sortable: true,
    searchable: true,
  },
  {
    title: 'Address',
    key: 'addressLine1',
    sortable: true,
    searchable: true,
    render: record => <Address address={record.address} />,
  },
  {
    title: 'Systems',
    key: 'systemIdsCount',
    sortable: true,
    render: record => `${record.systemIds.length}`,
  },
  {
    title: 'Created at',
    key: 'createdAt',
    sortable: true,
    searchable: true,
    render: record => `${formatDateTime(record.createdAt)}`,
  },
  {
    title: 'Created by',
    key: 'creatorEmailAddress',
    sortable: true,
    searchable: true,
    render: record => (
      <Link to={`/users/${record.createdById}`}>
        {record.createdBy.emailAddress}
      </Link>
    ),
    externalLink: true,
  },
]

export const SitesList = withRouter(({ history }) => {
  const {
    data,
    isLoading,
    error,
    onChange,
    pagination,
    refresh,
    sorter,
    filters,
    ...rest
  } = withAdminSites()
  return (
    <>
      {error && <Error error={error} />}
      <Table
        indexKey="id"
        isLoading={isLoading}
        columns={columns}
        data={data}
        sortingParams={sorter}
        filteringParams={filters}
        refresh={refresh}
        pagination={pagination}
        onChange={onChange}
        linkPath={'/sites'}
        testId="sites-table"
        history={history}
        {...rest}
      />
    </>
  )
})
