import { withListResource } from './withListResource'
import { withSingleResource } from './withSingleResource'
import { makeRequest } from './makeRequest'
import { fetchRequest } from './fetchRequest'
export { withUserUpdater } from './withUserUpdater'

export const authenticate = (emailAddress, password) =>
  makeRequest('/v1/auth/admin', {
    emailAddress,
    password,
  })

export const withInvitation = withSingleResource('/v1/invitations')

export const withSystems = withListResource('/v1/systems')

export const withInstalledSystems = withListResource('/v1/reports/systems')

export const withSystem = withSingleResource('/v1/systems')

export const withSites = withListResource('/v1/sites')

export const withSite = withSingleResource('/v1/sites')

export const withSiteUsers = siteId =>
  withListResource(`/v1/sites/${siteId}/users`)

export const withSiteSystems = siteId =>
  withListResource(`/v1/sites/${siteId}/systems`)

export const withSiteModems = siteId =>
  withListResource(`/v1/sites/${siteId}/modems`)

export const withSiteScannedSystems = siteId =>
  withListResource(`/v1/sites/${siteId}/scanned-systems`)

export const withScannedSystem = withSingleResource('/v1/scanned-systems')

export const withScannedLoop = withSingleResource('/v1/scanned-loops')

export const withScannedSystemLoops = scannedSystemId =>
  withListResource(`/v1/scanned-systems/${scannedSystemId}/scanned-loops`)

export const deleteScannedSystem = id =>
  makeRequest(`/v1/scanned-systems/${id}`, {}, 'delete')

export const withLoopUnits = loopId =>
  withListResource(`/v1/scanned-loops/${loopId}/scanned-units`)

export const withUnit = () => withSingleResource('/v1/scanned-units')

export const deleteLoop = id =>
  makeRequest(`/v1/scanned-loops/${id}`, {}, 'delete')

export const exportLoop = id =>
  makeRequest(`/v1/scanned-loops/${id}/export-loop`, {}, 'get')

export const updateLoop = (id, data) =>
  makeRequest(`/v1/scanned-loops/${id}`, data, 'put')

export const deleteLoopUnit = id =>
  makeRequest(`/v1/scanned-units/${id}`, {}, 'delete')

export const withSystemIssues = systemId =>
  withListResource(`/v1/systems/${systemId}/issues`)

export const withSystemConfigurations = systemId =>
  withListResource(`/v1/systems/${systemId}/configurations`)

export const withSystemOperators = systemId =>
  withListResource(`/v1/systems/${systemId}/users`)

export const withUsers = withListResource('/v1/users')

export const withUser = withSingleResource('/v1/users')

export const withRequester = withSingleResource('/v1/user')

export const withUserSystems = userId =>
  withListResource(`/v1/users/${userId}/systems`)

export const withUserSites = userId =>
  withListResource(`/v1/users/${userId}/sites`)

export const withUserModems = userId =>
  withListResource(`/v1/users/${userId}/modems`)

export const withUserModemsAccess = userId =>
  withListResource(`/v1/users/${userId}/modems-access`)

export const withAccessModemsIssued = userId =>
  withListResource(`/v1/users/${userId}/issued`)

export const fetchUser = userId => fetchRequest(`/v1/users/${userId}`)

export const withModems = withListResource('/v1/modems')

export const withModem = withSingleResource('/v1/modems')

export const withConfigurationSummary = id =>
  withSingleResource(`/v1/configurations/${id}/hardware-summary`)

export const archiveSystem = id =>
  makeRequest(`/v1/systems/${id}`, {}, 'delete')

export const deleteSystem = id =>
  makeRequest(`/v1/systems/${id}/full`, {}, 'delete')

export const deleteSite = id => makeRequest(`/v1/sites/${id}`, {}, 'delete')

export const withIotServerMetrics = withSingleResource('/v1/metrics/iot-server')

export const withLiveSystems = withListResource('/v1/systems/live')

export const withAdminSites = withListResource('/v1/admin/sites')

export const withAdminSystems = withListResource('/v1/admin/systems')

export const deleteModem = id => makeRequest(`/v1/modems/${id}`, {}, 'delete')

export const uploadModemConfiguration = (id, data) =>
  makeRequest(`/v1/modems/${id}/configuration`, data)

export const withStatistics = withSingleResource('/v1/admin/stats')

export const updateModemConfiguration = (id, data) =>
  makeRequest(`/v1/modems/${id}/network-configuration`, data, 'put')

export const updateAccessLevel = (type, id, accessLevel) =>
  makeRequest(`/v1/${type}-invitations/${id}`, { accessLevel }, 'put')

export const withReports = withListResource('/v1/reports/systems-per-unit-type')

export const withSystemEventsReports = withListResource(
  '/v1/reports/system-events',
)

export const withUnitAgeReports = withListResource('/v1/reports/units-age')

export const withSystemUnitReports = withListResource(
  '/v1/reports/system-units-amount',
)

export const withAvailableSystems = withListResource(
  '/v1/reports/available-systems',
)

export const exportReport = filters =>
  makeRequest('/v1/reports/export-systems-per-unit-type', {}, 'get', {
    headers: { 'Content-Type': 'blob' },
    responseType: 'arraybuffer',
    params: filters,
  })

export const exportEventReport = filters =>
  makeRequest('/v1/reports/export-system-events', {}, 'get', {
    headers: { 'Content-Type': 'blob' },
    responseType: 'arraybuffer',
    params: filters,
  })

export const exportUnitAgeReport = filters =>
  makeRequest('/v1/reports/export-units-age', {}, 'get', {
    headers: { 'Content-Type': 'blob' },
    responseType: 'arraybuffer',
    params: filters,
  })

export const exportRawConfiguration = id =>
  makeRequest(
    `/v1/configurations/${id}/download`,
    { headers: { 'Content-Type': 'blob' }, responseType: 'arraybuffer' },
    'get',
  )

export const exportSystemUnitReport = filters =>
  makeRequest('/v1/reports/export-system-units-amount', {}, 'get', {
    headers: { 'Content-Type': 'blob' },
    responseType: 'arraybuffer',
    params: filters,
  })
