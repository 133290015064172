import React from 'react'
import styled from 'styled-components'
import { pathOr } from 'ramda'
import { ColorBlack, ColorCharcoalGrey, ColorWhite } from 'style/design-tokens'

const StyledCell = styled.td`
  border-top: 0;
  border: 1px solid ${ColorBlack};
  color: ${({ color }) => (color ? color : 'inherit')};
  padding: ${({ padding }) => (padding ? padding : '0.5rem 0.7rem')};
  width: ${({ width }) => (width ? width : 'auto')};
  text-align: ${({ align }) => align || 'center'};
`

const StyledRow = styled.tr`
  &:hover {
    cursor: ${({ linkPath }) => (linkPath ? 'pointer' : 'auto')};
    background-color: ${ColorCharcoalGrey};
  }
`

export const Row = ({
  columns,
  item,
  refresh,
  index,
  linkPath,
  history,
  indexKey,
}) => (
  <StyledRow linkPath={linkPath}>
    {columns.map((column, idx) => (
      <StyledCell
        onClick={() => {
          if (
            !column.notClickable &&
            !column.externalLink &&
            linkPath &&
            indexKey
          ) {
            history.push(`${linkPath}/${indexKey}`)
          }
        }}
        key={idx}
        align={column.align}
        width={column.width}
        padding={column.padding}
        color={column.color ? column.color : ColorWhite}
        data-test-id={column.testId}
      >
        {column.render
          ? column.render(item, refresh, index)
          : pathOr('-', [column.key], item)}
      </StyledCell>
    ))}
  </StyledRow>
)

StyledCell.defaultProps = {
  align: 'left',
}
