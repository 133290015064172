import React from 'react'
import { FormattedMessage } from 'react-intl'
import { exportSystemUnitReport } from 'api'
import { Button } from 'components/atoms'
import { downloadXSLXFile } from 'utils/downloaders'

const composeFileName = filters => {
  let fileName = 'SystemUnitReport'
  if (filters) {
    if (filters.producedBefore) {
      fileName += `-from-${filters.producedBefore}`
    }
  }
  return `${fileName}.xlsx`
}

export const DownloadSystemUnitReport = ({ formValues }) => (
  <Button
    data-test-id="download-event-report-button"
    onClick={async () => {
      const { data, error } = await exportSystemUnitReport(formValues)
      if (!error) {
        downloadXSLXFile({
          data: data,
          fileName: composeFileName(formValues),
        })
      } else {
        throw error
      }
    }}
  >
    <FormattedMessage id="button.download" />
  </Button>
)
