import React from 'react'
import { Link, Error, SizedBox } from 'components/atoms'
import { MainLayout } from 'layout/MainLayout'
import { Table } from 'components/organisms'
import { formatDateTime } from 'utils/formatters'
import { withUserModems } from 'api'
import { connect } from 'react-redux'

const columns = [
  {
    title: 'Number',
    key: 'number',
  },
  {
    title: 'Site',
    render: record => (
      <Link to={`/sites/${record.siteId}`}>{record.siteName}</Link>
    ),
    externalLink: true,
  },
  {
    title: 'Created at',
    render: record => formatDateTime(record.createdAt),
  },
]

export const ModemsList = connect(state => ({
  userId: state.authentication.user.id,
}))(({ userId, history }) => {
  const { data, isLoading, error, ...rest } = withUserModems(userId)()
  return (
    <MainLayout header="Modems">
      <SizedBox height="1" />
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns}
        data={data}
        history={history}
        linkPath={'/modems'}
        {...rest}
      ></Table>
    </MainLayout>
  )
})
