import React from 'react'
import PropTypes from 'prop-types'
import { reset } from 'redux-form'
import { compose } from 'recompose'
import { store } from 'rdx/store'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { required, format } from 'redux-form-validators'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Button, Form, Typography, SizedBox } from 'components/atoms'
import { Input } from 'components/molecules'

const EditUserFormUnwrapped = ({
  handleSubmit,
  invalid,
  loading,
  pristine,
  firstName,
  lastName,
}) => {
  return (
    <Form.Dark onSubmit={handleSubmit}>
      <Typography.H3>Personal details</Typography.H3>
      <Form.Item width="400px">
        <Form.Label>
          <FormattedMessage id="edit.user.form.firstname" />
        </Form.Label>
        <Field
          component={Input}
          name="firstName"
          testId="edit-user-form-firstname-input"
          validate={[
            required(),
            format({
              with: firstName ? firstName.length > 36 : true,
              message: 'First name is too long (should be max 36 characters)',
            }),
          ]}
        />
      </Form.Item>
      <Form.Item width="400px">
        <Form.Label>
          <FormattedMessage id="edit.user.form.lastname" />
        </Form.Label>
        <Field
          component={Input}
          name="lastName"
          testId="edit-user-form-lastname-input"
          validate={[
            required(),
            format({
              with: lastName ? lastName.length > 36 : true,
              message: 'Last name is too long (should be max 36 characters)',
            }),
          ]}
        />
      </Form.Item>
      <Form.Item width="400px">
        <Form.Label>
          <FormattedMessage id="edit.user.form.phone" />
        </Form.Label>
        <Field
          component={Input}
          name="phoneNumber"
          testId="edit-user-form-phone-input"
        />
      </Form.Item>
      <SizedBox height="1" />
      <Typography.H3>Address</Typography.H3>
      <Form.Item width="400px">
        <Form.Label>
          <FormattedMessage id="edit.user.form.address1" />
        </Form.Label>
        <Field
          component={Input}
          name="addressLine1"
          testId="edit-user-form-address1-input"
        />
      </Form.Item>
      <Form.Item width="400px">
        <Form.Label>
          <FormattedMessage id="edit.user.form.address2" />
        </Form.Label>
        <Field
          component={Input}
          name="addressLine2"
          testId="edit-user-form-address2-input"
        />
      </Form.Item>
      <Form.Item width="400px">
        <Form.Label>
          <FormattedMessage id="edit.user.form.postalcode" />
        </Form.Label>
        <Field
          component={Input}
          name="postalCode"
          testId="edit-user-form-postalcode-input"
        />
      </Form.Item>
      <Form.Item width="400px">
        <Form.Label>
          <FormattedMessage id="edit.user.form.city" />
        </Form.Label>
        <Field
          component={Input}
          name="city"
          testId="edit-user-form-city-input"
        />
      </Form.Item>
      <Form.Item width="400px">
        <Form.Label>
          <FormattedMessage id="edit.user.form.country" />
        </Form.Label>
        <Field
          component={Input}
          name="country"
          testId="edit-user-form-country-input"
        />
      </Form.Item>
      <Form.Buttons>
        <Button
          loading={loading}
          disabled={pristine}
          buttonType="secondary"
          data-test-id="edit-user-form-cancel"
          onClick={() => {
            store.dispatch(reset('edit-user-form'))
          }}
        >
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button
          loading={loading}
          type="submit"
          disabled={pristine || invalid || loading}
          data-test-id="edit-user-form-save"
        >
          <FormattedMessage id="button.save" />
        </Button>
      </Form.Buttons>
    </Form.Dark>
  )
}

EditUserFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

const fieldSelector = formValueSelector('edit-user-form')

export const EditUserForm = compose(
  reduxForm({
    form: 'edit-user-form',
  }),
  connect(state => ({
    firstName: fieldSelector(state, 'firstName'),
    lastName: fieldSelector(state, 'lastName'),
  })),
)(EditUserFormUnwrapped)
