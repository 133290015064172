export const UnitTypes = [
  'BSD-310',
  'BSD-310/N',
  'BSD-311',
  'BSD-330',
  'BSB-310A',
  'BSJ-310',
  'BSE-310',
  'BSE-320',
  'BDH-200',
  'BDH-300',
  'BHH-200',
  'BHH-300',
  'BHH-500/S',
  'BHH-220',
  'BHH-320',
  'BBL-100/IP',
  'BBR-130',
  'BBR-200',
  'BBR-230',
  'BBR-230/IP',
  'BBQ-130',
  'BBQ-230',
]

