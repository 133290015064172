import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  ColorMalachite,
  SizeLineHeightMedium,
  SizeLineHeightXlarge,
  SizeSpacing03Rem,
} from 'style/design-tokens'

const Container = styled.div`
  color: ${ColorMalachite};
  display: inline-flex;
  padding: 0.6rem 2.6rem;
`

const Input = styled.input`
  display: flex;
  font-size: ${SizeLineHeightXlarge}px;
  margin-right: ${SizeSpacing03Rem}rem;
`

const Label = styled.div`
  line-height: ${SizeLineHeightMedium}px;
`

export const Radio = ({ input, label }) => (
  <Container>
    <Input
      type="radio"
      onChange={input.onChange}
      value={input.value}
      {...input}
    />
    <Label>{label}</Label>
  </Container>
)

Radio.propTypes = {
  label: PropTypes.node,
}
