import React, { useState } from 'react'
import { FormProvider } from 'providers/FormProvider'
import { Icon, Button } from 'components/atoms'
import { Modal } from 'components/molecules'
import { InviteOperatorForm } from './InviteOperatorForm'

export const InviteOperatorModal = ({ refresh, systemId }) => {
  const [isOpen, onChange] = useState(false)
  return (
    <>
      <Button
        data-test-id="invite-operator-button"
        buttonType="primary"
        onClick={() => onChange(true)}
      >
        <Icon type="plus" /> Invite
      </Button>
      <Modal
        title="Invite user and grant access to this resource"
        isOpen={isOpen}
        onRequestClose={() => {
          onChange(false)
        }}
      >
        {isOpen && (
          <FormProvider
            onSuccess={() => {
              refresh()
              onChange(false)
            }}
            url={`/v1/systems/${systemId}/users`}
            render={({ updating, handleSubmit }) => (
              <InviteOperatorForm
                onSubmit={values => {
                  handleSubmit(values)
                }}
                onCancel={() => {
                  onChange(false)
                }}
                loading={updating}
              />
            )}
          />
        )}
      </Modal>
    </>
  )
}
