import React from 'react'
import styled from 'styled-components'
import QRCode from 'qrcode'
import { MainLayout } from 'layout/MainLayout'
import { Input, Field, SizedBox, Button } from 'components/atoms'

const Code = styled.code`
  display: block;
  white-space: pre-wrap;
  background-color: black;
  padding: 2rem;
  font-size: 14px;
  width: 100%;
  max-width: 800px;
`

export class QrCodeGenerator extends React.Component {
  state = {
    clientId: '812f9b49c93f567d89e181907dcdbf12',
    imsi: '242090000865502',
    imei: '865546045403009',
    wgip: '10.250.0.24',
    ts: '20200602101200',
    iccid: '89883040000008755022',
  }

  componentDidMount = () => {
    this.generate()
  }

  handleChange = field => e => {
    this.setState(
      {
        [field]: e.target.value,
      },
      this.generate,
    )
  }

  generate = () => {
    const { clientId, imsi, imei, wgip, ts, iccid } = this.state
    const text = `<IMEI>${imei}</IMEI><ICCID>${iccid}</ICCID><IMSI>${imsi}</IMSI><WGIP>${wgip}</WGIP><CLIENTID>${clientId}</CLIENTID><TS>${ts}</TS>`
    QRCode.toCanvas(document.getElementById('qr-code-canvas'), text, function(
      error,
    ) {
      if (error) console.error(error)
    })
  }

  random = () => {
    this.setState(
      {
        clientId: this.uuidv4(),
        imsi: Math.random()
          .toString()
          .slice(2),
        imei: Math.random()
          .toString()
          .slice(2),
        wgip: `${Math.round(255 * Math.random())}.${Math.round(
          255 * Math.random(),
        )}.${Math.round(255 * Math.random())}.${Math.round(
          255 * Math.random(),
        )}`,
        ts: Math.random()
          .toString()
          .slice(2),
        iccid: Math.random()
          .toString()
          .slice(2),
      },
      this.generate,
    )
  }

  uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16),
    )
  }

  code = () =>
    `
mqtt:
  broker: mqtt://localhost:1883 # production: mqtt://13.49.229.212:1833
  topic:
    protocol: AFS19
    node: ${this.state.clientId}
autrocom:
  client:
    list:
      - tag: test
        name: Test
        host: 172.16.197.20 # autrosafe ip
        port: 25501  # autrosafe port
        enable: true
  `.trim()

  render() {
    const { clientId, imsi, imei, wgip, ts, iccid } = this.state
    return (
      <MainLayout>
        <Field title="ClientID">
          <Input
            data-test-id=""
            value={clientId}
            onChange={this.handleChange('clientId')}
          />
        </Field>
        <Field title="ICCID">
          <Input
            data-test-id=""
            value={iccid}
            onChange={this.handleChange('iccid')}
          />
        </Field>
        <Field title="IMEI">
          <Input
            data-test-id=""
            value={imei}
            onChange={this.handleChange('imei')}
          />
        </Field>
        <Field title="IMSI">
          <Input
            data-test-id=""
            value={imsi}
            onChange={this.handleChange('imsi')}
          />
        </Field>
        <Field title="WGIP">
          <Input
            data-test-id=""
            value={wgip}
            onChange={this.handleChange('wgip')}
          />
        </Field>
        <Field title="TS">
          <Input
            data-test-id=""
            value={ts}
            onChange={this.handleChange('ts')}
          />
        </Field>
        <SizedBox height="1" />
        <Button data-test-id="random" onClick={this.random}>
          Random
        </Button>
        <SizedBox height="3" />
        <canvas id="qr-code-canvas" />
        <SizedBox height="3" />
        <Field title="afs-relay-js/cfg.yml">
          <Code>{this.code()}</Code>
        </Field>
      </MainLayout>
    )
  }
}
