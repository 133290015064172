import React from 'react'
import PropTypes from 'prop-types'
import { change, reset } from 'redux-form'
import { compose } from 'recompose'
import { store } from 'rdx/store'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import { Button, Form, SizedBox, FormDropdown, Row } from 'components/atoms'
import { AccessLevels } from 'utils/AccesLevels'
import { UserTypes } from 'utils/UserTypes'

const UserPermissionsFormUnwrapped = ({
  handleSubmit,
  invalid,
  loading,
  pristine,
  userType,
  change,
}) => {
  return (
    <Form.Dark onSubmit={handleSubmit}>
      <Form.Select width="60%">
        <Form.Label>
          <FormattedMessage id="user.permissions.form.userType" />
        </Form.Label>
        <Row gutter={8}>
          <Row.Col size={6}>
            <Field
              component={FormDropdown}
              name="userType"
              options={[
                {
                  label: UserTypes.toLabel(UserTypes.Regular),
                  value: UserTypes.Regular,
                },
                {
                  label: UserTypes.toLabel(UserTypes.Super),
                  value: UserTypes.Super,
                },
              ]}
              onChange={value => {
                if (value === UserTypes.Regular) {
                  change(
                    'permissions-user-form',
                    'globalAccessLevel',
                    AccessLevels.Admin,
                  )
                }
              }}
              testId="user-permissions-form-user-type-dropdown"
            />
          </Row.Col>
        </Row>
      </Form.Select>
      <Form.Select width="60%">
        <Form.Label>
          <FormattedMessage id="user.permissions.form.globalAccessLevel" />
        </Form.Label>
        <Row.Col size={6}>
          {userType !== UserTypes.Regular ? (
            <Field
              component={FormDropdown}
              name="globalAccessLevel"
              options={[
                {
                  label: AccessLevels.toLabel(AccessLevels.Monitor),
                  value: AccessLevels.Monitor,
                },
                {
                  label: AccessLevels.toLabel(AccessLevels.Operator),
                  value: AccessLevels.Operator,
                },
                {
                  label: AccessLevels.toLabel(AccessLevels.Admin),
                  value: AccessLevels.Admin,
                },
              ]}
              testId="user-permissions-form-access-level-dropdown"
            />
          ) : (
            <Field
              component={FormDropdown}
              name="globalAccessLevel"
              options={[
                {
                  label: AccessLevels.toLabel(AccessLevels.Admin),
                  value: AccessLevels.Admin,
                },
              ]}
              testId="user-permissions-form-access-level-dropdown"
            />
          )}
        </Row.Col>
      </Form.Select>
      <SizedBox height="6" />
      <Form.Buttons>
        <Button
          loading={loading}
          disabled={pristine}
          buttonType="secondary"
          data-test-id="permissions-user-form-cancel"
          onClick={() => {
            store.dispatch(reset('permissions-user-form'))
          }}
        >
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button
          loading={loading}
          type="submit"
          disabled={pristine || invalid || loading}
          data-test-id="permissions-user-form-save"
        >
          <FormattedMessage id="button.save" />
        </Button>
      </Form.Buttons>
    </Form.Dark>
  )
}

UserPermissionsFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

const fieldSelector = formValueSelector('permissions-user-form')

export const UserPermissionsForm = compose(
  reduxForm({
    form: 'permissions-user-form',
  }),
  connect(
    state => ({
      userType: fieldSelector(state, 'userType'),
    }),
    { change },
  ),
)(UserPermissionsFormUnwrapped)
