import React from 'react'
import styled from 'styled-components'
import { ColorFireRed } from 'style/design-tokens'
import { Select as StyledSelect } from 'components/atoms'

const ErrorText = styled.label`
  color: ${ColorFireRed};
  padding-left: 1.75rem;
`

export const Select = ({
  meta,
  placeholder,
  input,
  disabled,
  testId,
  type,
  options,
  ...rest
}) => (
  <>
    <StyledSelect
      {...meta}
      {...rest}
      value={input.value}
      onChange={input.onChange}
      disabled={disabled}
    >
      <StyledSelect.Option value="">
        {placeholder || 'Select option'}
      </StyledSelect.Option>
      {options.map(option => (
        <StyledSelect.Option key={option.value} value={option.value}>
          {option.label}
        </StyledSelect.Option>
      ))}
    </StyledSelect>
    {meta.error && meta.dirty && <ErrorText>{meta.error}</ErrorText>}
  </>
)
