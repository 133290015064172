import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { FormattedMessage, injectIntl } from 'react-intl'
import { reduxForm } from 'redux-form'
import {
  Button,
  SizedBox,
  Typography,
  Input,
  Form,
  Select,
  ConfirmationModal,
} from 'components/atoms'
import styled from 'styled-components'
import { Col, Row } from 'react-grid-system'
import { ColorMalachite, ColorFireRed } from 'style/design-tokens'
import { updateModemConfiguration } from 'api'
import { showError, showSuccess } from 'layout/notifications'

const Container = styled.div`
  border: 1px solid ${ColorMalachite};
  padding: 1rem;
  width: 400px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  & select {
    margin-top: 0.4rem;
  }
`

const Error = styled.div`
  display: flex;
  justify-content: flex-start;
  color: ${ColorFireRed};
  padding-top: 0.5rem;
`

const DeleteButton = styled.div`
  color: ${ColorFireRed};
  cursor: pointer;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
`

const FormContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  & div {
    border-radius: 1px;
  }
  & input {
    height: 38px;
  }
  .input-container {
    padding: 0 0.6rem;
  }
`

const subnets = [
  '255.255.255.255/32',
  '255.255.255.254/31',
  '255.255.255.252/30',
  '255.255.255.248/29',
  '255.255.255.240/28',
  '255.255.255.224/27',
  '255.255.255.192/26',
  '255.255.255.128/25',
  '255.255.255.0/24',
  '255.255.254.0/23',
  '255.255.252.0/22',
  '255.255.248.0/21',
  '255.255.240.0/20',
  '255.255.224.0/19',
  '255.255.192.0/18',
  '255.255.128.0/17',
  '255.255.0.0/16',
  '255.254.0.0/15',
  '255.252.0.0/14',
  '255.248.0.0/13',
  '255.240.0.0/12',
  '255.224.0.0/11',
  '255.192.0.0/10',
  '255.128.0.0/9',
  '255.0.0.0/8',
  '254.0.0.0/7',
  '252.0.0.0/6',
  '248.0.0.0/5',
  '240.0.0.0/4',
  '224.0.0.0/3',
  '192.0.0.0/2',
  '128.0.0.0/1',
  '0.0.0.0/0',
]

const systemTypeDefaultFields = {
  autrosafe: {
    host: '',
    port: '',
  },
  autroprime: {
    host: '',
    username: '',
    password: '',
  },
}

const defaultForm = {
  ip: '',
  subnet: '',
  systems: [],
}

const deserialize = data => ({
  ip: data.ip || '',
  subnet: data.subnet || '',
  systems: data.systems.map(system => ({
    name: system.name || '',
    type: system.type || '',
    networkConfig:
      system.type === 'autroprime'
        ? {
            id: system.id,
            endpointId: system.modemEndpointId,
            host: system.host || '',
            username: system.username || '',
            password: system.password || '',
          }
        : {
            id: system.id,
            endpointId: system.modemEndpointId,
            host: system.host || '',
            port: system.port || '',
          },
  })),
})

export class ModemConfigurationFormUnwrapped extends React.Component {
  state = {
    form: this.props.configuration
      ? deserialize(this.props.configuration)
      : defaultForm,
  }

  updateNetworkConfig(index, property, value) {
    this.setState({
      form: {
        ...this.state.form,
        systems: [
          ...this.state.form.systems.slice(0, index),
          {
            ...this.state.form.systems[index],
            networkConfig: {
              ...this.state.form.systems[index]?.networkConfig,
              [property]: value,
            },
          },
          ...this.state.form.systems.slice(index + 1),
        ],
      },
    })
  }

  updateSystem(index, property, value) {
    this.setState({
      form: {
        ...this.state.form,
        systems: [
          ...this.state.form.systems.slice(0, index),
          {
            ...this.state.form.systems[index],
            [property]: value,
          },
          ...this.state.form.systems.slice(index + 1),
        ],
      },
    })
  }

  selectSystemType = index => value => {
    this.setState(state => ({
      form: {
        ...state.form,
        systems: [
          ...state.form.systems.slice(0, index),
          {
            ...state.form.systems[index],
            type: value,
            networkConfig: systemTypeDefaultFields[value],
          },
          ...state.form.systems.slice(index + 1),
        ],
      },
    }))
  }

  selectSubnet = value => {
    this.setState(state => ({
      form: {
        ...state.form,
        subnet: value,
      },
    }))
  }

  addSystem = () => {
    this.setState(state => ({
      form: {
        ...state.form,
        systems: [
          ...state.form.systems,
          {
            name: '',
            type: null,
            networkConfig: null,
          },
        ],
      },
    }))
  }

  deleteSystem(index) {
    this.setState(state => ({
      form: {
        ...state.form,
        systems: [
          ...state.form.systems.slice(0, index),
          ...state.form.systems.slice(index + 1),
        ],
      },
    }))
  }

  validateIp = ip =>
    /^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\.(?!$)|$)){4}$/.test(ip)

  validatePort = port => /^[0-9]+$/.test(port) && port >= 0 && port <= 65535

  validate = () => {
    let isValid = true
    const invalidate = () => {
      if (isValid) {
        isValid = false
      }
    }
    const { errors, ...form } = this.state.form

    if (!form.ip) {
      invalidate()
      form.errors = {
        ...form.errors,
        ip: 'Field is required',
      }
    }
    if (form.ip && !this.validateIp(form.ip)) {
      invalidate()
      form.errors = {
        ...form.errors,
        ip: 'Invalid IP Address',
      }
    }
    if (!form.subnet) {
      invalidate()
      form.errors = {
        ...form.errors,
        subnet: 'Field is required',
      }
    }
    if (form.subnet && !this.validateIp(form.subnet)) {
      invalidate()
      form.errors = {
        ...form.errors,
        subnet: 'Invalid subnet',
      }
    }
    const systems = form.systems || []
    systems.forEach((system, idx) => {
      if (!system.name) {
        invalidate()
        form.errors = {
          ...form.errors,
          name: {
            ...form.errors?.name,
            [idx]: 'Field is required',
          },
        }
      }
      if (system.type !== 'autrosafe' && system.type !== 'autroprime') {
        invalidate()
        form.errors = {
          ...form.errors,
          type: {
            ...form.errors?.type,
            [idx]: 'Field is required',
          },
        }
      }
      if (!system.networkConfig?.host) {
        invalidate()
        form.errors = {
          ...form.errors,
          host: {
            ...form.errors?.host,
            [idx]: 'Field is required',
          },
        }
      }
      if (
        system.networkConfig?.host &&
        !this.validateIp(system.networkConfig.host)
      ) {
        invalidate()
        form.errors = {
          ...form.errors,
          host: {
            ...form.errors?.host,
            [idx]: 'Invalid host IP Address',
          },
        }
      }
      if (
        system.networkConfig?.port &&
        !this.validatePort(system.networkConfig.port)
      ) {
        invalidate()
        form.errors = {
          ...form.errors,
          port: {
            ...form.errors?.port,
            [idx]: 'Invalid port number',
          },
        }
      }
      if (system.type === 'autrosafe' && !system.networkConfig?.port) {
        invalidate()
        form.errors = {
          ...form.errors,
          port: {
            ...form.errors?.port,
            [idx]: 'Field is required',
          },
        }
      }
      if (system.type === 'autroprime' && !system.networkConfig?.username) {
        invalidate()
        form.errors = {
          ...form.errors,
          username: {
            ...form.errors?.username,
            [idx]: 'Field is required',
          },
        }
      }
      if (system.type === 'autroprime' && !system.networkConfig?.password) {
        invalidate()
        form.errors = {
          ...form.errors,
          password: {
            ...form.errors?.password,
            [idx]: 'Field is required',
          },
        }
      }
    })

    this.setState({ form })

    return isValid
  }

  handleSubmit = async () => {
    if (this.validate()) {
      const { errors, ...data } = this.state.form
      const { error } = await updateModemConfiguration(
        this.props.modemId,
        this.serialize(data),
      )
      if (!error) {
        this.props.onClose()
        showSuccess('Modem network configuration successfully updated')
      } else {
        showError(error)
      }
    }
  }

  handleCancel = () => {
    this.setState({
      form: this.props.configuration
        ? deserialize(this.props.configuration)
        : defaultForm,
    })
    this.props.onClose()
  }

  serialize = data => ({
    ip: data.ip,
    subnet: data.subnet,
    systems: data.systems.map(system => ({
      id: system.id,
      name: system.name,
      type: system.type,
      endpointId: system.modemEndpointId,
      ...system.networkConfig,
    })),
  })

  findSubnet = substring => {
    subnets.filter(element => {
      if (element.indexOf(substring) !== -1) {
        return true
      }
    })
    return false
  }

  render() {
    const { form } = this.state
    return (
      <FormContainer>
        <Row>
          <Col md={6}>
            <Typography.H3>Modem</Typography.H3>
            <Form.Item width="400px">
              <Form.Label>
                <FormattedMessage id="modem.configuration.form.ip" />
              </Form.Label>
              <Input
                value={form.ip || ''}
                onChange={value =>
                  this.setState({
                    form: {
                      ...form,
                      ip: value.target.value,
                    },
                  })
                }
                spellcheck="false"
                data-test-id="modem-configuration-form-ip"
              />
              {form.errors?.ip && <Error>{form.errors.ip}</Error>}
            </Form.Item>
            <Form.Item width="400px">
              <Form.Label>
                <FormattedMessage id="modem.configuration.form.subnet" />
              </Form.Label>
              <Select.Dark
                value={
                  this.findSubnet(form.subnet) !== undefined
                    ? form.subnet
                    : undefined
                }
                onChange={value => this.selectSubnet(value.target.value)}
                spellcheck="false"
                data-test-id="modem-configuration-form-subnet"
              >
                <Select.Option value={undefined}>
                  {this.props.intl.formatMessage({
                    id: 'modem.configuration.select',
                  })}
                </Select.Option>
                {subnets.map(subnet => (
                  <Select.Option
                    key={subnet}
                    value={subnet.substring(0, subnet.indexOf('/'))}
                  >
                    {subnet}
                  </Select.Option>
                ))}
              </Select.Dark>
              {form.errors?.subnet && <Error>{form.errors.subnet}</Error>}
            </Form.Item>
            <SizedBox height="1" />
            <Typography.H3>Systems</Typography.H3>
            {form.systems.length > 0 ? (
              <>
                {form.systems.map((system, idx) => (
                  <Container key={idx}>
                    <DeleteButton
                      onClick={() => {
                        ConfirmationModal.show({
                          title: 'Are you sure?',
                          text:
                            'Are you sure you want to delete this system from config?',
                          onSubmit: () => this.deleteSystem(idx),
                        })
                      }}
                    >
                      <FormattedMessage id="modem.configuration.form.system.delete" />
                    </DeleteButton>
                    <SizedBox height="1" />
                    <Form.Select width="100%">
                      <Form.Label>
                        <FormattedMessage id="modem.configuration.system.type" />
                      </Form.Label>
                      <Select.Dark
                        value={system.type || undefined}
                        onChange={value => {
                          this.selectSystemType(idx)(value.target.value)
                        }}
                        spellcheck="false"
                        data-test-id="modem-configuration-system-type"
                      >
                        <Select.Option value={undefined}>
                          {this.props.intl.formatMessage({
                            id: 'modem.configuration.select',
                          })}
                        </Select.Option>
                        <Select.Option value="autroprime">
                          Autroprime
                        </Select.Option>
                        <Select.Option value="autrosafe">
                          AutroSafe
                        </Select.Option>
                      </Select.Dark>
                      {form.errors?.type?.[idx] && (
                        <Error>{form.errors.type[idx]}</Error>
                      )}
                    </Form.Select>
                    {(system.type === 'autroprime' ||
                      system.type === 'autrosafe') && (
                      <>
                        <Form.Item>
                          <Form.Label>
                            <FormattedMessage id="modem.configuration.form.system.name" />
                          </Form.Label>
                          <Input
                            value={system.name || ''}
                            onChange={value => {
                              this.updateSystem(idx, 'name', value.target.value)
                            }}
                            data-test-id="modem-configuration-form-system-name"
                            spellcheck="false"
                          />
                          {form.errors?.name?.[idx] && (
                            <Error>{form.errors.name[idx]}</Error>
                          )}
                        </Form.Item>
                        <Form.Item>
                          <Form.Label>
                            <FormattedMessage id="modem.configuration.system.host" />
                          </Form.Label>
                          <Input
                            value={system.networkConfig?.host || ''}
                            onChange={value => {
                              this.updateNetworkConfig(
                                idx,
                                'host',
                                value.target.value,
                              )
                            }}
                            data-test-id="modem-configuration-form-system-host"
                            spellcheck="false"
                          />
                          {form.errors?.host?.[idx] && (
                            <Error>{form.errors.host[idx]}</Error>
                          )}
                        </Form.Item>
                        {system.type === 'autrosafe' && (
                          <Form.Item>
                            <Form.Label>
                              <FormattedMessage id="modem.configuration.system.port" />
                            </Form.Label>
                            <Input
                              value={system.networkConfig?.port || ''}
                              onChange={value => {
                                this.updateNetworkConfig(
                                  idx,
                                  'port',
                                  value.target.value,
                                )
                              }}
                              data-test-id="modem-configuration-form-system-port"
                              spellcheck="false"
                            />
                            {form.errors?.port?.[idx] && (
                              <Error>{form.errors.port[idx]}</Error>
                            )}
                          </Form.Item>
                        )}
                        {system.type === 'autroprime' && (
                          <>
                            <Form.Item>
                              <Form.Label>
                                <FormattedMessage id="modem.configuration.system.username" />
                              </Form.Label>
                              <Input
                                value={system.networkConfig?.username || ''}
                                onChange={value => {
                                  this.updateNetworkConfig(
                                    idx,
                                    'username',
                                    value.target.value,
                                  )
                                }}
                                data-test-id="modem-configuration-form-system-username"
                                spellcheck="false"
                              />
                              {form.errors?.username?.[idx] && (
                                <Error>{form.errors.username[idx]}</Error>
                              )}
                            </Form.Item>
                            <Form.Item>
                              <Form.Label>
                                <FormattedMessage id="modem.configuration.system.password" />
                              </Form.Label>
                              <Input
                                value={system.networkConfig?.password || ''}
                                onChange={value => {
                                  this.updateNetworkConfig(
                                    idx,
                                    'password',
                                    value.target.value,
                                  )
                                }}
                                data-test-id="modem-configuration-form-system-password"
                                spellcheck="false"
                              />
                              {form.errors?.password?.[idx] && (
                                <Error>{form.errors.password[idx]}</Error>
                              )}
                            </Form.Item>
                          </>
                        )}
                      </>
                    )}
                  </Container>
                ))}
              </>
            ) : (
              <>
                <FormattedMessage id="modem.configuration.no.systems" />
                {form.errors?.systems && <Error>{form.errors.systems}</Error>}
                <SizedBox height="1" />
              </>
            )}
            <Button
              type="button"
              buttonType="secondary"
              onClick={() => this.addSystem()}
              data-test-id="modem-configuration-form-add-system-button"
            >
              <FormattedMessage id="modem.configuration.form.system.add" />
            </Button>
            <SizedBox height="2" />
            {form.errors && (
              <>
                <Error>
                  <FormattedMessage id="missing.form.fields" />
                </Error>
                <SizedBox height="1" />
              </>
            )}
            <SizedBox height="4" />
            <Form.Buttons>
              <Button
                data-test-id="modem-configuration-form-cancel"
                onClick={this.handleCancel}
                buttonType="secondary"
              >
                <FormattedMessage id="button.cancel" />
              </Button>
              <Button
                type="submit"
                data-test-id="modem-configuration-form-save"
                onClick={this.handleSubmit}
              >
                <FormattedMessage id="button.save" />
              </Button>
            </Form.Buttons>
          </Col>
        </Row>
      </FormContainer>
    )
  }
}

ModemConfigurationFormUnwrapped.propTypes = {
  onClose: PropTypes.func.isRequired,
  modemId: PropTypes.string.isRequired,
  configuration: PropTypes.object,
}

export const ModemConfigurationForm = compose(
  injectIntl,
  reduxForm({
    form: 'modem-configuration-form',
  }),
)(ModemConfigurationFormUnwrapped)
