import React from 'react'
import styled, { css } from 'styled-components'
import {
  FontWeightRegular,
  ColorFireRed,
  ColorMalachite,
  ColorPistachio,
  ColorWhite,
} from 'style/design-tokens'

const getVariant = ({ asyncValidating, active, dirty, error, disabled }) => {
  if (asyncValidating) return 'validating'
  if (active) return 'active'
  if (disabled) return 'disabled'
  if (dirty && !error) return 'success'
  if (dirty && error) return 'error'
  return 'default'
}

const StyledInputContainer = styled.div`
  background-color: ${ColorWhite};
  border-radius: 1px;
  border: 1px solid ${ColorPistachio};
  color: ${ColorMalachite};
  display: flex;
  font-weight: ${FontWeightRegular};
  flex-direction: row;
  align-items: center;
  padding: 0;
  outline: none;
  width: 100%;

  ${props => {
    const variant = getVariant(props)
    switch (variant) {
      case 'error':
        return css`
          border-color: ${ColorFireRed};
          color: ${ColorFireRed};
        `
      case 'active':
        return css`
          border-color: ${ColorMalachite};
        `
      case 'validating':
        return css`
          border-color: ${ColorMalachite};
        `
      case 'success':
        return css`
          border-color: ${ColorMalachite};
        `
      case 'disabled':
        return css`
          opacity: 0.5;
        `
      default:
        return ''
    }
  }}
`

const StyledInput = styled.textarea`
  border: none;
  outline: none;
  padding: 0;
  width: 100%;
  color: inherit;
  font-size: 1rem;
  padding: 1rem 0.8rem;
  ::placeholder {
    color: ${({ isError }) => (isError ? ColorFireRed : ColorMalachite)};
  }
`

const ErrorText = styled.label`
  color: ${ColorFireRed};
  font-size: 12px;
  margin-top: 4px;
`

export const Textarea = ({
  meta,
  placeholder,
  input,
  disabled,
  testId,
  type,
  ...rest
}) => (
  <>
    <StyledInputContainer {...meta} disabled={disabled}>
      <StyledInput
        type={type}
        placeholder={placeholder}
        isError={meta.error && meta.dirty}
        data-test-id={testId}
        {...rest}
        {...input}
      />
    </StyledInputContainer>
    {meta.error && meta.dirty && <ErrorText>{meta.error}</ErrorText>}
  </>
)
