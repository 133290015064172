import React from 'react'
import { history } from './history'
import {
  Router as ReactRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { connect } from 'react-redux'
import { PrivateRoute } from './PrivateRoute'
import { Login } from 'scenes/auth/Login/Login'
import { Register } from 'scenes/auth/Register/Register'
import { ConfirmEmail } from 'scenes/auth/ConfirmEmail/ConfirmEmail'
import { ForgotPassword } from 'scenes/auth/ForgotPassword/ForgotPassword'
import { Settings } from 'scenes/settings/Settings/Settings'
import { ResetPassword } from 'scenes/auth/ResetPassword/ResetPassword'
import { Page404 } from 'scenes/errors/Page404'
import { Page403 } from 'scenes/errors/Page403'
import { Administration } from 'scenes/administration/Administration'
import { Map } from 'scenes/map/Map'
import { UserPage } from 'scenes/users/UserPage/UserPage'
import { SystemsList } from 'scenes/systems/SystemsList/SystemsList'
import { SystemPage } from 'scenes/systems/SystemPage/SystemPage'
import { ModemsList } from 'scenes/modems/ModemsList/ModemsList'
import { ModemPage } from 'scenes/modems/ModemPage/ModemPage'
import { SitesList } from 'scenes/sites/SitesList/SitesList'
import { SitePage } from 'scenes/sites/SitePage/SitePage'
import { SystemConfigurationDocument } from 'scenes/systems/SystemPage/SystemConfigurations/SystemConfigurationDocument/SystemConfigurationDocument'
import { QrCodeGenerator } from 'scenes/qr-code-generator/QrCodeGenerator'
import { Invitation } from '../scenes/invitation/Invitation'
import { TermsAndConditions } from 'scenes/auth/TermsAndConditions/TermsAndConditions'
import { ScannedSystemPage } from 'scenes/scanned-system/ScannedSystemPage/ScannedSystemPage'
import { LoopPage } from 'scenes/scanned-system/ScannedSystemPage/LoopPage/LoopPage'
import { ReportsPage } from 'scenes/reports/ReportsPage/ReportsPage'
import { UnitReportsPage } from 'scenes/reports/ReportsPage/UnitReports/UnitReportsPage'
import { InstallBaseReportPage } from 'scenes/reports/ReportsPage/InstallBaseReport/InstallBaseReportPage'
import { SystemEventReportsPage } from 'scenes/reports/ReportsPage/SystemEventReports/SystemEventsReportPage'
import { UnitsAgeReportsPage } from 'scenes/reports/ReportsPage/UnitAgeReports/UnitAgeReportsPage'
import { SystemUnitReportsPage } from 'scenes/reports/ReportsPage/SystemUnitReport/SystemUnitReportPage'

const Home = connect(state => ({
  isSuperUser: state.authentication.user?.permissions?.isSuperUser ?? false,
}))(({ isSuperUser }) => {
  const invitationId = sessionStorage.getItem('invitation_id')
  if (invitationId) {
    return <Redirect to={`/invitations/${invitationId}`} />
  }
  return <Redirect to={isSuperUser ? '/administration' : '/sites'} />
})

const Logout = () => <Redirect to="/login" />

export const Router = () => (
  <ReactRouter history={history}>
    <Switch>
      <PrivateRoute exact path="/" component={Home} />
      <Route exact path="/404" component={Page404} />
      <Route exact path="/403" component={Page403} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/register" component={Register} />
      <Route
        exact
        path="/terms-and-conditions"
        component={TermsAndConditions}
      />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route
        exact
        path="/reset-password/:passwordResetToken"
        component={ResetPassword}
      />
      <Route exact path="/invitations/:id" component={Invitation} />
      <PrivateRoute exact path="/settings" component={Settings} />
      <PrivateRoute exact path="/confirm-email" component={ConfirmEmail} />
      <Route exact path="/administration" component={Administration} />
      <Route exact path="/map" component={Map} />
      <PrivateRoute exact path="/users/:id" component={UserPage} />
      <PrivateRoute exact path="/systems" component={SystemsList} />
      <PrivateRoute exact path="/systems/:id" component={SystemPage} />
      <PrivateRoute exact path="/modems" component={ModemsList} />
      <PrivateRoute exact path="/reports" component={ReportsPage} />
      <PrivateRoute exact path="/unit-reports" component={UnitReportsPage} />
      <PrivateRoute
        exact
        path="/system-unit-reports"
        component={SystemUnitReportsPage}
      />
      <PrivateRoute
        exact
        path="/install-base-reports"
        component={InstallBaseReportPage}
      />
      <PrivateRoute
        exact
        path="/system-event-reports"
        component={SystemEventReportsPage}
      />
      <PrivateRoute
        exact
        path="/unit-age-reports"
        component={UnitsAgeReportsPage}
      />
      <PrivateRoute exact path="/modems/:id" component={ModemPage} />
      <PrivateRoute exact path="/sites" component={SitesList} />
      <PrivateRoute exact path="/sites/:id" component={SitePage} />
      <PrivateRoute
        exact
        path="/scanned-systems/:id"
        component={ScannedSystemPage}
      />
      <PrivateRoute exact path="/scanned-loops/:id" component={LoopPage} />
      <PrivateRoute
        exact
        path="/qr-code-generator"
        component={QrCodeGenerator}
      />
      <PrivateRoute
        exact
        path="/systems/:id/configurations/:hash.:extension"
        component={SystemConfigurationDocument}
      />
      <PrivateRoute component={Page404} />
    </Switch>
  </ReactRouter>
)
