import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ColorWhite } from 'style/design-tokens.js'
import { ModemSystemConnectionStage } from 'components/molecules'

const StagesContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
`

const StageIndicatorSeparator = styled.div`
  display: flex;
  height: 10px;
  width: 4px;
  background-color: ${ColorWhite};
  margin-left: 16px;
`

export const ModemSystemConnectionStages = ({
  description,
  stages,
  testId,
}) => (
  <>
    <StagesContainer data-test-id={testId}>
      <ModemSystemConnectionStage
        status={stages.mobileConnection}
        textId={'modem.configuration.system.stage.mobileConnection'}
        description={description}
      />
      <StageIndicatorSeparator />
      <ModemSystemConnectionStage
        status={stages.networkConfig}
        textId={'modem.configuration.system.stage.networkConfig'}
        description={description}
      />
      <StageIndicatorSeparator />
      <ModemSystemConnectionStage
        status={stages.panelConfig}
        textId={'modem.configuration.system.stage.panelConfiguration'}
        description={description}
      />
      <StageIndicatorSeparator />
      <ModemSystemConnectionStage
        status={stages.liveUpdates}
        textId={'modem.configuration.system.stage.liveUpdates'}
        description={description}
      />
    </StagesContainer>
  </>
)

ModemSystemConnectionStages.defaultProps = {
  description: '',
  stages: {
    mobileConnection: 'pending',
    networkConfig: 'pending',
    panelConfig: 'pending',
    liveUpdates: 'pending',
  },
}

ModemSystemConnectionStages.propTypes = {
  description: PropTypes.string,
  stages: PropTypes.any,
  testId: PropTypes.string,
}
