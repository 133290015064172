import React from 'react'
import { Error, Tag, Link } from 'components/atoms'
import { Table } from 'components/organisms'
import { withSystemIssues } from 'api'
import { formatDateTime } from 'utils/formatters'

const buildIssueSeverity = record => {
  if (record.hasAlarms) {
    return <Tag type="warning">Alarm</Tag>
  }
  return <Tag>{record.isUrgent ? 'Critical' : 'Non-critical'}</Tag>
}

const columns = [
  {
    title: 'Number',
    render: record => record.unitName || record.unitConfigId,
  },
  {
    title: 'Unit',
    key: 'unitName',
  },
  {
    title: 'Hardware',
    render: record => (
      <Link to={`/hardware/${record.hardwareName}`}>{record.hardwareName}</Link>
    ),
    externalLink: true,
  },
  {
    title: 'Created at',
    render: record => formatDateTime(record.createdAt),
  },
  {
    title: 'Assignee',
    render: record =>
      `${record.assigneeFirstName || ''} ${record.assigneeLastName || ''}`,
  },
  {
    title: 'Severity',
    render: record => buildIssueSeverity(record),
  },
  {
    title: 'Status',
    key: 'status',
    render: record => (record.isSolved ? <Tag>Solved</Tag> : <Tag>Open</Tag>),
  },
]

export const SystemIssues = ({ systemId }) => {
  const { data, isLoading, error, refresh, ...rest } = withSystemIssues(
    systemId,
  )()

  return (
    <>
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns}
        data={data}
        {...rest}
      ></Table>
    </>
  )
}
