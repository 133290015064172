import React from 'react'
import { pathOr } from 'ramda'
import { useIntl } from 'react-intl'
import { Row, Statistic } from 'components/atoms'
import { withStatistics } from 'api'

export const Statistics = () => {
  let intl = useIntl()
  const { data } = withStatistics()
  return (
    <Row>
      <Row.Col size={6}>
        <Statistic
          title={intl.formatMessage({
            id: 'statistics.modems',
          })}
          value={pathOr('0', ['modemsCount'], data)}
        />
      </Row.Col>
      <Row.Col size={6}>
        <Statistic
          title={intl.formatMessage({
            id: 'statistics.loop_units',
          })}
          value={pathOr('0', ['unitsCount'], data)}
        />
      </Row.Col>
      <Row.Col size={6}>
        <Statistic
          title={intl.formatMessage({
            id: 'statistics.systems',
          })}
          value={pathOr('0', ['systemsCount'], data)}
        />
      </Row.Col>
      <Row.Col size={6}>
        <Statistic
          title={intl.formatMessage({
            id: 'statistics.sites',
          })}
          value={pathOr('0', ['sitesCount'], data)}
        />
      </Row.Col>
      <Row.Col size={6}>
        <Statistic
          title={intl.formatMessage({
            id: 'statistics.users',
          })}
          value={pathOr('0', ['usersCount'], data)}
        />
      </Row.Col>
    </Row>
  )
}
