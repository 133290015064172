import React from 'react'
import { SizedBox, Button, Column, Protected } from 'components/atoms'
import { FormattedMessage } from 'react-intl'
import { MainLayout } from 'layout/MainLayout'
import { withRouter } from 'react-router-dom'
import { AccessLevels } from 'utils/AccesLevels'
import { UserTypes } from 'utils/UserTypes'

export const ReportsPage = withRouter(({ history }) => (
  <MainLayout>
    <Column>
      <h1>
        <FormattedMessage id="reports.header.select.type" />
      </h1>
      <SizedBox height="1" />
      <Button
        width="20rem"
        data-test-id="button.unit.report"
        onClick={() => {
          history.push('/unit-reports')
        }}
      >
        <FormattedMessage id="reports.button.unit.report" />
      </Button>
      <SizedBox height="1" />
      <Button
        width="20rem"
        data-test-id="button.system.event.report"
        onClick={() => {
          history.push('/system-event-reports')
        }}
      >
        <FormattedMessage id="reports.button.system.events.report" />
      </Button>
      <SizedBox height="1" />
      <Protected
        globalAccessLevel={AccessLevels.Admin}
        userType={UserTypes.Super}
      >
        <Button
          width="20rem"
          data-test-id="button.install.base.report"
          onClick={() => {
            history.push('/install-base-reports')
          }}
        >
          <FormattedMessage id="reports.button.install.base.report" />
        </Button>
      </Protected>
      <SizedBox height="1" />
      <Protected
        globalAccessLevel={AccessLevels.Admin}
        userType={UserTypes.Super}
      >
        <Button
          width="20rem"
          data-test-id="button.system.unit.report"
          onClick={() => {
            history.push('/system-unit-reports')
          }}
        >
          <FormattedMessage id="reports.button.system.unit.report" />
        </Button>
      </Protected>
      <SizedBox height="1" />
      <Protected
        globalAccessLevel={AccessLevels.Admin}
        userType={UserTypes.Super}
      >
        <Button
          width="20rem"
          data-test-id="button.system.unit.age.report"
          onClick={() => {
            history.push('/unit-age-reports')
          }}
        >
          <FormattedMessage id="reports.button.unit.age.report" />
        </Button>
      </Protected>
    </Column>
  </MainLayout>
))
