import React from 'react'
import styled from 'styled-components'
import {
  ColorWhite,
  ColorGreenOnion,
  ColorRichCarmine,
  ColorKoalaGrey,
  FontWeightRegular,
} from 'style/design-tokens.js'
import { FormattedMessage } from 'react-intl'

const stageColors = {
  pending: ColorKoalaGrey,
  ok: ColorGreenOnion,
  fail: ColorRichCarmine,
}

const StageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`

const StageNameContainer = styled.div`
  font-size: 1rem;
  font-weight: ${FontWeightRegular};
`

const StageDescriptionContainer = styled.div`
  font-size: 0.8rem;
  font-weight: ${FontWeightRegular};
`

const StageIndicatorContainer = styled.div`
  display: inline-flex;
  align-content: center;
  gap: 5px;
`

const StageIndicator = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  background-color: ${({ color }) => (color ? color : 'inherit')};
  border-radius: 50%;
  border: 4px solid ${ColorWhite};
`

export const ModemSystemConnectionStage = ({ status, textId, description }) => {
  return (
    <StageIndicatorContainer>
      <StageIndicator color={stageColors[status]} />
      <StageTextContainer>
        <StageNameContainer>
          <FormattedMessage id={textId} />
        </StageNameContainer>
        <StageDescriptionContainer>
          {status === 'fail' && description}
        </StageDescriptionContainer>
      </StageTextContainer>
    </StageIndicatorContainer>
  )
}
