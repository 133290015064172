import styled, { css } from 'styled-components'
import {
  ColorFireRed,
  ColorMalachite,
  ColorAlmostBlack,
  ColorCharcoalGrey,
  ColorMisty,
} from 'style/design-tokens'

const getVariant = ({ asyncValidating, active, dirty, error, disabled }) => {
  if (asyncValidating) return 'validating'
  if (active) return 'active'
  if (disabled) return 'disabled'
  if (dirty && !error) return 'success'
  if (dirty && error) return 'error'
  return 'default'
}

export const Select = styled.select`
  padding: 0 0.5rem;
  font-size: 16px;
  color: ${ColorAlmostBlack};
  border-radius: 1px;
  width: 100%;
  max-width: 500px;
  outline: none;
  border: none;
  height: 38px;
  width: ${({ width }) => width || '100%'};
  ${props => {
    const variant = getVariant(props)
    switch (variant) {
      case 'error':
        return css`
          border-color: ${ColorFireRed};
          color: ${ColorFireRed};
        `
      case 'active':
        return css`
          border-color: ${ColorMalachite};
        `
      case 'validating':
        return css`
          border-color: ${ColorMalachite};
        `
      case 'success':
        return css`
          border-color: ${ColorMalachite};
        `
      case 'disabled':
        return css`
          opacity: 0.5;
        `
      default:
        return ''
    }
  }};
`

Select.Dark = styled(Select)`
  color: ${ColorMisty};
  background-color: ${ColorCharcoalGrey};
  height: 30px;
`

Select.Option = styled.option``
