import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import { Error, SizedBox, IconDropdown, TextWrapper } from 'components/atoms'
import { Table } from 'components/organisms'
import { withLoopUnits, deleteLoopUnit } from 'api'
import { ConfirmationModal } from 'components/atoms'
import { Protected, Space, Spacing } from 'components/atoms'
import { AccessLevels } from 'utils/AccesLevels'
import { useIntl, FormattedMessage } from 'react-intl'
import { EditUnitModal } from '../EditUnitModal/EditUnitModal'
import { AddUnitModal } from '../AddUnitModal/AddUnitModal'

export const ScannedUnitsList = compose(withRouter)(({ match, siteId }) => {
  let intl = useIntl()
  const [editModalVisible, setEditModalVisibility] = useState(false)
  const [unitToEdit, setUnitToEdit] = useState({})

  const { data, isLoading, error, refresh, ...rest } = withLoopUnits(
    match.params.id,
  )()
  const columns = refresh => [
    {
      title: <FormattedMessage id={'scanned.units.list.header.lsiNumber'} />,
      key: 'lsiNumber',
      render: record =>
        record.lsiNumber !== null && record.lsiNumber.length > 0
          ? record.lsiNumber
          : '-',
    },
    {
      title: <FormattedMessage id={'scanned.units.list.header.unitType'} />,
      key: 'unitType',
      render: record =>
        record.unitType !== null && record.unitType.length > 0
          ? record.unitType
          : '-',
    },
    {
      title: <FormattedMessage id={'scanned.units.list.header.tagName'} />,
      key: 'tagName',
      render: record =>
        record.tagName !== null && record.tagName.length > 0
          ? record.tagName
          : '-',
    },
    {
      title: (
        <FormattedMessage id={'scanned.units.list.header.detectionZone'} />
      ),
      key: 'detectionZone',
      render: record =>
        record.detectionZone ? (
          <TextWrapper>{record.detectionZone}</TextWrapper>
        ) : (
          '-'
        ),
    },
    {
      title: <FormattedMessage id={'scanned.units.list.header.serialNumber'} />,
      key: 'serialNumber',
      render: record =>
        record.serialNumber !== null && record.serialNumber.length > 0
          ? record.serialNumber
          : '-',
    },
    {
      title: <FormattedMessage id={'scanned.units.list.header.comment'} />,
      key: 'comment',
      render: record =>
        record.comment !== null && record.comment.length > 0
          ? record.comment
          : '-',
    },
    {
      title: <FormattedMessage id={'table.header.actions'} />,
      key: 'actions',
      render: record => (
        <Protected
          resourceId={siteId}
          accessLevel={AccessLevels.Admin}
          renderWithoutPermission={<>-</>}
        >
          <IconDropdown
            align="right"
            type="3dots"
            options={getDropdownOptions(record, refresh)}
          />
        </Protected>
      ),
    },
  ]

  const toggleEditModal = () => {
    setEditModalVisibility(!editModalVisible)
  }

  const getDropdownOptions = (record, refresh) => {
    return [
      {
        label: 'button.modify',
        action: () => {
          setUnitToEdit(record)
          toggleEditModal()
        },
      },
      {
        label: 'button.delete',
        action: () => {
          ConfirmationModal.show({
            title: intl.formatMessage({
              id: 'modal.confirmation.default',
            }),
            text: intl.formatMessage({
              id: 'modal.delete.scanned.unit.confirmation',
            }),
            onSubmit: async () => {
              const { error } = await deleteLoopUnit(record.id)
              if (!error) {
                refresh()
              } else {
                throw error
              }
            },
          })
        },
      },
    ]
  }
  return (
    <>
      <SizedBox height="1" />
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns(refresh)}
        data={data}
        {...rest}
      />
      <Protected resourceId={siteId} accessLevel={AccessLevels.Admin}>
        <Spacing mt="1" width="100%">
          <Space right>
            <AddUnitModal loopId={match.params.id} refresh={refresh} />
          </Space>
        </Spacing>
      </Protected>
      <Protected resourceId={siteId} accessLevel={AccessLevels.Admin}>
        <Spacing mt="1" width="100%">
          <Space right>
            <EditUnitModal
              loopId={match.params.id}
              refresh={refresh}
              isOpen={editModalVisible}
              onRequestClose={toggleEditModal}
              unit={unitToEdit}
            />
          </Space>
        </Spacing>
      </Protected>
    </>
  )
})
