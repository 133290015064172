import axios from 'axios'
import { path } from 'ramda'
import { store } from 'rdx/store'
import { API_BASE_URL } from '../config'
import { showForbiddenError, showError } from 'layout/notifications'

const getToken = () => {
  const state = store.getState()
  return path(['authentication', 'token'], state)
}

export const request = axios.create({
  baseURL: API_BASE_URL,
})

request.interceptors.request.use(
  config => {
    config.headers.Authorization = getToken()
    return config
  },
  error => Promise.reject(error),
)

request.interceptors.response.use(
  _ => _,
  error => {
    if (path(['response', 'status'], error) === 403) {
      if (
        path(['response', 'data', 'statusText'], error) ===
        'user.invalid_auth_token'
      ) {
        window.location.replace('/login')
      }
      showForbiddenError()
    }
    if (!error.response) {
      if (error.message === 'Network Error') {
        showError('No internet connection')
      }
    }
    throw error
  },
)

export const encodeObjectValues = object =>
  Object.fromEntries(
    Object.entries(object)
      .filter(entry => !!entry[1])
      .map(entry => [entry[0], encodeURI(entry[1])]),
  )
