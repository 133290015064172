import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field, reduxForm } from 'redux-form'
import { required } from 'redux-form-validators'
import { Form, Button, SizedBox } from 'components/atoms'
import { Input } from 'components/molecules'
import { ScannedInputLimits } from 'utils/InputLimits'

const validate = values => {
  let errors = {}
  if (values.name && values.name.length > ScannedInputLimits.NameMaxLength) {
    errors.name = `Name is too long (should be at max ${ScannedInputLimits.NameMaxLength} characters)`
  }
  return errors
}

const AddLoopFormUnwrapped = ({
  handleSubmit,
  loading,
  pristine,
  invalid,
  onCancel,
}) => (
  <Form
    onSubmit={handleSubmit}
    description={<FormattedMessage id="loops.add.form.description" />}
  >
    <SizedBox height="1" />
    <Form.Item width="60%">
      <Form.Label>
        <FormattedMessage id="form.scanned.loop.name" />
      </Form.Label>
      <Field
        component={Input}
        name="name"
        validate={[required()]}
        testId="scanned-loop-add-form-name-input"
      />
    </Form.Item>
    <Form.Buttons>
      <Button
        disabled={loading}
        buttonType="secondary"
        onClick={onCancel}
        data-test-id="add-loop-form_cancel"
      >
        <FormattedMessage id="button.cancel" />
      </Button>
      <Button
        disabled={loading || pristine || invalid}
        isLoading={loading}
        type="submit"
        buttonType="primary"
        isActive
        data-test-id="add-loop-form-submit"
      >
        <FormattedMessage id="button.save" />
      </Button>
    </Form.Buttons>
  </Form>
)

AddLoopFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export const AddLoopForm = reduxForm({
  form: 'add-loop-form',
  validate,
})(AddLoopFormUnwrapped)
