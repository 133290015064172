import React from 'react'
import { reset } from 'redux-form'
import { store } from 'rdx/store'
import { Spacing, Typography, Divider } from 'components/atoms'
import { FormattedMessage } from 'react-intl'
import { FormProvider } from 'providers/FormProvider'
import { EditLoopForm } from './EditLoopForm'

const getInitialValues = pick => ({
  name: pick(['name']),
})

export const EditLoop = ({ refresh, loopId, pick }) => {
  return (
    <>
      <Divider />
      <Spacing width="100%" mt="3" ml="2">
        <Typography.H3>
          <FormattedMessage id="scanned.loop.edit.details" />
        </Typography.H3>
        <FormProvider
          method="put"
          onSuccess={() => {
            refresh()
            store.dispatch(reset('edit-loop-form'))
          }}
          url={`/v1/scanned-loops/${loopId}`}
          render={({ updating, handleSubmit }) => (
            <EditLoopForm
              loading={updating}
              onSubmit={handleSubmit}
              initialValues={getInitialValues(pick)}
            />
          )}
        />
      </Spacing>
    </>
  )
}
