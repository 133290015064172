import styled, { css } from 'styled-components'
import { ColorWhite, ColorCharcoalGrey } from 'style/design-tokens'

const base = css`
  color: ${({ type }) => {
    switch (type) {
      case 'dark':
        return ColorCharcoalGrey
      default:
        return ColorWhite
    }
  }};
`

const spacing = css`
  ${({ mt }) => (mt ? `margin-top: ${mt}rem;` : '')}
  ${({ mr }) => (mr ? `margin-right: ${mr}rem;` : '')}
  ${({ mb }) => (mb ? `margin-bottom: ${mb}rem;` : '')}
  ${({ ml }) => (ml ? `margin-left: ${ml}rem;` : '')}
  ${({ pt }) => (pt ? `padding-top: ${pt}rem;` : '')}
  ${({ pr }) => (pr ? `padding-right: ${pr}rem;` : '')}
  ${({ pb }) => (pb ? `padding-bottom: ${pb}rem;` : '')}
  ${({ pl }) => (pl ? `padding-left: ${pl}rem;` : '')}
`

export const Typography = {}

const H1 = styled.h1`
  ${base}
  font-size: 2rem;
  font-weight: 400;
  margin: 0;
  margin-bottom: 1.6rem;
  ${spacing}
`

const H2 = styled.h2`
  ${base}
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  margin-bottom: 1.7rem;
  ${spacing}
`

const H3 = styled.h3`
  ${base}
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0;
  margin-bottom: 1.5rem;
  ${spacing}
`

const P = styled.p`
  ${base}
  margin: 0;
  margin-bottom: 1.6rem;
  font-size: 1rem;
  ${spacing}
`

const Link = styled.link`
  ${base}
  font-size: 1rem;
`

const Label = styled.span`
  ${base}
  font-size: 0.8rem;
  font-weight: 400;
  display: block;
  margin-bottom: 0.6rem;
  ${spacing}
`

const Ghost = styled.span`
  ${base}
  font-size: 0.8rem;
  font-weight: 400;
  display: block;
  margin-bottom: 0.6rem;
  opacity: 0.6;
  ${spacing}
`

Typography.H1 = H1
Typography.H2 = H2
Typography.H3 = H3
Typography.P = P
Typography.Link = Link
Typography.Label = Label
Typography.Ghost = Ghost
