import React from 'react'
import { Spacing, Divider } from 'components/atoms'
import { FormProvider } from 'providers/FormProvider'
import { store } from 'rdx/store'
import { reset } from 'redux-form'
import { EditUserForm } from './EditUserForm'

const getInitialValues = pick => ({
  firstName: pick(['firstName']),
  lastName: pick(['lastName']),
  phoneNumber: pick(['phoneNumber']),
  addressLine1: pick(['address', 'addressLine1']),
  addressLine2: pick(['address', 'addressLine2']),
  city: pick(['address', 'city']),
  country: pick(['address', 'country']),
  postalCode: pick(['address', 'postalCode']),
})

export const EditUser = ({ refresh, userId, pick }) => (
  <>
    <Divider />
    <Spacing width="100%" mt="3" ml="2">
      <FormProvider
        method="put"
        onSuccess={() => {
          refresh(() => {
            store.dispatch(reset('edit-user-form'))
          })
        }}
        url={`/v1/users/${userId}`}
        render={({ updating, handleSubmit }) => (
          <EditUserForm
            loading={updating}
            onSubmit={handleSubmit}
            initialValues={getInitialValues(pick)}
          />
        )}
      />
    </Spacing>
  </>
)
