import React from 'react'
import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { ColorSeaBlue } from 'style/design-tokens'

const Container = styled.span`
  color: ${({ color }) => (color ? color : ColorSeaBlue)};
  font-size: 1rem;
  cursor: pointer;
`

export const Link = ({ children, ...props }) => (
  < RouterLink {...props}>
    <Container color={props.color}>{children}</Container>
  </RouterLink >
)
