import React from 'react'
import { Spacing, Divider } from 'components/atoms'
import { FormProvider } from 'providers/FormProvider'
import { store } from 'rdx/store'
import { reset } from 'redux-form'
import { UserPermissionsForm } from './UserPermissionsForm'
import { UserTypes } from 'utils/UserTypes'

const getInitialValues = pick => ({
  userType: pick(['permissions', 'isSuperUser'])
    ? UserTypes.Super
    : UserTypes.Regular,
  globalAccessLevel: pick(['permissions', 'globalAccessLevel']),
})

export const Permissions = ({ refresh, userId, pick }) => (
  <>
    <Divider />
    <Spacing width="100%" mt="3" ml="2">
      <FormProvider
        method="put"
        onSuccess={() => {
          refresh(() => {
            store.dispatch(reset('permissions-user-form'))
          })
        }}
        url={`/v1/users/${userId}/permissions`}
        render={({ updating, handleSubmit }) => (
          <UserPermissionsForm
            loading={updating}
            onSubmit={handleSubmit}
            initialValues={getInitialValues(pick)}
          />
        )}
      />
    </Spacing>
  </>
)
