import React from 'react'
import { components } from 'react-select'
import { Icon } from 'components/atoms/Icon/Icon'

export const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon
      iconType={props.selectProps.menuIsOpen ? 'caretUp' : 'caretDown'}
    ></Icon>
  </components.DropdownIndicator>
)

export const Option = props => (
  <components.Option {...props}>
    {props.children}
    <Icon iconType={props.isSelected ? 'checkMark' : 'default'} />
  </components.Option>
)
