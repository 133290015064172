import React from 'react'
import ReactJsonView from 'react-json-view'
import ReactXMLView from 'react-xml-viewer'

const style = {
  color: 'black',
  minHeight: '100vh',
  overflow: 'auto',
  backgroundColor: 'white',
  fontFamily: '"Courier New", Courier, monospace',
  fontSize: '1.2rem',
  padding: '1rem',
}

const createDocumentContent = (extension, data) => {
  switch (extension) {
    case 'xml': {
      return <ReactXMLView xml={data.xml ? data.xml : data} />
    }
    case 'json':
      return <ReactJsonView src={data} />
    default:
      return null
  }
}

export const SystemConfigurationDocument = ({ match, location }) => {
  const { params } = match
  const { extension } = params
  return (
    <div style={style}>
      {createDocumentContent(extension, location.state?.data)}
    </div>
  )
}
