import styled from 'styled-components'

export const Space = styled.div`
  display: flex;
  ${({ right }) =>
    right
      ? `
      justify-content: flex-end;
      width: 100%;
      & > * {
        margin-left: 1rem;
      }
  `
      : `
  & > * {
    margin-right: 1rem;
  }
  `}
`
