import React from 'react'
import { useIntl } from 'react-intl'
import { Row, Statistic } from 'components/atoms'

export const InstallBaseReportStatistics = ({ meta }) => {
  let intl = useIntl()
  return (
    <Row>
      <Row.Col size={8}>
        <Statistic
          title={intl.formatMessage({
            id: 'statistics.systems',
          })}
          value={meta.totalSystems}
        />
      </Row.Col>
      <Row.Col size={8}>
        <Statistic
          title={intl.formatMessage({
            id: 'statistics.contracts',
          })}
          value={meta.totalSystemsWithContracts}
        />
      </Row.Col>
      <Row.Col size={8}>
        <Statistic
          title={intl.formatMessage({
            id: 'statistics.sites',
          })}
          value={meta.totalSites}
        />
      </Row.Col>
    </Row>
  )
}
