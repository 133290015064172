import React from 'react'
import { compose } from 'recompose'
import { MainLayout } from 'layout/MainLayout'
import { Typography } from 'components/atoms'
import { FormattedMessage } from 'react-intl'
import { FormProvider } from 'providers/FormProvider'
import { withRouter } from 'react-router'
import { signedIn as signedInAction } from 'rdx/actions/authActions'
import { RegisterForm } from './RegisterForm/RegisterForm'
import { connect } from 'react-redux'

const RegisterUnwrapped = withRouter(({ history, signedIn }) => (
  <MainLayout.FullPageScreen>
    <Typography.H1>
      <FormattedMessage id="register.header" />
    </Typography.H1>
    <Typography.H3>
      <FormattedMessage id="register.description" />
    </Typography.H3>
    <FormProvider
      onSuccess={response => {
        signedIn(response.data)
        history.push('/confirm-email')
      }}
      url="/v1/auth/register"
      render={({ updating, handleSubmit }) => (
        <RegisterForm loading={updating} onSubmit={handleSubmit} />
      )}
    />
  </MainLayout.FullPageScreen>
))

export const Register = compose(
  withRouter,
  connect(null, { signedIn: signedInAction }),
)(RegisterUnwrapped)
