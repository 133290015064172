import React, { useState } from 'react'
import {
  SizedBox,
  Row,
  Link,
  Address,
  Error,
  CircleStatusIndicator,
} from 'components/atoms'
import { MainLayout } from 'layout/MainLayout'
import { Table } from 'components/organisms'
import { withRouter } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { formatDate, formatTime } from 'utils/formatters'
import { SystemUnitReportsStatistics } from './SystemUnitReportsStatistics'
import { GenerateSystemUnitReportForm } from './GenerateSystemUnitReportForm/GenerateSystemUnitReportForm'
import { omitUndefinedKeys } from 'utils/helpers'
import { formatDateTime } from 'utils/formatters'
import { DownloadSystemUnitReport } from './DownloadSystemUnitReport'
import { withSystemUnitReports } from 'api'
import { ModemSystemState } from 'utils/ModemSystemState'
import { ColorAckGreen, ColorFireRed, ColorSage } from 'style/design-tokens.js'

const columns = [
  {
    title: <FormattedMessage id="reports.headers.customer.number" />,
    key: 'customerNumber',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.eq.number" />,
    key: 'equipmentNumber',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.system.name" />,
    key: 'systemName',
    render: record => (
      <Link to={`/systems/${record.systemId}`}>
        {record.systemName ? record.systemName : '-'}
      </Link>
    ),
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.system.type" />,
    key: 'systemType',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.site.number" />,
    key: 'siteNumber',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.site.name" />,
    key: 'siteName',
    render: record => (
      <Link to={`/sites/${record.siteId}`}>
        {record.siteName ? record.siteName : '-'}
      </Link>
    ),
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.site.address" />,
    key: 'address',
    render: record => <Address address={record.address} />,
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.configuration.time" />,
    key: 'configurationTime',
    render: record =>
      record.configurationTime
        ? formatDate(record.configurationTime) +
          '\n' +
          formatTime(record.configurationTime)
        : '-',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.hardware" />,
    key: 'hardware',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.units.count" />,
    key: 'unitsCount',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.business.unit" />,
    key: 'businessUnit',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.connection" />,
    key: 'connectionStatus',
    render: record =>
      record.connectionStatus ? (
        record.connectionStatus === ModemSystemState.SystemConnected ? (
          <CircleStatusIndicator color={ColorAckGreen} />
        ) : (
          <CircleStatusIndicator color={ColorFireRed} />
        )
      ) : (
        <CircleStatusIndicator color={ColorSage} />
      ),
    sortable: true,
  },
]

export const SystemUnitReportsPage = withRouter(({ history }) => {
  // eslint-disable-next-line no-unused-vars
  const [producedBefore, setProducedBefore] = useState(null)
  const {
    data,
    isLoading,
    refresh,
    error,
    filters,
    onChange,
    sorter,
    pagination,
    meta,
    ...rest
  } = withSystemUnitReports()
  const intl = useIntl()

  const serialize = data =>
    omitUndefinedKeys({
      producedBefore: data.producedBefore
        ? formatDateTime(data.producedBefore)
        : undefined,
    })

  const handleSubmit = values => {
    setProducedBefore({
      producedBefore: formatDateTime(values.producedBefore),
    })
    onChange(
      undefined,
      {
        ...filters,
        ...serialize(values),
      },
      sorter,
    )
  }

  const refreshTable = () => {
    onChange(undefined, { ...filters, producedBefore: undefined }, sorter)
  }

  return (
    <MainLayout
      header={intl.formatMessage({
        id: 'reports.header.system.units',
      })}
    >
      <SizedBox height="1" />
      <Row width="40rem">
        <GenerateSystemUnitReportForm
          onSubmit={values => {
            handleSubmit(values)
          }}
          refresh={refreshTable}
        />
        <SizedBox width="0.5" />
        <div>
          <SizedBox height="0.5" />
          <DownloadSystemUnitReport formValues={{ ...filters, ...sorter }} />
        </div>
      </Row>
      <SizedBox height="1" />
      <SystemUnitReportsStatistics meta={meta} />
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns}
        data={data}
        history={history}
        sortingParams={sorter}
        filteringParams={filters}
        pagination={pagination}
        onChange={onChange}
        refresh={refresh}
        hasIterator
        {...rest}
      />
    </MainLayout>
  )
})
