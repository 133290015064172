import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { Button, Form, Spacing } from 'components/atoms'
import { Input } from 'components/molecules'
import { FormattedMessage } from 'react-intl'
import { required } from 'redux-form-validators'
import { withRouter } from 'react-router-dom'
import { store } from 'rdx/store'
import { reset } from 'redux-form'

const DeleteAccountFormUnwrapped = withRouter(
  ({ handleSubmit, loading, pristine, invalid }) => {
    return (
      <Form.Dark onSubmit={handleSubmit}>
        <Form.Item width="400px">
          <Form.Label htmlFor="password">
            <FormattedMessage id="form.password" />
          </Form.Label>
          <Field
            component={Input}
            name="password"
            type="password"
            validate={[required()]}
            testId="delete-account-form_password"
          />
        </Form.Item>

        <Spacing mt="3">
          <Form.Buttons>
            <Button
              loading={loading}
              disabled={pristine}
              buttonType="secondary"
              data-test-id="delete-account-form-cancel"
              onClick={() => {
                store.dispatch(reset('delete-account-form'))
              }}
            >
              <FormattedMessage id="button.cancel" />
            </Button>
            <Button
              type="submit"
              disabled={pristine || invalid || loading}
              data-test-id="delete-account-form_submit"
            >
              <FormattedMessage id="button.submit" /> {loading ? '...' : ''}
            </Button>
          </Form.Buttons>
        </Spacing>
      </Form.Dark>
    )
  },
)

DeleteAccountFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export const DeleteAccountForm = reduxForm({
  form: 'delete-account-form',
})(DeleteAccountFormUnwrapped)
