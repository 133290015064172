import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAt,
  faCaretDown,
  faCaretUp,
  faCheckCircle,
  faCircleNotch,
  faExclamationCircle,
  faExclamationTriangle,
  faFilter,
  faLock,
  faPlus,
  faSearch,
  faUser,
  faUserFriends,
  faPen,
  faCog,
  faSyncAlt,
  faTimes,
  faTrash,
  faMapMarkerAlt,
  faSync,
  faEllipsisH,
  faLockOpen,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import { faUser as faUserRegular } from '@fortawesome/free-regular-svg-icons'

export const Icon = ({ type, size, spin }) => {
  switch (type) {
    case 'user':
      return <FontAwesomeIcon icon={faUser} size={size ? size : 'xs'} />
    case 'padlock':
      return <FontAwesomeIcon icon={faLock} size={size ? size : 'xs'} />
    case 'padlock-open':
      return <FontAwesomeIcon icon={faLockOpen} size={size ? size : 'xs'} />
    case 'exclamation':
      return (
        <FontAwesomeIcon icon={faExclamationCircle} size={size ? size : 'xs'} />
      )
    case 'exclamationTriangle':
      return (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size={size ? size : 'xs'}
        />
      )
    case 'check':
      return <FontAwesomeIcon icon={faCheckCircle} size={size ? size : 'xs'} />
    case 'xmark':
      return <FontAwesomeIcon icon={faTimesCircle} size={size ? size : 'xs'} />
    case 'spinner':
      return (
        <FontAwesomeIcon
          icon={faCircleNotch}
          spin={spin}
          size={size ? size : 'xs'}
        />
      )
    case 'sync':
      return (
        <FontAwesomeIcon
          icon={faSyncAlt}
          spin={spin}
          size={size ? size : 'xs'}
        />
      )
    case 'caretDown':
      return <FontAwesomeIcon icon={faCaretDown} size={size ? size : 'xs'} />
    case 'caretUp':
      return <FontAwesomeIcon icon={faCaretUp} size={size ? size : 'xs'} />
    case 'angleUp':
      return <FontAwesomeIcon icon={faAngleUp} size={size ? size : 'xs'} />
    case 'angleDown':
      return <FontAwesomeIcon icon={faAngleDown} size={size ? size : 'xs'} />
    case 'angleLeft':
      return <FontAwesomeIcon icon={faAngleLeft} size={size ? size : 'xs'} />
    case 'angleRight':
      return <FontAwesomeIcon icon={faAngleRight} size={size ? size : 'xs'} />
    case 'angleDoubleLeft':
      return (
        <FontAwesomeIcon icon={faAngleDoubleLeft} size={size ? size : 'xs'} />
      )
    case 'angleDoubleRight':
      return (
        <FontAwesomeIcon icon={faAngleDoubleRight} size={size ? size : 'xs'} />
      )
    case 'members':
      return <FontAwesomeIcon icon={faUserFriends} size={size ? size : 'xs'} />
    case 'plus':
      return <FontAwesomeIcon icon={faPlus} size={size ? size : 'xs'} />
    case 'at':
      return <FontAwesomeIcon icon={faAt} size={size ? size : 'xs'} />
    case 'userRegular':
      return <FontAwesomeIcon icon={faUserRegular} size={size ? size : 'xs'} />
    case 'search':
      return <FontAwesomeIcon icon={faSearch} size={size ? size : 'xs'} />
    case 'filter':
      return <FontAwesomeIcon icon={faFilter} size={size ? size : 'xs'} />
    case 'pen':
      return <FontAwesomeIcon icon={faPen} size={size ? size : 'xs'} />
    case 'times':
      return <FontAwesomeIcon icon={faTimes} size={size ? size : 'xs'} />
    case 'cog':
      return <FontAwesomeIcon icon={faCog} size={size ? size : 'xs'} />
    case 'trash':
      return <FontAwesomeIcon icon={faTrash} size={size ? size : 'xs'} />
    case 'marker':
      return <FontAwesomeIcon icon={faMapMarkerAlt} size={size ? size : 'xs'} />
    case 'refresh':
      return <FontAwesomeIcon icon={faSync} size={size ? size : 'xs'} />
    case '3dots':
      return <FontAwesomeIcon icon={faEllipsisH} size={size ? size : 'xs'} />
    default:
      return null
  }
}
