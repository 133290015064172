import React, { useState } from 'react'
import { FormProvider } from 'providers/FormProvider'
import { Icon, Button } from 'components/atoms'
import { Modal } from 'components/molecules'
import { UploadLoopForm } from './UploadLoopForm'
import { FormattedMessage } from 'react-intl'

const applyMultipartPayload = data => {
    const formData = new FormData()
    formData.append('name', data.name)
    formData.append('scanned-loop', data.scannedLoop)
    return formData
}

export const UploadLoopModal = ({ refresh, systemId }) => {
    const [isOpen, onChange] = useState(false)
    return (
        <>
            <Button
                data-test-id="add-loop-button"
                buttonType="primary"
                onClick={() => onChange(true)}
            >
                <Icon type="plus" /> <FormattedMessage id={'modal.upload.button'} />
            </Button>
            <Modal
                title={<FormattedMessage id={'upload.loop.modal.title'} />}
                isOpen={isOpen}
                onRequestClose={() => {
                    onChange(false)
                }}
            >
                {isOpen && (
                    <FormProvider
                        onSuccess={() => {
                            refresh()
                            onChange(false)
                        }}
                        url={`/v1/scanned-systems/${systemId}/import-loop`}
                        mapFormDataToPayload={applyMultipartPayload}
                        multipart
                        render={({ updating, handleSubmit }) => (
                            <UploadLoopForm
                                onCancel={() => {
                                    onChange(false)
                                }}
                                onSubmit={values => {
                                    handleSubmit(values)
                                }}
                                loading={updating}
                            />
                        )}
                    />
                )}
            </Modal>
        </>
    )
}
