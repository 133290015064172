import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { store } from 'rdx/store'
import { Field, reduxForm, reset, change } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import { Button, Form, SizedBox } from 'components/atoms'
import { Col, Row } from 'react-grid-system'
import { Input } from 'components/molecules'
import { MapForm } from './MapForm'

const EditModemFormUnwrapped = ({
  invalid,
  loading,
  pristine,
  handleSubmit,
  initialValues,
}) => {
  const [coordinates] = useState({
    lat: initialValues.lat,
    lng: initialValues.lng,
  })

  const handleCoordinatesChange = ({ lat, lng }) => {
    store.dispatch(change('edit-modem-form', 'lat', lat))
    store.dispatch(change('edit-modem-form', 'lng', lng))
  }

  return (
    <Form.Dark onSubmit={handleSubmit}>
      <Row>
        <Col md={6}>
          <Form.Item width="400px">
            <Form.Label>
              <FormattedMessage id="edit.modem.form.clientId" />
            </Form.Label>
            <Field
              component={Input}
              name="clientId"
              testId="edit-modem-form-clientId-input"
            />
          </Form.Item>
          <Form.Item width="400px">
            <Form.Label>
              <FormattedMessage id="edit.modem.form.iccid" />
            </Form.Label>
            <Field
              component={Input}
              name="iccid"
              testId="edit-modem-form-iccid-input"
            />
          </Form.Item>
          <Form.Item width="400px">
            <Form.Label>
              <FormattedMessage id="edit.modem.form.imei" />
            </Form.Label>
            <Field
              component={Input}
              name="imei"
              testId="edit-modem-form-imei-input"
            />
          </Form.Item>
          <Form.Item width="400px">
            <Form.Label>
              <FormattedMessage id="edit.modem.form.imsi" />
            </Form.Label>
            <Field
              component={Input}
              name="imsi"
              testId="edit-modem-form-imsi-input"
            />
          </Form.Item>
          <Form.Item width="400px">
            <Form.Label>
              <FormattedMessage id="edit.modem.form.wgip" />
            </Form.Label>
            <Field
              component={Input}
              name="wgip"
              testId="edit-modem-form-wgip-input"
            />
          </Form.Item>
          <Form.Item width="400px">
            <Form.Label>
              <FormattedMessage id="edit.modem.form.ts" />
            </Form.Label>
            <Field
              component={Input}
              name="ts"
              testId="edit-modem-form-ts-input"
            />
          </Form.Item>
          <Form.Buttons>
            <Button
              loading={loading}
              disabled={pristine}
              buttonType="secondary"
              data-test-id="edit-modem-form-cancel"
              onClick={() => {
                store.dispatch(reset('edit-modem-form'))
              }}
            >
              <FormattedMessage id="button.cancel" />
            </Button>
            <Button
              loading={loading}
              type="submit"
              disabled={pristine || invalid || loading}
              data-test-id="edit-modem-form-save"
            >
              <FormattedMessage id="button.save" />
            </Button>
          </Form.Buttons>
        </Col>
        <Col md={6}>
          <SizedBox height="1" />
          <MapForm
            lat={coordinates.lat}
            lng={coordinates.lng}
            onCoordinatesChange={handleCoordinatesChange}
          />
        </Col>
      </Row>
    </Form.Dark>
  )
}

EditModemFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  initialValues: PropTypes.any,
}

export const EditModemForm = reduxForm({
  form: 'edit-modem-form',
})(EditModemFormUnwrapped)
