import styled from 'styled-components'
import { ColorWhite } from 'style/design-tokens.js'

export const CircleStatusIndicator = styled.div`
  display: inline-block;
  height: 16px;
  width: 16px;
  background-color: ${({ color }) => (color ? color : 'inherit')};
  border-radius: 50%;
  border: 2px solid ${ColorWhite};
`
