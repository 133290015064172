import React, { useState } from 'react'
import { SizedBox, Row, Link, Address, Error } from 'components/atoms'
import { MainLayout } from 'layout/MainLayout'
import { Table } from 'components/organisms'
import { withRouter } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { formatDate, formatTime } from 'utils/formatters'
import { UnitsAgeReportsStatistics } from './UntAgeReportsStatistics'
import { GenerateUnitAgeReportForm } from './GenerateUnitAgeReportForm/GenerateUnitAgeReportForm'
import { omitUndefinedKeys } from 'utils/helpers'
import { formatDateTime } from 'utils/formatters'
import { DownloadUnitAgeReport } from './DownloadUnitAgeReport'
import { withUnitAgeReports } from 'api'

const columns = [
  {
    title: <FormattedMessage id="reports.headers.customer.number" />,
    key: 'customerNumber',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.unit.name" />,
    key: 'unitName',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.hardware" />,
    key: 'hardware',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.unit.tags" />,
    key: 'carrierTags',
  },
  {
    title: <FormattedMessage id="reports.headers.serial.number" />,
    key: 'serialNumber',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.production.date" />,
    key: 'productionDate',
    render: record =>
      record.productionDate
        ? formatDate(record.productionDate) +
          '\n' +
          formatTime(record.productionDate)
        : '-',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.unit.age" />,
    key: 'unitAge',
    sortable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.system.name" />,
    key: 'systemName',
    render: record => (
      <Link to={`/systems/${record.systemId}`}>
        {record.systemName ? record.systemName : '-'}
      </Link>
    ),
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.system.type" />,
    key: 'systemType',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.business.site.number" />,
    key: 'siteNumber',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.site.name" />,
    key: 'siteName',
    render: record => (
      <Link to={`/sites/${record.siteId}`}>
        {record.siteName ? record.siteName : '-'}
      </Link>
    ),
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.site.address" />,
    key: 'address',
    render: record => <Address address={record.address} />,
    sortable: true,
    searchable: true,
  },
]

export const UnitsAgeReportsPage = withRouter(({ history }) => {
  // eslint-disable-next-line no-unused-vars
  const [producedBefore, setProducedBefore] = useState(null)
  const {
    data,
    isLoading,
    refresh,
    error,
    filters,
    onChange,
    sorter,
    pagination,
    meta,
    ...rest
  } = withUnitAgeReports()
  const intl = useIntl()

  const serialize = data =>
    omitUndefinedKeys({
      producedBefore: formatDateTime(data.producedBefore),
    })

  const handleSubmit = values => {
    setProducedBefore({
      producedBefore: formatDateTime(values.producedBefore),
    })
    onChange(
      undefined,
      {
        ...filters,
        ...serialize(values),
      },
      sorter,
    )
  }

  const refreshTable = () => {
    onChange(
      undefined,
      {
        ...filters,
        producedBefore: undefined,
      },
      sorter,
    )
  }

  return (
    <MainLayout
      header={intl.formatMessage({
        id: 'reports.header.unit.age',
      })}
    >
      <SizedBox height="1" />
      <Row width="40rem">
        <GenerateUnitAgeReportForm
          onSubmit={values => {
            handleSubmit(values)
          }}
          refresh={refreshTable}
        />
        <SizedBox width="0.5" />
        <div>
          <SizedBox height="0.5" />
          <DownloadUnitAgeReport formValues={{ ...filters, ...sorter }} />
        </div>
      </Row>
      <SizedBox height="1" />
      <UnitsAgeReportsStatistics meta={meta} />
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns}
        data={data}
        history={history}
        sortingParams={sorter}
        filteringParams={filters}
        pagination={pagination}
        onChange={onChange}
        refresh={refresh}
        hasIterator
        {...rest}
      />
    </MainLayout>
  )
})
