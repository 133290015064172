import React, { useState } from 'react'
import { FormProvider } from 'providers/FormProvider'
import { Icon, Button } from 'components/atoms'
import { Modal } from 'components/molecules'
import { AddModemForm } from './AddModemForm'
import { Col, Row } from 'react-grid-system'
import { MapForm } from './MapForm'

export const AddModemModal = ({ refresh, siteId }) => {
  const [isOpen, onChange] = useState(false)
  const location = {
    lat: 63.4225876,
    lng: 10.4680739,
  }

  const onMapUpdate = ({ lat, lng }) => {
    location.lat = lat
    location.lng = lng
  }

  return (
    <>
      <Button
        data-test-id="add-modem-button"
        buttonType="primary"
        onClick={() => onChange(true)}
      >
        <Icon type="plus" /> Add
      </Button>
      <Modal
        title="Add new modem to site"
        width="1200px"
        isOpen={isOpen}
        onRequestClose={() => {
          onChange(false)
        }}
      >
        {isOpen && (
          <FormProvider
            onSuccess={() => {
              refresh()
              onChange(false)
            }}
            url={'/v1/modems'}
            render={({ updating, handleSubmit }) => (
              <Row>
                <Col>
                  <AddModemForm
                    onSubmit={values => {
                      const code = `<IMEI>${values.imei}</IMEI><ICCID>${values.iccid}</ICCID><IMSI>${values.imsi}</IMSI><WGIP>${values.wgip}</WGIP><CLIENTID>${values.clientId}</CLIENTID><TS>${values.ts}</TS>`
                      handleSubmit({
                        code,
                        siteId,
                        lat: location.lat,
                        lng: location.lng,
                      })
                    }}
                    onCancel={() => {
                      onChange(false)
                    }}
                    loading={updating}
                    siteId={siteId}
                  />
                </Col>
                <Col>
                  <MapForm
                    lat={location.lat}
                    lng={location.lng}
                    onCoordinatesChange={onMapUpdate}
                  />
                </Col>
              </Row>
            )}
          />
        )}
      </Modal>
    </>
  )
}
