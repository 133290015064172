import React from 'react'
import { Redirect } from 'react-router-dom'
import { formatDateTime } from 'utils/formatters'
import { AccessLevels } from 'utils/AccesLevels'
import { withScannedSystem, deleteScannedSystem } from 'api'
import { MainLayout } from 'layout/MainLayout'
import {
  Field,
  SizedBox,
  IconDropdown,
  ConfirmationModal,
  Protected,
  Link,
  Row,
  Divider,
} from 'components/atoms'
import { Tabs } from 'components/molecules/Tabs/Tabs'
import { withRouter } from 'react-router-dom'
import { ScannedSystemLoopList } from './ScannedSystemLoopList/ScannedSystemLoopList'
import { EditScannedSystem } from './EditScannedSystem/EditScannedSystem'
import { FormattedMessage } from 'react-intl'

const ScannedSystemsTabs = {
  Loops: '0',
  Edit: '1',
}

export const ScannedSystemPage = withRouter(({ match, history }) => {
  const {
    isInitialLoading,
    pick,
    data,
    refresh,
    errorStatus,
  } = withScannedSystem(match.params.id)
  const tab = (window.location.hash || ScannedSystemsTabs.Loops).replace(
    '#',
    '',
  )
  const setIndex = value => (window.location.hash = value)

  if (errorStatus) {
    return <Redirect to={`/${errorStatus}`} />
  }
  return (
    <MainLayout
      isLoading={isInitialLoading}
      header={<>{pick(['name']) || pick(['number'])}</>}
      extra={
        <Protected
          resourceId={match.params.id}
          accessLevel={AccessLevels.Admin}
        >
          <IconDropdown
            type={'cog'}
            options={[
              {
                label: 'button.delete',
                action: () => {
                  ConfirmationModal.show({
                    title: 'Are you sure?',
                    text: 'Are you sure you want to delete this system?',
                    onSubmit: async () => {
                      const { error } = await deleteScannedSystem(pick(['id']))
                      if (!error) {
                        history.push(`/sites/${data.siteId}#3`)
                      } else {
                        throw error
                      }
                    },
                  })
                },
              },
            ]}
          />
        </Protected>
      }
    >
      <SizedBox height="2" />
      <Divider />
      <SizedBox height="1" />
      <Row>
        <Field title="Site">
          <Link to={`/sites/${pick(['siteId'])}`}>
            {pick(['siteName']) || 'Site'}
          </Link>
        </Field>
        <Field title="Created at">{formatDateTime(pick(['createdAt']))}</Field>
      </Row>
      <Divider />
      <SizedBox height="3" />

      <Tabs>
        <Tabs.Tab
          onClick={() => setIndex(ScannedSystemsTabs.Loops)}
          selected={tab === ScannedSystemsTabs.Loops}
          testId="loops-tab"
        >
          <FormattedMessage id="scanned.system.tab.loops" />
        </Tabs.Tab>
        <Tabs.Tab
          onClick={() => setIndex(ScannedSystemsTabs.Edit)}
          selected={tab === ScannedSystemsTabs.Edit}
          testId="edit-tab"
        >
          <FormattedMessage id="scanned.system.tab.edit" />
        </Tabs.Tab>
      </Tabs>
      {tab === ScannedSystemsTabs.Loops && (
        <ScannedSystemLoopList systemId={match.params.id} refresh={refresh} />
      )}
      {tab === ScannedSystemsTabs.Edit && (
        <EditScannedSystem
          refresh={refresh}
          systemId={pick(['id'])}
          pick={pick}
        />
      )}
    </MainLayout>
  )
})
