import React from 'react'
import { Icon } from 'components/atoms'
import { SnackBar } from 'components/molecules/SnackBar/SnackBar'

const Message = (text, icon) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: 'white',
      color: 'black',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: 10,
      }}
    >
      <Icon type={icon || 'exclamationTriangle'} size="lg" />
    </div>
    <div style={{ marginLeft: 10, marginRight: 15 }}>{text}</div>
  </div>
)

export const showError = (message, code) => {
  console.error('error', message, code)
  SnackBar.openSnackbar(Message(`${message} ${code || ''}`))
}

export const showForbiddenError = () => {
  console.error('error', 'Forbidden erro')
  SnackBar.openSnackbar(Message('Unauthorized access'))
}

export const showSuccess = message => {
  SnackBar.openSnackbar(Message(message, 'check'))
}
