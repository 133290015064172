import React from 'react'
import { withRouter } from 'react-router-dom'
import { AccessLevels } from 'utils/AccesLevels'
import { Space, Spacing, Error, Protected } from 'components/atoms'
import { Table } from 'components/organisms'
import { withSiteModems } from 'api'
import { AddModemModal } from './AddModemModal/AddModemModal'
import { formatDateTime } from 'utils/formatters'

const columns = [
  {
    title: 'Number',
    key: 'number',
  },
  {
    title: 'Client ID',
    key: 'clientId',
  },
  {
    title: 'IMEI',
    key: 'imei',
  },
  {
    title: 'WGIP',
    key: 'wgip',
  },
  {
    title: 'Created at',
    render: record => formatDateTime(record.createdAt),
  },
]

export const SiteModems = withRouter(({ siteId, history }) => {
  const { data, isLoading, error, refresh, ...rest } = withSiteModems(siteId)()
  return (
    <>
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns}
        data={data}
        history={history}
        linkPath={'/modems'}
        {...rest}
      />
      <Protected resourceId={siteId} accessLevel={AccessLevels.Admin}>
        <Spacing mt="1" width="100%">
          <Space right>
            <AddModemModal siteId={siteId} refresh={refresh} />
          </Space>
        </Spacing>
      </Protected>
    </>
  )
})
