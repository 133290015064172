import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import styled from 'styled-components'
import {
  ColorMisty,
  ColorWhite,
  SizeSpacing03Rem,
  SizeSpacing07Rem,
} from 'style/design-tokens'
import { render, unmountComponentAtNode } from 'react-dom'
import { Typography } from '../Typography/Typography'
import { Button } from '../Button/Button'
import { SizedBox } from '../SizedBox/SizedBox'
import { showError } from 'layout/notifications'

export const StyledModal = styled(Modal)`
  background: ${ColorWhite};
  border: 1px solid ${ColorMisty};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  left: 50%;
  outline: none;
  padding: ${SizeSpacing03Rem}rem ${SizeSpacing07Rem}rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 600px;
  & label {
    hight: 40px;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ConfirmationModal = ({
  title = 'Are you sure?',
  onSubmit,
  children,
  text,
  isDefaultOpen,
  onCloseCallback,
  hasOpenButton,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen || false)
  useEffect(() => {
    StyledModal.setAppElement('body')
  }, [])
  return (
    <>
      {hasOpenButton && (
        <Button
          onClick={() => setIsOpen(true)}
          data-test-id={`${rest['data-rest-id']}__confirmation-modal__open`}
        >
          {children}
        </Button>
      )}
      <StyledModal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false)
          onCloseCallback()
        }}
        style={{ overlay: { backgroundColor: '#000000c2', zIndex: 999 } }}
      >
        <Typography.H3 type="dark">{title}</Typography.H3>
        {text && (
          <>
            <Typography.P type="dark">{text}</Typography.P>
            <SizedBox height="0.6" />
          </>
        )}
        <ButtonsContainer>
          <Button
            buttonType="secondary"
            onClick={() => setIsOpen(false)}
            data-test-id={`${rest['data-rest-id']}__confirmation-modal__cancel`}
          >
            Cancel
          </Button>
          <SizedBox width="0.5" />
          <Button
            data-test-id={`${rest['data-rest-id']}__confirmation-modal__confirm`}
            onClick={async () => {
              try {
                await onSubmit()
                setIsOpen(false)
              } catch (err) {
                showError(err)
                throw err
              }
            }}
          >
            Confirm
          </Button>
        </ButtonsContainer>
      </StyledModal>
    </>
  )
}

ConfirmationModal.show = props => {
  const div = document.createElement('div')
  document.body.appendChild(div)
  StyledModal.setAppElement('body')
  render(
    <ConfirmationModal
      onRequestClose
      isDefaultOpen
      {...props}
      onCloseCallback={() => {
        unmountComponentAtNode(div)
        div.remove()
      }}
    />,
    div,
  )
}

ConfirmationModal.defaultProps = {
  title: 'Are you sure?',
  onCloseCallback: () => {},
}

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}
