import React from 'react'
import PropTypes from 'prop-types'
import { reset } from 'redux-form'
import { store } from 'rdx/store'
import { FormattedMessage } from 'react-intl'
import { Button, Form } from 'components/atoms'
import { Input } from 'components/molecules'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { required, format } from 'redux-form-validators'
import { connect } from 'react-redux'
import { compose } from 'recompose'

const EditSiteFormUnwrapped = ({
  handleSubmit,
  invalid,
  loading,
  pristine,
  siteNumber,
}) => {
  return (
    <Form.Dark onSubmit={handleSubmit}>
      <Form.Item width="400px">
        <Form.Label>
          <FormattedMessage id="form.name" />
        </Form.Label>
        <Field
          component={Input}
          name="name"
          validate={[required()]}
          testId="edit-site-form-name-input"
        />
      </Form.Item>

      <Form.Item width="400px">
        <Form.Label>
          <FormattedMessage id="form.comment" />
        </Form.Label>
        <Field
          component={Input}
          name="comment"
          testId="edit-site-form-comment-input"
        />
      </Form.Item>

      <Form.Item width="400px">
        <Form.Label>
          <FormattedMessage id="form.number" />
        </Form.Label>
        <Field
          component={Input}
          name="siteNumber"
          validate={[
            format({
              with: siteNumber ? siteNumber.length > 100 : false,
              message:
                'Site number is too long (should be at max 100 characters)',
            }),
          ]}
          testId="edit-site-form-name-input"
        />
      </Form.Item>
      <Form.Buttons>
        <Button
          loading={loading}
          disabled={pristine}
          buttonType="secondary"
          data-test-id="edit-site-form-cancel"
          onClick={() => {
            store.dispatch(reset('edit-site-form'))
          }}
        >
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button
          loading={loading}
          type="submit"
          disabled={pristine || invalid || loading}
          data-test-id="edit-site-form-save"
        >
          <FormattedMessage id="button.save" />
        </Button>
      </Form.Buttons>
    </Form.Dark>
  )
}

EditSiteFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

const fieldSelector = formValueSelector('edit-site-form')

export const EditSiteForm = compose(
  reduxForm({
    form: 'edit-site-form',
  }),
  connect(state => ({
    siteNumber: fieldSelector(state, 'siteNumber'),
  })),
)(EditSiteFormUnwrapped)
