import React, { useState } from 'react'
import {
  Spacing,
  Divider,
  Button,
  SizedBox,
  Field,
  Typography,
  UserStatus,
} from 'components/atoms'
import { FormattedMessage } from 'react-intl'
import { ModemConfigurationForm } from './ModemConfigurationForm'
import { useIntl } from 'react-intl'
import { Protected } from 'components/atoms/Protected/Protected'
import { AccessLevels } from 'utils/AccesLevels'
import { ModemSystemConnectionStages } from 'components/organisms'
import { ColorKoalaGrey } from 'style/design-tokens'
import styled from 'styled-components'

const FieldTitle = styled.div`
  display: flex;
  color: ${ColorKoalaGrey};
`

const systemTypeNames = {
  autrosafe: 'AutroSafe',
  autroprime: 'Autroprime',
  simulator: 'Simulator',
}

export const ModemConfiguration = ({ modemId, pick, refresh }) => {
  let intl = useIntl()
  const [isVisible, setVisible] = useState(false)
  return (
    <>
      <Divider />
      <Spacing width="100%" mt="3" ml="2">
        {isVisible ? (
          <ModemConfigurationForm
            onClose={async () => {
              await refresh()
              setVisible(false)
            }}
            modemId={modemId}
            configuration={{
              ip: pick(['ip']),
              subnet: pick(['subnet']),
              systems: pick(['modemSystems']),
            }}
          />
        ) : (
          <>
            {!pick(['ip']) ||
            !pick(['subnet']) ||
            pick(['modemSystems'], []).length === 0 ? (
              <>
                <FormattedMessage id="modem.no.configuration" />
                <SizedBox height="2" />
                <Button
                  onClick={() => setVisible(true)}
                  data-test-id="configure-modem-button"
                >
                  <FormattedMessage id="button.configure.modem" />
                </Button>
              </>
            ) : (
              <>
                <FieldTitle>
                  <FormattedMessage id="modem.configuration.ip" />
                </FieldTitle>
                <Field>{pick(['ip'])}</Field>
                <FieldTitle>
                  <FormattedMessage id="modem.configuration.subnet" />
                </FieldTitle>
                <Field>{pick(['subnet'])}</Field>
                <SizedBox height="1" />
                <FieldTitle>
                  <FormattedMessage id="modem.configuration.systems" />
                </FieldTitle>
                <Field>
                  {pick(['modemSystems'], []).map((system, idx) => (
                    <React.Fragment key={idx}>
                      <SizedBox height="1" />
                      <Typography.P mb="0.5">
                        {`${idx + 1}. ${system.name || '-'}  `}

                        {pick(['endpoints']).filter(endpoint => {
                          if (endpoint.systemName === system.name) {
                            return endpoint.systemArchivedAt
                          }
                        }).length > 0 ? (
                          <UserStatus
                            status={intl.formatMessage({
                              id:
                                'modem.configuration.endpoint.system.archived',
                            })}
                          />
                        ) : (
                          ''
                        )}
                      </Typography.P>
                      <Typography.P mb="0.5" data-test-id="endpoint-id">
                        <FormattedMessage id="modem.configuration.endpoint.id" />
                        : {system.modemEndpointId || '-'}
                      </Typography.P>
                      <Typography.P mb="0.5">
                        <FormattedMessage id="modem.configuration.system.type" />
                        : {systemTypeNames[system.type] || '-'}
                      </Typography.P>
                      <Typography.P mb="0.5">
                        <FormattedMessage id="modem.configuration.system.host" />
                        : {system.host || '-'}
                      </Typography.P>
                      {system.type === 'autrosafe' && (
                        <Typography.P mb="0.5">
                          <FormattedMessage id="modem.configuration.system.port" />
                          : {system.port || '-'}
                        </Typography.P>
                      )}
                      {system.type === 'autroprime' && (
                        <>
                          <Typography.P mb="0.5">
                            <FormattedMessage id="modem.configuration.system.username" />
                            : {system.username || '-'}
                          </Typography.P>
                          <Typography.P mb="0.5">
                            <FormattedMessage id="modem.configuration.system.password" />
                            : {system.password || '-'}
                          </Typography.P>
                        </>
                      )}
                      <ModemSystemConnectionStages
                        description={system.connectionState.description}
                        stages={system.connectionState.stages}
                      />
                      <SizedBox height="1" />
                    </React.Fragment>
                  ))}
                </Field>
                <Protected
                  accessLevel={AccessLevels.Admin}
                  resourceId={pick['siteId']}
                >
                  <Button
                    onClick={() => setVisible(true)}
                    data-test-id="update-configuration-button"
                  >
                    <FormattedMessage id="button.update.config" />
                  </Button>
                </Protected>
              </>
            )}
          </>
        )}
      </Spacing>
    </>
  )
}
