import debounce from 'lodash/debounce'
import { useState, useEffect } from 'react'
import { displayError } from './displayError'
import { request, encodeObjectValues } from './request'

const encodeFilters = filters =>
  Object.fromEntries(
    Object.entries(filters)
      .filter(entry => Array.isArray(entry[1]))
      .map(entry => [entry[0], entry[1].join(',')]),
  )

const getStoredPerPage = () => Number(localStorage.getItem('per_page') || 10)

const setStoredPerPage = value => localStorage.setItem('per_page', value)

export const withListResource = (url, normalizer = val => val) => {
  let lastRequestNumber = 0
  return (defaultParams = {}, defaultLimit = getStoredPerPage()) => {
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)
    const [meta, setMeta] = useState({ count: 0 })
    const [data, setData] = useState(null)
    const [limit, setLimit] = useState(defaultLimit)
    const [offset, setOffset] = useState(0)
    const [params, setParamsValue] = useState(defaultParams)
    const [filters, setFilters] = useState({})
    const [sorter, setSorter] = useState({})
    const [refreshCount, setRefreshCount] = useState(0)
    const setParams = value => {
      setParamsValue(value)
      setOffset(0)
    }

    useEffect(() => {
      const fetchData = debounce(async requestNumber => {
        if (lastRequestNumber !== requestNumber) {
          return
        }
        try {
          setIsLoading(true)
          const response = await request.get(url, {
            params: {
              limit,
              offset,
              ...sorter,
              ...encodeFilters(filters),
              ...encodeObjectValues(params),
            },
          })
          setData(normalizer(response.data.data))
          setMeta({
            count: response.data.count,
            ...response.data.meta,
          })
        } catch (err) {
          if (err.response) {
            setError(displayError(err))
          } else {
            console.error(err)
          }
        }
        setIsLoading(false)
      }, 500)
      lastRequestNumber++
      fetchData(lastRequestNumber)
    }, [limit, offset, params, filters, sorter, refreshCount])

    return {
      isLoading,
      error,
      data,
      meta,
      limit,
      offset,
      params,
      setLimit,
      setParams,
      sorter,
      filters,
      pagination: {
        current: offset / limit + 1,
        pageSize: limit,
        total: meta.count,
      },
      refresh: () => {
        setRefreshCount(refreshCount + 1)
      },
      onChange: (pagination, _filters, _sorter) => {
        if (!_filters) {
          setFilters({})
        } else if (_filters) {
          setFilters(_filters)
        }
        if (!_sorter) {
          setSorter({})
        } else {
          setSorter({
            orderBy: _sorter.orderBy,
            order: _sorter.order === 'ASC' ? 'ASC' : 'DESC',
          })
        }
        if (pagination) {
          if (pagination.pageSize && pagination.pageSize !== limit) {
            setLimit(pagination.pageSize)
            setStoredPerPage(pagination.pageSize)
            setOffset(0)
          } else {
            setOffset((pagination.current - 1) * limit)
          }
        } else {
          setOffset(0)
        }
      },
    }
  }
}
