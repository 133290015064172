import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Image = styled.img`
  height: 2.6rem;
  margin: 0 1rem;
`

export const Logo = ({ light }) => (
  <Image src={light ? '/assets/logo-white.png' : '/assets/logo-dark.png'} />
)

Logo.propTypes = {
  light: PropTypes.bool,
}
