import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field, reduxForm } from 'redux-form'
import { required } from 'redux-form-validators'
import { Form, Button, SizedBox } from 'components/atoms'
import { FileInput } from 'components/molecules'

const UploadInstallBaseReportFormUnwrapped = ({
  handleSubmit,
  loading,
  pristine,
  invalid,
  onCancel,
}) => (
  <Form
    onSubmit={handleSubmit}
    description={
      <FormattedMessage id="upload.install.base.report.form.description" />
    }
  >
    <SizedBox height="1" />
    <Form.Item width="40%">
      <Form.Label>
        <FormattedMessage id="form.file" />
      </Form.Label>
      <Field
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        name="file"
        component={FileInput}
        validate={[required()]}
        data-test-id="upload-install-base-report-file-input"
      />
    </Form.Item>
    <Form.Buttons>
      <Button
        disabled={loading}
        buttonType="secondary"
        onClick={onCancel}
        data-test-id="upload-install-base-report-form-cancel"
      >
        <FormattedMessage id="button.cancel" />
      </Button>
      <Button
        disabled={loading || pristine || invalid}
        isLoading={loading}
        type="submit"
        buttonType="primary"
        isActive
        data-test-id="upload-install-base-report-form-submit"
      >
        <FormattedMessage id="button.upload" />
      </Button>
    </Form.Buttons>
  </Form>
)

UploadInstallBaseReportFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export const UploadInstallBaseReportForm = reduxForm({
  form: 'upload-install-base-report-form',
})(UploadInstallBaseReportFormUnwrapped)
