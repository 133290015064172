export const omitUndefinedKeys = obj => {
  return Object.entries(obj || {}).reduce((acc, curr) => {
    if (curr[1] !== undefined && curr[1] !== null) {
      acc[curr[0]] = [curr[1]]
    }
    return acc
  }, {})
}

export const isTwoStringsEqualCaseInsenstive = (word, otherWord) => {
  return word && otherWord && word.toLowerCase() === otherWord.toLowerCase()
}

/**
 * Verifies if two addresses are equal (if they exist)
 *
 * @param {Address | null} address - First address to compare
 * @param {Address | null} otherAddress - Second address to compare
 * @returns {boolean} - True if the addresses are equal, false otherwise
 */
export const isTwoAdressessEqual = (address, otherAddress) => {
  return (
    address &&
    otherAddress &&
    isTwoStringsEqualCaseInsenstive(address.city, otherAddress.city) &&
    isTwoStringsEqualCaseInsenstive(
      address.postalCode,
      otherAddress.postalCode,
    ) &&
    isTwoStringsEqualCaseInsenstive(address.country, otherAddress.country) &&
    isTwoStringsEqualCaseInsenstive(
      address.addressLine1,
      otherAddress.addressLine1,
    ) &&
    isTwoStringsEqualCaseInsenstive(
      address.addressLine2,
      otherAddress.addressLine2,
    )
  )
}
