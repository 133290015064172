import React from 'react'
import { withLiveSystems } from 'api'
import {
  Error,
  Address,
  Space,
  Link,
  CircleStatusIndicator,
} from 'components/atoms'
import { Table } from 'components/organisms'
import {
  ColorAckGreen,
  ColorFireRed,
  ColorAlmostBlack,
  ColorCarrotOrange,
  ColorSunnyYellow,
} from 'style/design-tokens.js'
import { ModemSystemState } from 'utils/ModemSystemState'

const displayCreator = user =>
  `${user.firstName} ${user.lastName}`.trim() || user.emailAddress

const columns = [
  {
    title: '#',
    key: '_number',
    sortable: true,
    render: record => record._number,
  },
  {
    title: 'System',
    key: 'system',
    sortable: true,
    searchable: true,
    render: system => (
      <>
        <Link to={`/systems/${system.id}`}>{system.name || system.number}</Link>
        <Space>
          <small>{system.type}</small>
          <small>{system.tag}</small>
        </Space>
      </>
    ),
  },
  {
    title: 'Site',
    key: 'site',
    sortable: true,
    searchable: true,
    render: system => (
      <>
        <Link to={`/sites/${system.site.id}`}>{system.site.name} </Link>
        <Link to={`/users/${system.site.creator.id}`}>
          <small>({displayCreator(system.site.creator)})</small>
        </Link>
        <div>
          <small>
            <Address address={system.site} />
          </small>
        </div>
      </>
    ),
  },
  {
    title: 'Modem',
    key: 'modem',
    sortable: true,
    searchable: true,
    render: system => (
      <>
        <Link to={`/modems/${system.modem.id}`}>{system.modem.number} </Link>
        <Link to={`/users/${system.modem.creator.id}`}>
          <small>({displayCreator(system.modem.creator)})</small>
        </Link>
        <Space right>
          <small>{system.modem.wgip}</small>
          <small>{system.modem.clientId}</small>
          <small>
            <a
              style={{ color: 'white', fontWeight: 400, align: 'right' }}
              href={`https://www.google.com/maps/search/?api=1&query=${system.modem.lat},${system.modem.lng}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              show on map
            </a>
          </small>
        </Space>
      </>
    ),
  },
  {
    title: 'Connection',
    key: 'status',
    sortable: true,
    render: record =>
      record.connectionState ? (
        record.connectionState === ModemSystemState.SystemConnected ? (
          <CircleStatusIndicator color={ColorAckGreen} />
        ) : record.connectionState ===
          ModemSystemState.MobileConnectionFailed ? (
          <CircleStatusIndicator color={ColorFireRed} />
        ) : record.connectionState === ModemSystemState.PanelConfigOk ? (
          <CircleStatusIndicator color={ColorSunnyYellow} />
        ) : (
          <CircleStatusIndicator color={ColorCarrotOrange} />
        )
      ) : (
        <CircleStatusIndicator color={ColorAlmostBlack} />
      ),
  },
]

export const LiveSystems = () => {
  const {
    data,
    isLoading,
    error,
    onChange,
    pagination,
    refresh,
    sorter,
    filters,
    ...rest
  } = withLiveSystems()
  return (
    <>
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns}
        data={data}
        sortingParams={sorter}
        filteringParams={filters}
        pagination={pagination}
        onChange={onChange}
        {...rest}
      />
    </>
  )
}
