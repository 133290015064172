import { SIGNED_IN, TOKEN_CHECK, SIGN_OUT, UPDATE_INFO } from './constants'

export const signedIn = user => ({
  type: SIGNED_IN,
  user,
})

export const updateInfo = user => ({
  type: UPDATE_INFO,
  user,
})

export const tokenCheck = () => ({
  type: TOKEN_CHECK,
})

export const signOut = () => ({
  type: SIGN_OUT,
})
