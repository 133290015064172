import { path } from 'ramda'
import { displayError } from './displayError'
import { request } from './request'

export const makeRequest = async (url, body, method = 'post', options = {}) => {
  try {
    const response = await request({
      method: method,
      url,
      data: body,
      ...options,
    })
    let data = path(['data', 'data'], response)
    if (data === undefined) {
      data = response.data
    }
    return {
      data: data,
      attachmentName: response.headers['attachment-name'],
      status: path(['status'], response),
    }
  } catch (err) {
    return {
      error: err.response ? displayError(err) : err,
    }
  }
}
