import jwtDecode from 'jwt-decode'
import {
  SIGNED_IN,
  TOKEN_CHECK,
  SIGN_OUT,
  UPDATE_INFO,
} from '../actions/constants'
import { pathOr } from 'ramda'

const decodeToken = token => {
  try {
    return jwtDecode(token)
  } catch (err) {
    return null
  }
}

const getStoredUser = () => {
  try {
    return JSON.parse(localStorage.getItem('user'))
  } catch {
    return {}
  }
}

const isValidToken = (token, user) => {
  const decodedToken = decodeToken(token)
  if (!decodedToken) {
    return false
  }
  if (decodedToken.exp <= Math.round(Date.now() / 1000)) {
    return false
  }
  if (decodedToken.id !== user.id) {
    return false
  }
  return true
}

const buildUser = (user = {}) => ({
  id: pathOr('', ['id'], user),
  firstName: pathOr('', ['firstName'], user),
  lastName: pathOr('', ['lastName'], user),
  emailAddress: pathOr('', ['emailAddress'], user),
  permissions: pathOr('', ['permissions'], user),
})

const getDefaultState = () => {
  const token = localStorage.getItem('auth_token')
  const user = getStoredUser()
  return {
    token,
    isAuthorized: isValidToken(token, user),
    user: buildUser(user),
  }
}

const saveToken = token => {
  localStorage.setItem('auth_token', token)
}

const saveUser = user => {
  localStorage.setItem('user', JSON.stringify(user))
}

const deleteToken = () => {
  localStorage.removeItem('auth_token')
  localStorage.removeItem('user')
  return {
    token: null,
    isAuthorized: false,
    user: buildUser(),
  }
}

export const authReducer = (state = getDefaultState(), action) => {
  switch (action.type) {
    case TOKEN_CHECK:
      return {
        ...state,
        isAuthorized: isValidToken(state.token, state.user),
      }
    case SIGNED_IN:
      saveToken(action.user.token)
      saveUser(action.user)
      return {
        ...state,
        isAuthorized: isValidToken(action.user.token, action.user),
        token: action.user.token,
        user: action.user,
      }
    case SIGN_OUT: {
      return deleteToken()
    }
    case UPDATE_INFO: {
      saveUser(action.user)
      return { ...state, user: action.user }
    }
    default:
      return state
  }
}
