import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table } from 'components/organisms'
import { AccessLevels } from 'utils/AccesLevels'
import { Space, Spacing, Error, Tag, Protected } from 'components/atoms'
import { SelectAccessLevel } from 'components/molecules'
import { withSiteUsers } from 'api'
import { InviteUserModal } from './InviteUserModal/InviteUserModal'

const columns = siteId => [
  {
    title: 'Number',
    key: 'number',
  },
  {
    title: 'Name',
    render: record => `${record.firstName || ''} ${record.lastName || ''}`,
  },
  {
    title: 'E-mail',
    key: 'emailAddress',
  },
  {
    title: 'Access level',
    key: 'accessLevel',
    render: record => (
      <Protected
        resourceId={siteId}
        accessLevel={AccessLevels.Admin}
        renderWithoutPermission={AccessLevels.toLabel(record.accessLevel)}
      >
        <SelectAccessLevel
          id={record.id}
          type="site"
          accessLevel={record.accessLevel}
        />
      </Protected>
    ),
  },
  {
    title: 'Status',
    key: 'status',
    render: record => (
      <Tag>
        {!record.isAccepted && !record.isRejected
          ? 'Pending'
          : record.isAccepted
          ? 'Accepted'
          : 'Rejected'}
      </Tag>
    ),
  },
]

export const SiteAccess = withRouter(({ siteId, history }) => {
  const { data, isLoading, error, refresh, ...rest } = withSiteUsers(siteId)()
  return (
    <>
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns(siteId)}
        data={data}
        history={history}
        {...rest}
      />
      <Protected resourceId={siteId} accessLevel={AccessLevels.Admin}>
        <Spacing mt="1" width="100%">
          <Space right>
            <InviteUserModal siteId={siteId} refresh={refresh} />
          </Space>
        </Spacing>
      </Protected>
    </>
  )
})
