import 'react-datepicker/dist/react-datepicker.css'
import React from 'react'
import PropTypes from 'prop-types'
import ReactDatePicker from 'react-datepicker'
import styled from 'styled-components'
import {
  SizeFontSmallRem,
  FontWeightRegular,
  ColorFireRed,
  ColorPistachio,
  ColorBlack,
} from 'style/design-tokens'

const Error = styled.label`
  font-size: ${SizeFontSmallRem}rem;
  font-weight: ${FontWeightRegular};
  text-align: left;
  color: ${ColorFireRed};
  padding-left: 2rem;
`

const StyledInput = styled.input`
  width: 150px;
  height: 25px;
  padding: 5px 3px;
  font-family: inherit;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid ${ColorPistachio};
  border-radius: 2px;
  z-index: 3;
  color: ${ColorBlack};
`

export const DatePicker = ({
  error,
  input,
  testId,
  minDate,
  maxDate,
  placeholderText,
  showMonthYearPicker,
}) => {
  if (typeof input.value === 'string') {
    input.value = null
  }
  return (
    <>
      <ReactDatePicker
        utcOffset={0}
        customInput={<StyledInput />}
        data-test-id={testId}
        selected={input.value}
        onChange={input.onChange}
        placeholderText={placeholderText}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={showMonthYearPicker ? 'MM/yyyy' : 'dd/MM/yyyy'}
        showMonthYearPicker={showMonthYearPicker}
      />
      {error && <Error>{error}</Error>}
    </>
  )
}

DatePicker.defaultProps = {
  minDate: new Date(),
}

DatePicker.propTypes = {
  minDate: PropTypes.instanceOf(Date),
  testId: PropTypes.string.isRequired,
  helpText: PropTypes.string,
}
