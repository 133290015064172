import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { request } from 'api/request'
import { Icon, Button } from 'components/atoms'
import { ConfirmationModal } from 'components/molecules'

export class RequestButton extends React.Component {
  state = {
    loading: false,
    isModalVisible: false,
  }

  handleToggleModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible })
  }

  handleClick = async () => {
    this.handleToggleModal()
    try {
      this.setState({ loading: true })
      await this.makeRequest()
      this.setState({ loading: false })
      this.props.onSuccess()
    } catch (err) {
      this.setState({ loading: false })
      this.props.onError()
    }
  }

  makeRequest = () => {
    const options = {
      method: this.props.method,
      url: this.props.url,
    }
    if (this.props.payload) {
      options.data = this.props.payload
    }
    return request(options)
  }

  render() {
    const { children, modalText, testId } = this.props
    return (
      <>
        <Button
          data-test-id={testId}
          onClick={this.handleToggleModal}
          buttonType={this.props.buttonType}
          style={this.props.style}
        >
          <Icon type={this.props.iconType} />
          {children}
        </Button>
        {this.props.hasModal && (
          <ConfirmationModal
            handleConfirm={this.handleClick}
            isOpen={this.state.isModalVisible}
            modalText={modalText}
            onRequestClose={this.handleToggleModal}
          />
        )}
      </>
    )
  }
}

RequestButton.defaultProps = {
  buttonType: 'primary',
  modalText: <FormattedMessage id="modal.confirmation.default" />,
  hasModal: true,
  onError: () => {},
  onSuccess: () => {},
}

RequestButton.propTypes = {
  buttonType: PropTypes.oneOf(['primary', 'secondary']),
  form: PropTypes.any,
  formUrl: PropTypes.string,
  iconType: PropTypes.string,
  method: PropTypes.string,
  modalDescription: PropTypes.any,
  modalText: PropTypes.object,
  hasModal: PropTypes.bool,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  payload: PropTypes.object,
  testId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  style: PropTypes.object,
}
