import React from 'react'
import { AccessLevels } from 'utils/AccesLevels'
import { Error } from 'components/atoms'
import { Table } from 'components/organisms'
import { formatDateTime } from 'utils/formatters'
import { Space, Spacing, Tag, Protected } from 'components/atoms'
import { SelectAccessLevel } from 'components/molecules'
import { InviteOperatorModal } from '../SystemAccess/InviteOperatorModal/InviteOperatorModal'
import { withSystemOperators } from 'api'

const columns = systemId => [
  {
    title: 'E-mail',
    key: 'emailAddress',
  },
  {
    title: 'Access level',
    key: 'accessLevel',
    render: record => (
      <Protected
        resourceId={systemId}
        accessLevel={AccessLevels.Admin}
        renderWithoutPermission={AccessLevels.toLabel(record.accessLevel)}
      >
        <SelectAccessLevel
          id={record.id}
          type="system"
          accessLevel={record.accessLevel}
        />
      </Protected>
    ),
  },
  {
    title: 'Invited at',
    key: 'createdAt',
    render: record => formatDateTime(record.createdAt),
  },
  {
    title: 'Status',
    key: 'status',
    render: record => (
      <Tag>
        {!record.isAccepted && !record.isRejected
          ? 'Pending'
          : record.isAccepted
          ? 'Accepted'
          : 'Rejected'}
      </Tag>
    ),
  },
]

export const SystemAccess = ({ systemId, isArchived }) => {
  const { data, isLoading, error, refresh, ...rest } = withSystemOperators(
    systemId,
  )()
  return (
    <>
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns(systemId)}
        data={data}
        {...rest}
      />
      {isArchived ? null : (
        <Protected resourceId={systemId} accessLevel={AccessLevels.Admin}>
          <Spacing mt="1" width="100%">
            <Space right>
              <InviteOperatorModal systemId={systemId} refresh={refresh} />
            </Space>
          </Spacing>
        </Protected>
      )}
    </>
  )
}
