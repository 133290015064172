import React, { useState } from 'react'
import { FormProvider } from 'providers/FormProvider'
import { Icon, Button } from 'components/atoms'
import { Modal } from 'components/molecules'
import { AddSiteForm } from './AddSiteForm'

export const AddSiteModal = ({ refresh }) => {
  const [isOpen, onChange] = useState(false)
  return (
    <>
      <Button
        data-test-id="add-site-button"
        buttonType="primary"
        onClick={() => onChange(true)}
      >
        <Icon type="plus" /> Add
      </Button>
      <Modal
        title="Add new site"
        isOpen={isOpen}
        onRequestClose={() => {
          onChange(false)
        }}
      >
        {isOpen && (
          <FormProvider
            onSuccess={() => {
              refresh()
              onChange(false)
            }}
            url={'/v1/sites'}
            render={({ updating, handleSubmit }) => (
              <AddSiteForm
                onSubmit={handleSubmit}
                onCancel={() => {
                  onChange(false)
                }}
                loading={updating}
              />
            )}
          />
        )}
      </Modal>
    </>
  )
}
