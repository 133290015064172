import React, { useState } from 'react'
import { FormProvider } from 'providers/FormProvider'
import { Button } from 'components/atoms'
import { Modal } from 'components/molecules'
import { FormattedMessage, useIntl } from 'react-intl'
import { UploadInstallBaseReportForm } from './UploadInstallBaseReportForm'

const applyMultipartPayload = data => {
  const formData = new FormData()
  formData.append('file', data.file)
  return formData
}

export const UploadInstalBaseReportModal = ({ refresh }) => {
  const [isOpen, onChange] = useState(false)
  let intl = useIntl()
  return (
    <>
      <Button
        data-test-id="add-install-base-report-button"
        buttonType="primary"
        onClick={() => onChange(true)}
      >
        <FormattedMessage id="reports.install.base.import.file" />
      </Button>
      <Modal
        title={intl.formatMessage({
          id: 'reports.install.base.import',
        })}
        isOpen={isOpen}
        onRequestClose={() => {
          onChange(false)
        }}
      >
        {isOpen && (
          <FormProvider
            onSuccess={() => {
              refresh()
              onChange(false)
            }}
            url={'/v1/resources/upload-install-base-report'}
            mapFormDataToPayload={applyMultipartPayload}
            multipart
            render={({ updating, handleSubmit }) => (
              <UploadInstallBaseReportForm
                onCancel={() => {
                  onChange(false)
                }}
                onSubmit={values => {
                  handleSubmit(values)
                }}
                loading={updating}
              />
            )}
          />
        )}
      </Modal>
    </>
  )
}
