import React, { useState } from 'react'
import { Link, Error, SizedBox, Space, Address, Row } from 'components/atoms'
import { MainLayout } from 'layout/MainLayout'
import { Table } from 'components/organisms'
import { formatDateTime } from 'utils/formatters'
import { omitUndefinedKeys } from 'utils/helpers'
import { withReports } from 'api'
import { withRouter } from 'react-router-dom'
import { GenerateUnitReportForm } from './GenerateUnitReportForm'
import { UnitTypes } from './UnitTypes'
import { FormattedMessage, useIntl } from 'react-intl'
import { DownloadUnitReport } from './DownloadUnitReport/DownloadUnitReport'
import { UnitReportStatistics } from './UnitReportStatistics'

const columns = [
  {
    title: <FormattedMessage id="reports.headers.system" />,
    key: 'system',
    render: record => (
      <>
        <Link to={`/systems/${record.system.id}`}>
          {record.system.name ? record.system.name : '-'}
        </Link>
        <Space>
          <small>{record.system.type}</small>
        </Space>
      </>
    ),
    externalLink: true,
  },
  {
    title: <FormattedMessage id="reports.headers.site" />,
    key: 'site',
    render: record => (
      <>
        <Link to={`/sites/${record.site.id}`}>{record.site.name}</Link>
        <Space>
          <small>
            <Address address={record.site} />
          </small>
        </Space>
      </>
    ),
    externalLink: true,
  },
  {
    title: <FormattedMessage id="reports.headers.configuration.time" />,
    key: 'configuredAt',
    render: record => formatDateTime(record.configuredAt),
    notClickable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.unit.type" />,
    key: 'unitType',
    notClickable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.units.count" />,
    key: 'unitsCount',
    notClickable: true,
  },
]

export const UnitReportsPage = withRouter(({ history }) => {
  const [formValues, setFormValues] = useState(null)
  const {
    data,
    isLoading,
    refresh,
    error,
    filters,
    onChange,
    sorter,
    pagination,
    meta,
    ...rest
  } = withReports()
  const intl = useIntl()

  const serialize = data =>
    omitUndefinedKeys({
      unitType: data.unitType,
      beginDate: formatDateTime(data.startDate),
      endDate: formatDateTime(data.endDate),
    })

  const handleSubmit = values => {
    setFormValues({
      unitType: values.unitType,
      beginDate: formatDateTime(values.startDate),
      endDate: formatDateTime(values.endDate),
    })
    onChange(
      undefined,
      {
        ...serialize(values),
      },
      sorter,
    )
  }

  const refreshTable = () => {
    onChange(undefined, undefined, sorter)
  }

  const createSuggestion = element => ({
    id: element,
    value: element,
  })

  return (
    <MainLayout
      header={intl.formatMessage({
        id: 'reports.header.units',
      })}
    >
      <SizedBox height="1" />
      <Row>
        <GenerateUnitReportForm
          onSubmit={values => {
            handleSubmit(values)
          }}
          data={UnitTypes}
          createSuggestion={createSuggestion}
          refresh={refreshTable}
        />
        <SizedBox width="0.5" />
        <div>
          <SizedBox height="0.5" />
          <DownloadUnitReport formValues={formValues} />
        </div>
      </Row>
      <SizedBox height="1" />
      <UnitReportStatistics meta={meta} />
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns}
        data={data}
        history={history}
        pagination={pagination}
        onChange={onChange}
        {...rest}
      />
    </MainLayout>
  )
})
