import React from 'react'
import { withRouter } from 'react-router-dom'
import { Link, Error, Space } from 'components/atoms'
import { Table } from 'components/organisms'
import { withModems } from 'api'
import { formatDateTime } from 'utils/formatters'

const columns = [
  {
    title: 'Details',
    key: 'details',
    searchable: true,
    render: record => (
      <>
        <Space>ICCID: {record.iccid}</Space>
        <Space>IMEI: {record.imei} </Space>
        <Space>WGIP: {record.wgip}</Space>
        <Space>Client ID: {record.clientId}</Space>
      </>
    ),
  },
  {
    title: 'Site',
    key: 'siteName',
    sortable: true,
    searchable: true,
    render: record => (
      <Link to={`/sites/${record.siteId}`}>{record.siteName}</Link>
    ),
    externalLink: true,
  },
  {
    title: 'Created at',
    key: 'createdAt',
    sortable: true,
    searchable: true,
    render: record => formatDateTime(record.createdAt),
  },
  {
    title: 'Registered by',
    key: 'creatorName',
    searchable: true,
    render: record => (
      <Link to={`/users/${record.createdById}`}>{record.createdBy}</Link>
    ),
    externalLink: true,
  },
]

export const ModemsList = withRouter(({ history }) => {
  const {
    data,
    isLoading,
    error,
    sorter,
    filters,
    onChange,
    ...rest
  } = withModems()
  return (
    <>
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns}
        data={data}
        sortingParams={sorter}
        filteringParams={filters}
        onChange={onChange}
        history={history}
        linkPath={'/modems'}
        {...rest}
      />
    </>
  )
})
