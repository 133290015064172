import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { FormattedMessage } from 'react-intl'
import { Typography, SizedBox, Spacing, Divider } from 'components/atoms'
import { FormProvider } from 'providers/FormProvider'
import { DeleteAccountForm } from './DeleteAccountForm/DeleteAccountForm'

export const DeleteAccount = withRouter(({ history }) => {
  const [isDone, setDone] = useState(false)
  return (
    <>
      <Divider />
      <Spacing width="100%" mt="3" ml="2">
        {!isDone && (
          <>
            <Typography.H2>
              <FormattedMessage id="delete-account.header" />
            </Typography.H2>
            <SizedBox height="1" />
            <FormProvider
              onSuccess={() => {
                history.push('/login')
                setDone(true)
              }}
              url={'/v1/user/delete'}
              render={({ updating, handleSubmit }) => (
                <DeleteAccountForm
                  loading={updating}
                  onSubmit={handleSubmit}
                  history={history}
                />
              )}
            />
          </>
        )}
      </Spacing>
    </>
  )
})
