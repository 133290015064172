import React from 'react'
import { Icon } from 'components/atoms'
import { PaginationContainer } from './styled/PaginationContainer'
import { PaginationButton } from './styled/PaginationButton'
import { getPageNumbers } from './getPageNumbers'

const FIRST_PAGE = 1
const getPage = (current, onPaginationChange) => () => {
  onPaginationChange({ current })
}

const renderLeftArrows = (current, onPaginationChange) => {
  return (
    <>
      <PaginationButton
        disabled={current === FIRST_PAGE}
        onClick={getPage(FIRST_PAGE, onPaginationChange)}
      >
        <Icon type="angleDoubleLeft" />
      </PaginationButton>

      <PaginationButton
        disabled={current === FIRST_PAGE}
        onClick={getPage(current - 1, onPaginationChange)}
      >
        <Icon type="angleLeft" />
      </PaginationButton>
    </>
  )
}

const renderRightArrows = (current, onPaginationChange, pagesCount) => {
  return (
    <>
      <PaginationButton
        disabled={current === pagesCount}
        onClick={getPage(current + 1, onPaginationChange)}
      >
        <Icon type="angleRight" />
      </PaginationButton>

      <PaginationButton
        disabled={current === pagesCount}
        onClick={getPage(pagesCount, onPaginationChange)}
      >
        <Icon type="angleDoubleRight" />
      </PaginationButton>
    </>
  )
}

const renderFigures = (current, onPaginationChange, pagesCount, range) => {
  const buttons = getPageNumbers(current, range, 1, pagesCount)
  return (
    <>
      {buttons.map(index => (
        <PaginationButton
          active={index === current}
          disabled={index > pagesCount || index < FIRST_PAGE}
          onClick={getPage(index, onPaginationChange)}
          key={index}
        >
          {index}
        </PaginationButton>
      ))}
    </>
  )
}

export const Pagination = ({ onChange, pageSize, total, current, range }) => {
  const pagesCount = Math.ceil(total / pageSize)
  return (
    <PaginationContainer>
      <div>
        {renderLeftArrows(current, onChange)}
        {renderFigures(current, onChange, pagesCount, range)}
        {renderRightArrows(current, onChange, pagesCount)}
      </div>
    </PaginationContainer>
  )
}

Pagination.defaultProps = {
  range: 6,
}
