import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { setLang } from 'rdx/actions/langActions'
import { MenuDropdown } from 'components/atoms'
import {
  ColorAlmostBlack,
  ColorBlack,
  ColorCharcoalGrey,
  ColorWhite,
  SizeSpacing01Rem,
  SizeSpacing05Rem,
} from 'style/design-tokens'
import { Logo } from './Logo'

const Header = styled.header`
  align-items: center;
  background-color: ${({ isLogged }) =>
    isLogged ? ColorCharcoalGrey : 'inherit'};
  border-bottom: 1px solid ${ColorBlack};
  display: flex;
  justify-content: space-around;
  a {
    padding: ${SizeSpacing01Rem}rem;
  }
  height: 64px;
`

const Nav = styled.nav`
  color: red;
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  & > a {
    margin-left: 1.4rem;
  }
  & > button {
    margin-left: 1.4rem;
  }
`
const MenuContainer = styled.div`
  border-left: 1px solid ${ColorAlmostBlack};
  border-right: 1px solid ${ColorAlmostBlack};
  height: 100%;
  color: ${ColorWhite};
  z-index: 999;
`

const NavbarText = styled.div`
  color: ${ColorWhite};
  font-size: 1.25rem;
  letter-spacing: 0.02em;
  margin-left: ${SizeSpacing05Rem}rem;
  text-transform: uppercase;
  width: 11rem;
`

const NavbarUnwrapped = props => {
  return (
    <Header isLogged={props.user}>
      <Link to="/">
        <Logo light />
      </Link>
      <NavbarText>
        <FormattedMessage id="layout.navbar.appname" />
      </NavbarText>
      <Nav>
        {props.user && (
          <MenuContainer>
            <MenuDropdown
              options={[
                props.user.permissions.isSuperUser
                  ? {
                    label: 'layout.navbar.administration',
                    value: '/administration',
                  }
                  : {},
                {
                  label: 'layout.navbar.map',
                  value: '/map',
                },
                {
                  label: 'layout.navbar.sites',
                  value: '/sites',
                },
                {
                  label: 'layout.navbar.systems',
                  value: '/systems',
                },
                {
                  label: 'layout.navbar.modems',
                  value: '/modems',
                },
                {
                  label: 'layout.navbar.reports',
                  value: '/reports',
                },
                {
                  label: 'layout.navbar.settings',
                  value: '/settings',
                },
                {
                  label: 'layout.navbar.logout',
                  value: '/logout',
                },
              ]}
              user={props.user}
              menuType="user"
            />
          </MenuContainer>
        )}
      </Nav>
    </Header>
  )
}

export const Navbar = connect(
  state => ({
    user: state.authentication.user,
  }),
  { setLang },
)(NavbarUnwrapped)
