import React from 'react'
import { MainLayout } from 'layout/MainLayout'
import { FormProvider } from 'providers/FormProvider'
import { withRouter } from 'react-router'
import { request } from 'api/request'
import { Spacing, Button, Typography } from 'components/atoms'
import { NewPasswordForm } from './NewPasswordForm/NewPasswordForm'
import { FormattedMessage } from 'react-intl'

const Container = ({ children, history }) => (
  <MainLayout.FullPageScreen>
    {children}
    <Spacing mt="3">
      <Button
        onClick={() => {
          history.push('/login')
        }}
      >
        Go to login page
      </Button>
    </Spacing>
  </MainLayout.FullPageScreen>
)

class ResetPasswordUnwrapped extends React.Component {
  state = {
    fetching: true,
    invalidToken: false,
    serverError: false,
    passwordResetToken: null,
    user: null,
  }

  componentDidMount = () => {
    this.fetchResetPasswordToken(this.props.match.params.passwordResetToken)
  }

  fetchResetPasswordToken = async passwordResetToken => {
    try {
      const response = await request.post(
        '/v1/user/request-password-reset-token',
        {
          data: {
            passwordResetToken,
          },
        },
      )
      this.setState({
        user: response.data.data.user,
        passwordResetToken: response.data.data.passwordResetToken,
        fetching: false,
      })
    } catch (err) {
      if (err.response.status === 400) {
        this.setState({ invalidToken: true, fetching: false })
      } else {
        this.setState({ serverError: true, fetching: false })
      }
    }
  }

  render() {
    const {
      fetching,
      invalidToken,
      serverError,
      user,
      passwordResetToken,
    } = this.state
    const { history } = this.props
    if (fetching) {
      return (
        <Container history={history}>
          <FormattedMessage id="reset.password.message.fetching" />
        </Container>
      )
    }
    if (invalidToken) {
      return (
        <Container history={history}>
          <FormattedMessage id="reset.password.message.invalid.token" />
        </Container>
      )
    }
    if (serverError) {
      return (
        <Container history={history}>
          <FormattedMessage id="reset.password.message.server.error" />
        </Container>
      )
    }
    return (
      <MainLayout.FullPageScreen>
        <Typography.H2>
          <FormattedMessage id="reset.password.message.greeting" />
          {user.firstName}
        </Typography.H2>
        <Typography.P>
          <FormattedMessage id="reset.password.message.prompt" />
        </Typography.P>
        <FormProvider
          url={'/v1/user/reset-password'}
          onSuccess={() => {
            this.props.history.push('/login')
          }}
          additionalData={{
            passwordResetToken: passwordResetToken,
          }}
          render={({ updating, handleSubmit }) => (
            <NewPasswordForm loading={updating} onSubmit={handleSubmit} />
          )}
        />
      </MainLayout.FullPageScreen>
    )
  }
}

export const ResetPassword = withRouter(ResetPasswordUnwrapped)
