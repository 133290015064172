import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field, reduxForm } from 'redux-form'
import { length } from 'redux-form-validators'
import { Form, Button, SizedBox } from 'components/atoms'
import { Input, Textarea } from 'components/molecules'
import { compose } from 'recompose'
import { ScannedInputLimits } from 'utils/InputLimits'
import { FormValidationErrorMessages } from 'utils/FormValidationErrorMessages'

const validate = values => {
  let errors = {}
  if (
    (!values.tagName && !values.detectionZone) ||
    (values.tagName &&
      values.tagName.length === 0 &&
      values.detectionZone &&
      values.detectionZone.length === 0)
  ) {
    errors.tagName = FormValidationErrorMessages.ScannedUnitBothTagAndDzAreEmpty
    errors.detectionZone =
      FormValidationErrorMessages.ScannedUnitBothTagAndDzAreEmpty
  }
  return errors
}

const AddUnitFormUnwrapped = ({
  handleSubmit,
  loading,
  pristine,
  invalid,
  onCancel,
}) => (
  <Form
    onSubmit={handleSubmit}
    description={<FormattedMessage id="add.unit.form.description" />}
  >
    <FormattedMessage id="scanned_unit.form.tag_or_dz_required" />
    <SizedBox height="1" />
    <Form.Item>
      <Form.Label>
        <FormattedMessage id="add.unit.form.tagName" />
      </Form.Label>
      <Field
        component={Input}
        name="tagName"
        validate={[length({ max: ScannedInputLimits.TagMaxLength })]}
        testId="add-unit-form-tag-name-input"
      />
    </Form.Item>
    <Form.Item>
      <Form.Label>
        <FormattedMessage id="add.unit.form.detectionZone" />
      </Form.Label>
      <Field
        component={Input}
        name="detectionZone"
        validate={[length({ max: ScannedInputLimits.DetectionZoneMaxLength })]}
        testId="add-unit-form-detectionZone-input"
      />
    </Form.Item>
    <Form.Row>
      <Form.Item>
        <Form.Label>
          <FormattedMessage id="add.unit.form.lsiNumber" />
        </Form.Label>
        <Field
          component={Input}
          name="lsiNumber"
          validate={[length({ max: ScannedInputLimits.LsiMaxLength })]}
          testId="add-unit-form-lsi-number-input"
        />
      </Form.Item>
      <Form.Item>
        <Form.Label>
          <FormattedMessage id="add.unit.form.unitType" />
        </Form.Label>
        <Field
          component={Input}
          name="unitType"
          validate={[length({ max: ScannedInputLimits.TypeMaxLength })]}
          testId="add-unit-form-unit-type-input"
        />
      </Form.Item>
    </Form.Row>
    <Form.Item>
      <Form.Label>
        <FormattedMessage id="add.unit.form.serialNumber" />
      </Form.Label>
      <Field
        component={Input}
        name="serialNumber"
        validate={[length({ max: ScannedInputLimits.SerialNumberMaxLength })]}
        testId="add-unit-form-serial-number-input"
      />
    </Form.Item>
    <Form.Item>
      <Form.Label>
        <FormattedMessage id="add.unit.form.comment" />
      </Form.Label>
      <Field
        component={Textarea}
        name="comment"
        validate={[length({ max: ScannedInputLimits.CommentMaxLength })]}
        testId="add-unit-form-comment"
      />
    </Form.Item>
    <Form.Buttons>
      <Button
        buttonType="secondary"
        onClick={onCancel}
        data-test-id="cancel-button"
      >
        <FormattedMessage id="button.cancel" />
      </Button>
      <Button
        type="submit"
        disabled={pristine || invalid || loading}
        data-test-id="add-unit-form-submit"
      >
        <FormattedMessage id="add.unit.form.submit" /> {loading ? '...' : ''}
      </Button>
    </Form.Buttons>
  </Form>
)

AddUnitFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export const AddUnitForm = compose(
  reduxForm({
    form: 'add-unit-form',
    validate,
  }),
)(AddUnitFormUnwrapped)
