import React from 'react'
import styled from 'styled-components'
import {
  ColorSeaBlue,
  ColorCharcoalGrey,
  SizeFontSmall,
  ColorFireRed,
} from 'style/design-tokens'

export const Form = styled.form`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
`

Form.Dark = styled.form`
  width: 100%;
  margin-bottom: 1rem;
  & div {
    border-radius: 1px;
  }
  & input {
    height: 38px;
  }
  & label {
    color: #bdbdbd;
    padding-left: 0;
    font-size: 12px;
  }
  & .error {
    color: ${ColorFireRed};
  }
  .input-container {
    padding: 0 0.6rem;
  }
`

Form.Container = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
`

Form.Row = styled.div`
  display: flex;
  flex-direction: row;
  width: ${({ width }) => (width ? width : '100%')};
  & div {
    margin-right: 0.6rem;
  }
  & div:last-child {
    margin-right: 0;
  }
`

Form.Checkbox = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
  width: ${({ width }) => (width ? width : '100%')};
  margin-left: 3rem;
`

Form.Item = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-bottom: ${({ theOnlyOne }) => (theOnlyOne ? '0' : '1.4rem')};
  & div {
    display: flex;
  }
  width: ${({ width }) => (width ? width : '100%')};
`

Form.Select = styled.div`
  width: ${({ width }) => (width ? width : '100%')};
  margin-bottom: 1.4rem;
`
Form.Date = styled.div`
  flex-direction: column;
`

Form.Error = styled(({ children }) => <div className="error">{children}</div>)`
  color: red;
  font-size: 0.6rem;
  margin-top: 0.2rem;
  min-height: 1rem;
`

Form.Warning = styled(({ children }) => (
  <div className="warning">{children}</div>
))`
  color: red;
  font-size: 0.8rem;
  margin-top: 0.2rem;
`

Form.Label = styled(({ children }) => (
  <label className="label">{children}</label>
))`
  color: ${ColorCharcoalGrey};
  font-size: ${SizeFontSmall}px;
  line-height: 19px;
  font-size: 14px;
`

Form.Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ right }) => (right ? 'flex-end' : 'flex-start')};
  & > button:not(:last-child) {
    margin-right: 0.6rem;
  }
`

Form.Button = styled.div`
  margin-top: 1rem;
  width: 100%;
`

Form.Links = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  & a {
    margin: 0 1rem;
    color: ${ColorSeaBlue};
    font-weight: 500;
  }
`
