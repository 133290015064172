import React from 'react'
import { withRouter } from 'react-router'
import {
  Space,
  Spacing,
  Protected,
  IconDropdown,
  ConfirmationModal,
  Icon,
  TextWrapper,
} from 'components/atoms'
import { Table } from 'components/organisms'
import { UploadLoopModal } from './UploadLoopModal/UploadLoopModal'
import { withScannedSystemLoops, deleteLoop, exportLoop } from 'api'
import { formatDateTime } from 'utils/formatters'
import { AccessLevels } from 'utils/AccesLevels'
import { useIntl, FormattedMessage } from 'react-intl'
import { AddLoopModal } from './AddLoopModal/AddLoopModal'

const downloadCSVFile = ({ data, fileName }) => {
  const blob = new Blob([data], { type: 'csv' })
  const a = document.createElement('a')
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}

const getDropdownOptions = (record, refresh, intl) => {
  return [
    {
      label: 'button.export.to.csv',
      action: async () => {
        const { data, attachmentName, error } = await exportLoop(record.id)
        if (!error) {
          let fileName = attachmentName || 'LoopUnits.csv'
          downloadCSVFile({
            data: data,
            fileName: fileName,
            fileType: 'text/csv',
          })
        } else {
          throw error
        }
      },
    },
    {
      label: 'button.delete',
      action: () => {
        ConfirmationModal.show({
          title: intl.formatMessage({
            id: 'modal.confirmation.default',
          }),
          text: intl.formatMessage({
            id: 'modal.delete.scanned.loop.confirmation',
          }),
          onSubmit: async () => {
            const { error } = await deleteLoop(record.id)
            if (!error) {
              refresh()
            } else {
              throw error
            }
          },
        })
      },
    },
  ]
}

const columns = (refresh, intl, siteId) => [
  {
    title: <FormattedMessage id={'scanned.loop.list.header.name'} />,
    key: 'name',
    render: record => <TextWrapper>{record.name}</TextWrapper>,
  },
  {
    title: <FormattedMessage id={'scanned.loop.list.header.units.amount'} />,
    key: 'unitsCount',
  },
  {
    title: <FormattedMessage id={'scanned.loop.list.header.locked'} />,
    render: record =>
      record.isLocked ? <Icon type="padlock" /> : <Icon type="padlock-open" />,
  },
  {
    title: <FormattedMessage id={'scanned.loop.list.header.ready'} />,
    render: record =>
      record.isReady ? <Icon type="check" /> : <Icon type="xmark" />,
  },
  {
    title: <FormattedMessage id={'scanned.loop.list.header.created.at'} />,
    render: record => formatDateTime(record.createdAt),
  },

  {
    title: <FormattedMessage id={'table.header.actions'} />,
    notClickable: true,
    render: record => (
      <Protected
        resourceId={siteId}
        accessLevel={AccessLevels.Admin}
        renderWithoutPermission={<>-</>}
      >
        <IconDropdown
          align="right"
          type="3dots"
          options={getDropdownOptions(record, refresh, intl)}
        />
      </Protected>
    ),
  },
]

export const ScannedSystemLoopList = withRouter(
  ({ siteId, systemId, history }) => {
    let intl = useIntl()
    const { data, isLoading, error, refresh, ...rest } = withScannedSystemLoops(
      systemId,
    )()
    return (
      <>
        <Table
          isLoading={isLoading}
          columns={columns(refresh, intl, siteId)}
          data={data}
          history={history}
          linkPath={'/scanned-loops'}
          {...rest}
        />
        <Protected resourceId={siteId} accessLevel={AccessLevels.Admin}>
          <Spacing mt="1" width="100%">
            <Space right>
              <UploadLoopModal systemId={systemId} refresh={refresh} />
            </Space>
          </Spacing>
        </Protected>
        <Protected resourceId={siteId} accessLevel={AccessLevels.Admin}>
          <Spacing mt="1" width="100%">
            <Space right>
              <AddLoopModal systemId={systemId} refresh={refresh} />
            </Space>
          </Spacing>
        </Protected>
      </>
    )
  },
)
