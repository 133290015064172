import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field, reduxForm } from 'redux-form'
import { required } from 'redux-form-validators'
import { Form, Button, SizedBox } from 'components/atoms'
import { Input, Radio } from 'components/molecules'
import { email } from 'redux-form-validators'

const validate = values => {
  let errors = {}
  if (values.accessLevel < 0) {
    errors.accessLevel = 'Required'
  }
  return errors
}

const InviteOperatorFormUnwrapped = ({
  handleSubmit,
  loading,
  pristine,
  invalid,
  onCancel,
}) => (
  <Form
    onSubmit={handleSubmit}
    description={
      <FormattedMessage id="systems.operators.invite.form.description" />
    }
  >
    <SizedBox height="1" />
    <Form.Item width="60%">
      <Form.Label htmlFor="emailAddress">
        <FormattedMessage id="form.emailAddress" />
      </Form.Label>
      <Field
        component={Input}
        iconType="at"
        name="emailAddress"
        type="text"
        validate={[required(), email()]}
        testId="system-user-invitation-form-email-input"
      />
    </Form.Item>
    <Form.Item>
      <Field
        component={Radio}
        name="accessLevel"
        type="radio"
        label={<FormattedMessage id="user.permissions.al.monitor" />}
        value={0}
        parse={value => parseInt(value, 10)}
        validate={[required()]}
        testId="site-user-invitation-form-email-read"
      />
    </Form.Item>
    <Form.Item>
      <Field
        component={Radio}
        name="accessLevel"
        label={<FormattedMessage id="user.permissions.al.operator" />}
        type="radio"
        value={1}
        parse={value => parseInt(value, 10)}
        validate={[required()]}
        testId="site-user-invitation-form-email-update"
      />
    </Form.Item>
    <Form.Item>
      <Field
        component={Radio}
        name="accessLevel"
        type="radio"
        label={<FormattedMessage id="user.permissions.al.admin" />}
        value={2}
        parse={value => parseInt(value, 10)}
        validate={[required()]}
        testId="site-user-invitation-form-email-delete"
      />
    </Form.Item>
    <Form.Buttons>
      <Button
        buttonType="secondary"
        onClick={onCancel}
        data-test-id="cancel-button"
      >
        <FormattedMessage id="button.cancel" />
      </Button>
      <Button
        type="submit"
        disabled={pristine || invalid || loading}
        data-test-id="system-user-invitation-form-submit"
      >
        <FormattedMessage id="system.details.user.invite" />{' '}
        {loading ? '...' : ''}
      </Button>
    </Form.Buttons>
  </Form>
)

InviteOperatorFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export const InviteOperatorForm = reduxForm({
  form: 'invite-operator-form',
  validate,
  initialValues: {
    accessLevel: -1,
  },
})(InviteOperatorFormUnwrapped)
