import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Navbar } from 'layout/Navbar/Navbar'
import { LeafletMaps } from './LeafletMaps/LeafletMaps'
import { Statistics } from '../administration/Statistics/Statistics'
import { ColorAlmostBlack } from 'style/design-tokens'
import { Spacing } from 'components/atoms/Spacing/Spacing'

const BackgroundContainer = styled.div`
  background-color: ${ColorAlmostBlack};
`

export const Map = connect(state => ({
  isSuperUser: state.authentication.user?.permissions?.isSuperUser ?? false,
}))(({ isSuperUser }) => (
  <BackgroundContainer>
    <Navbar />
    {isSuperUser && (
      <>
        <Spacing mt="0.35" />
        <Statistics />
      </>
    )}
    <LeafletMaps />
  </BackgroundContainer>
))
