import React from 'react'
import styled from 'styled-components'
import { Icon } from 'components/atoms'

const IconButtonContainer = styled.button`
  display: inline-block;
  padding: 3px 8px;
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`

export const IconButton = ({ onClick, type }) => {
    return (
        <IconButtonContainer onClick={onClick}>
            <Icon type={type} />
        </IconButtonContainer>
    )
}