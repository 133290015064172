import React from 'react'
import { compose } from 'recompose'
import { MainLayout } from 'layout/MainLayout'
import { withRouter } from 'react-router'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Typography, RequestButton } from 'components/atoms'
import { FormProvider } from 'providers/FormProvider'
import { ConfirmEmailForm } from './ConfirmEmailForm/ConfirmEmailForm'
import { showSuccess } from 'layout/notifications'
import { ColorSeaBlue } from 'style/design-tokens'

class ConfirmEmailUnwrapped extends React.Component {
  state = {
    loading: false,
    error: '',
    code: '',
  }

  makeRequest = async () => {
    try {
      // await request.post(`/v1/user/confirm-email`)
      this.setState({ loading: false })
    } catch (error) {
      this.setState({ loading: false, error: error.response.data.statusText })
    }
  }

  render() {
    return (
      <MainLayout.FullPageScreen>
        <Typography.H1>
          <FormattedMessage id="confirm-email.header" />
        </Typography.H1>
        <Typography.P>
          {(this.state.loading && 'Loading') ||
            (this.state.error && this.state.error) || (
              <FormattedMessage id="confirm-email.success" />
            )}
        </Typography.P>

        <FormProvider
          onSuccess={() => {
            this.props.history.push('/')
          }}
          url="/v1/user/registration/confirm-email-address"
          render={({ updating, handleSubmit }) => (
            <ConfirmEmailForm loading={updating} onSubmit={handleSubmit} />
          )}
        />
        <RequestButton
          url={'/v1/user/registration/resend-email-confirmation-code'}
          method="post"
          onSuccess={() =>
            showSuccess(
              this.props.intl.formatMessage({
                id: 'form.confirmation_code_resent',
              }),
            )
          }
          testId="invitations-accept-button"
          modalText={
            <FormattedMessage id="form.confirm_resend_confirmation_code" />
          }
          style={{
            color: ColorSeaBlue,
            backgroundColor: 'inherit',
          }}
        >
          <FormattedMessage id="button.resend.code" />
        </RequestButton>
      </MainLayout.FullPageScreen>
    )
  }
}

export const ConfirmEmail = compose(
  withRouter,
  injectIntl,
)(ConfirmEmailUnwrapped)
