import styled from 'styled-components'

export const Spacing = styled.div`
  margin-top: ${({ mt }) => mt || '0'}rem;
  margin-right: ${({ mr }) => mr || '0'}rem;
  margin-bottom: ${({ mb }) => mb || '0'}rem;
  margin-left: ${({ ml }) => ml || '0'}rem;
  padding-top: ${({ pt }) => pt || '0'}rem;
  padding-right: ${({ pr }) => pr || '0'}rem;
  padding-bottom: ${({ pb }) => pb || '0'}rem;
  padding-left: ${({ pl }) => pl || '0'}rem;
  text-align: ${({ align }) => align || 'inherit'};
  ${({ width }) => width && `width: ${width}`};
`

Spacing.Span = styled.span`
  margin-top: ${({ mt }) => mt || '0'}rem;
  margin-right: ${({ mr }) => mr || '0'}rem;
  margin-bottom: ${({ mb }) => mb || '0'}rem;
  margin-left: ${({ ml }) => ml || '0'}rem;
  padding-top: ${({ pt }) => pt || '0'}rem;
  padding-right: ${({ pr }) => pr || '0'}rem;
  padding-bottom: ${({ pb }) => pb || '0'}rem;
  padding-left: ${({ pl }) => pl || '0'}rem;
  text-align: ${({ align }) => align || 'inherit'};
`
