import React from 'react'
import { withSnackbar } from 'react-simple-snackbar'

const options = {
  style: {
    backgroundColor: 'white',
    minWidth: 50,
  },
  closeStyle: {
    display: 'none',
  },
}

export const SnackBar = withSnackbar(({ openSnackbar }) => {
  SnackBar.openSnackbar = openSnackbar
  return <></>
}, options)
