import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import {
  ColorMalachite,
  ColorWhite,
  SizeLineHeightMedium,
  SizeLineHeightXlarge,
  SizeSpacing03Rem,
} from 'style/design-tokens'

const base = css`
  background: ${ColorWhite};
  border: 1px solid ${ColorMalachite};
  color: ${ColorMalachite};
  display: block;
  height: ${SizeLineHeightMedium}px;
  max-height: ${SizeLineHeightMedium}px;
  width: ${SizeLineHeightMedium}px;
  max-width: ${SizeLineHeightMedium}px;
`

const Container = styled.div`
  color: ${ColorMalachite};
  display: inline-flex;
  padding: 0.6rem 2.6rem;
`

const Input = styled.input`
  ${base}
  display: flex;
  font-size: ${SizeLineHeightXlarge}px;
  margin-right: ${SizeSpacing03Rem}rem;
  :after {
    ${base}
    content: '';
  }
  :checked:after {
    ${base}
    content: '✓';
  }
`

const Label = styled.div`
  line-height: ${SizeLineHeightMedium}px;
`

export const Checkbox = ({ input, label }) => (
  <Container>
    <Input type="checkbox" value={input.checked} onChange={input.onChange} />
    <Label>{label}</Label>
  </Container>
)

Checkbox.propTypes = {
  label: PropTypes.node,
}
