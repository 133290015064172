import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import {
  ColorMalachite,
  ColorAlmostBlack,
  ColorWhite,
  ColorBlack,
  SizeLineHeightMedium,
  SizeLineHeightLarge,
  SizeSpacing03Rem,
} from 'style/design-tokens'

const CheckboxStyled = styled.div`
  color: ${ColorMalachite};
  display: inline-flex;
`

const CheckboxBase = css`
  display: block;
  height: ${SizeLineHeightMedium}px;
  width: ${SizeLineHeightMedium}px;
  ${({ dark }) =>
    dark
      ? `background: ${ColorAlmostBlack};
  border: 1px solid ${ColorBlack};
  color: ${ColorWhite};`
      : `background: ${ColorWhite};
  border: 1px solid ${ColorMalachite};
  color: ${ColorMalachite};`}
`

const CheckboxButton = styled.input`
  ${CheckboxBase}
  display: flex;
  font-size: ${SizeLineHeightLarge}px;
  margin-right: ${SizeSpacing03Rem}rem;
  :after {
    ${CheckboxBase}
    content: '';
  }
  :checked:after {
    ${CheckboxBase}
    content: '✓';
  }
`

const CheckboxLabel = styled.div`
  display: flex;
  margin: auto;
  width: fit-content;
`

export const Checkbox = ({
  onFiltersChange,
  filteringParams,
  field,
  dark,
  label,
}) => {
  const [checked, isChecked] = useState(false)

  const handleStateChange = event => {
    isChecked(!checked)

    if (event.target.value) {
      onFiltersChange({
        ...filteringParams,
        [field]: [event.target.checked],
      })
    } else {
      const newFilters = Object.fromEntries(
        Object.entries(filteringParams).filter(entry => entry[0] !== field),
      )
      onFiltersChange({
        ...newFilters,
      })
    }
  }

  return (
    <CheckboxStyled>
      <CheckboxButton
        type="checkbox"
        onChange={handleStateChange}
        dark={dark}
      />
      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxStyled>
  )
}
