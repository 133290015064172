export const enMessages = {
  'button.add.member': 'Add member',
  'button.cancel': 'Cancel',
  'button.create.team': 'Create new team',
  'button.edit.team': 'Edit team',
  'button.yes': 'Yes',
  'button.no': 'No',
  'button.confirm': 'Confirm',
  'button.save': 'Save',
  'button.refresh': 'Refresh',
  'button.submit': 'Submit',
  'button.create': 'Create',
  'button.add': 'Add',
  'button.login': 'Login',
  'button.resend.code': 'Resend confirmation code',
  'button.register': 'Register',
  'button.delete': 'Delete',
  'button.archive': 'Archive',
  'button.back': 'Back',
  'layout.navbar.home': 'Home',
  'layout.navbar.administration': 'Administration',
  'layout.navbar.map': 'Map',
  'layout.navbar.login': 'Log in',
  'layout.navbar.logout': 'Log out',
  'layout.navbar.sites': 'Sites',
  'layout.navbar.systems': 'Systems',
  'layout.navbar.settings': 'Settings',
  'layout.navbar.modems': 'Modems',
  'layout.navbar.users': 'Users',
  'layout.navbar.dashboard': 'Dashboard',
  'layout.navbar.appname': 'Autromaster',
  'form.firstName': 'First name',
  'form.lastName': 'Last name',
  'form.mobileNumber': 'Mobile number',
  'form.emailAddress': 'Email address',
  'form.emailConfirmation': 'Email confirmation',
  'form.password': 'Password',
  'form.passwordConfirmation': 'Password confirmation',
  'form.currentPassword': 'Current password',
  'form.newPassword': 'New password',
  'form.addressLine1': 'Address 1',
  'form.addressLine2': 'Address 2',
  'form.success': 'Form submitted successfully',
  'form.city': 'City',
  'form.country': 'Country',
  'form.postalCode': 'Postal code',
  'form.isCustomer': 'I own Fire Safety Systems',
  'form.isSystemOperator': 'I operate Fire Safety Systems',
  'form.confirmationCode': 'Confirmation code',
  'form.telephone': 'Phone number',
  'form.file': 'File',
  'form.comment': 'Comment',
  'form.name': 'Name',
  'users-list.header': 'Users',
  'register.header': 'Registration',
  'register.description': 'Set up your cloud account',
  'login.description': 'Add your info to continue',
  'login.header': 'Sign in to the Client Portal',
  'login.invalidCredentials': 'Invalid username or password',
  'login.retrieve-password': 'I forgot my password',
  'login.register': 'Register account',
  'user.register': 'Create new account',
  'user.login': 'Login',
  'terms-and-conditions.header': 'Terms and conditions',
  'system.details.user.invite': 'Invite',
  'system.operators.invite.form.description':
    'Grant access to this system to a new user',
  'edit.user.form.firstname': 'First name',
  'edit.user.form.lastname': 'Last name',
  'edit.user.form.email': 'Email address',
  'edit.user.form.phone': 'Phone number',
  'edit.user.form.address1': 'Address 1',
  'edit.user.form.address2': 'Address 2',
  'edit.user.form.postalcode': 'Postal code',
  'edit.user.form.city': 'City',
  'edit.user.form.country': 'Country',
  'edit.modem.form.topic': 'Topic',
  'edit.modem.form.clientId': 'Client ID',
  'edit.modem.form.iccid': 'ICCID',
  'edit.modem.form.imei': 'IMEI',
  'edit.modem.form.imsi': 'IMSI',
  'edit.modem.form.wgip': 'WGIP',
  'edit.modem.form.ts': 'TS',
  'change-password.header': 'Change your password',
  'forgot-password.header': 'Retrieve password',
  'forgot-password.subtitle':
    'Enter your email address to receive a password reset code',
  'sites.list.page.lines': 'Lines per page:',
  'forgot-password.success': 'Enter 6-digit code from the email message',
  'confirm-email.header': 'Confirm your email address',
  'confirm-email.success': 'Enter 6-digit code from the email message',
  'site.details.system.add': 'Add system',
  'form.modem.clientId': 'Client ID',
  'form.modem.iccid': 'ICCID',
  'form.modem.imei': 'IMEI',
  'form.modem.imsi': 'IMSI',
  'form.modem.wgip': 'WGIP',
  'form.modem.ts': 'TS',
  'form.modem.lat': 'Latitude',
  'form.modem.lng': 'Longitude',
  'site.details.modem.add': 'Add modem',
  'site.details.user.invite': 'Send invitation',
  'header.user.invitation.site': 'You received invitation to the site',
  'header.user.invitation.system': 'You received invitation to the system',
  'button.user.ivitation.accept': 'Accept',
  'button.user.ivitation.reject': 'Reject',
  'user.invitation_not_found': 'Invitation not found',
  'user.invitation_accepted': 'Invitation is already accepted',
  'modal.confirmation.default': 'Are you sure?',
  'add.site.form.name': 'Name',
  'add.site.form.description': 'Description (optional)',
  'add.site.form.address1': 'Address 1',
  'add.site.form.address2': 'Address 2 (optional)',
  'add.site.form.postalcode': 'Postal code',
  'add.site.form.city': 'City',
  'add.site.form.country': 'Country',
  'add.site.form.submit': 'Add site',
  'user_invitation.already_exists': 'User access already sent',
  'site_access.already_exists': 'User is already invited',
  'system_configuration.duplicate': 'Same configuration file cannot be added',
  'modem.no.configuration': 'Modem is currently not configured',
  'button.configure.modem': 'Configure modem',
  'button.update.config': 'Update configuration',
  'modem.configuration.form.ip': 'IP',
  'modem.configuration.form.subnet': 'Subnet',
  'modem.configuration.no.systems': 'Modem currently has no systems',
  'modem.configuration.system.host': 'HOST',
  'modem.configuration.system.port': 'PORT',
  'modem.configuration.system.username': 'USERNAME',
  'modem.configuration.system.password': 'PASSWORD',
  'modem.configuration.system.type': 'TYPE',
  'modem.configuration.form.system.name': 'NAME',
  'modem.configuration.form.system.delete': 'Delete',
  'missing.form.fields': 'Some form fields are missing',
  'modem.configuration.form.system.add': 'Add system',
  'statistics.modems': 'Modems',
  'statistics.loop_units': 'Loop units',
  'statistics.systems': 'Systems',
  'statistics.sites': 'Sites',
  'statistics.users': 'Users',
  'map.modem.issues': 'Issues',
  'map.modem.alarms': 'Alarms',
  'map.modem.status.connected': 'Connected',
  'map.modem.status.disconnected': 'Disconnected',
  'user.invalid_password': 'Invalid password',
  'user.invalid_email_or_password': 'Invalid credentials',
  'modem_endpoint.already_registered': 'Modem endpoint ID already registered',
  'modem.imei_already_registered': 'Modem IMEI is already registered',
  'form.system.modemEndpointId': 'Modem endpoint ID',
  'modem_endpoint.not_found': 'Modem endpoint not found',
  'modem.configuration.system.state': 'STATE',
  'modem.configuration.system.stage.mobileConnection': 'Mobile Connection',
  'modem.configuration.system.stage.networkConfig': 'Network Configuration',
  'modem.configuration.system.stage.panelConfiguration': 'Panel Configuration',
  'modem.configuration.system.stage.liveUpdates': 'Live Updates',
  'modem.configuration.endpoint.id': 'ENDPOINT ID',
  'modem.configuration.ip': 'IP',
  'modem.configuration.subnet': 'Subnet',
  'modem.configuration.systems': 'Systems',
  'modem_site.mismatch': 'Modem endpoint is located in a different site',
  'user.permissions.type.super': 'Super',
  'user.permissions.type.regular': 'Regular',
  'user.permissions.al.monitor': 'Monitoring Only',
  'user.permissions.al.operator': 'System Operator',
  'user.permissions.al.admin': 'Administrator',
  'user.permissions.form.globalAccessLevel': 'Global Access Level',
  'user.permissions.form.userType': 'User type',
  'user.missing_permissions': 'User permissions are missing',
  'user.invalid_password_format': 'Invalid password format',
  'user.email_already_exists_confirmed': 'Email already registered',
  'modem.configuration.endpoint.system.archived': 'ARCHIVED',
  'delete-account.header': 'Delete account',
  'modem.configuration.select': 'Select...',
  'user.confirmation_code_expired': 'Confirmation code expired',
  'user.invalid_confirmation_code': 'Invalid confirmation code',
  'form.confirm_resend_confirmation_code':
    'Do you want to resent the confirmation code?',
  'form.confirmation_code_resent': 'Confirmation code resent',
  'form.scanned.system.name': 'Scanned system name',
  'site.details.scanned.system.add': 'Add',
  'table.header.actions': 'Actions',
  'modal.delete.scanned.system.confirmation':
    'Do you want to delete this scanned system?',
  'form.scanned.loop.name': 'Loop name',
  'button.modify': 'Modify',
  'add.unit.form.loop': 'Loop',
  'add.unit.form.lsiNumber': 'LSI (optional)',
  'add.unit.form.unitType': 'Type (optional)',
  'add.unit.form.tagName': 'Tag',
  'add.unit.form.detectionZone': 'Detection zone',
  'add.unit.form.serialNumber': 'Serial Number (optional)',
  'add.unit.form.comment': 'Comment (optional)',
  'scanned.systems.list.header.name': 'Name',
  'scanned.systems.list.header.created.at': 'Created at',
  'scanned.units.list.header.detectionZone': 'Detection zone',
  'scanned.units.list.header.tagName': 'Tag name',
  'scanned.units.list.header.unitType': 'Unit type',
  'scanned.units.list.header.serialNumber': 'Serial number',
  'scanned.units.list.header.lsiNumber': 'LSI number',
  'scanned.units.list.header.comment': 'Comment',
  'layout.header.loop.units': 'Loop units',
  'modal.add.button': 'Add',
  'edit.unit.modal.title': 'Edit unit',
  'modal.delete.scanned.loop.confirmation':
    'Do you want to delete this scanned loop?',
  'scanned.loop.list.header.name': 'Name',
  'scanned.loop.list.header.units.amount': 'Units amount',
  'scanned.loop.list.header.created.at': 'Created at',
  'modal.upload.button': 'Upload',
  'upload.loop.modal.title': 'Upload loop',
  'add.scanned.system.modal.title': 'Add scanned system to site',
  'button.export.to.csv': 'Export to *.csv',
  'modal.delete.scanned.unit.confirmation':
    'Do you want to delete this scanned unit?',
  'scanned.loop.list.header.ready': 'Ready',
  'scanned.loop.list.header.locked': 'Locked',
  'add.unit.form.submit': 'Add',
  'edit.unit.form.submit': 'Update',
  'scanned.system.tab.loops': 'Loops',
  'scanned.system.tab.edit': 'Edit',
  'scanned.system.tab.edit.details': 'Details',
  'add.loop.modal.title': 'Add loop',
  'scanned.systems.list.header.loops.count': 'Loops amount',
  'scanned_unit.tag_name_already_exists': 'Tag name already exists',
  'scanned_unit.serial_number_already_exists': 'Serial number already exists',
  'scanned_unit.form.tag_or_dz_required': 'Tag or Detection Zone is required',
  'button.mark.as.unready': 'Mark as unready',
  'button.mark.as.ready': 'Mark as ready',
  'modal.mark.loop.as.unready.confirmation':
    'Do you want mark this loop as unready for production?',
  'modal.mark.loop.as.ready.confirmation':
    'Do you want mark this loop as ready for production?',
  'button.enable.modifications': 'Enable modifications',
  'button.disable.modifications': 'Disable modifications',
  'modal.mark.loop.as.unlocked.confirmation':
    'Do you want to enable modifications in this loop?',
  'modal.mark.loop.as.locked.confirmation':
    'Do you want to disable modifications in this loop?',
  'scanned.loop.locked.for.modification': 'Modifications',
  'scanned.loop.ready.for.production': 'Ready for production',
  'scanned.loop.tab.units': 'Units',
  'scanned.loop.tab.edit': 'Edit',
  'scanned.loop.edit.details': 'Details',
  'layout.navbar.reports': 'Reports',
  'reports.form.input.unit.type': 'Unit type',
  'reports.form.input.start.date': 'Start date',
  'reports.form.input.end.date': 'End date',
  'reports.form.button.generate.report': 'Generate report',
  'reports.form.input.enter.unit.type': 'Enter unit type...',
  'button.input.search': 'Search...',
  'reports.form.label.unit.type': 'Unit type',
  'reports.form.date.input.select.end': 'Select end date...',
  'reports.form.date.input.select.start': 'Select start date...',
  'reports.headers.system': 'System',
  'reports.headers.site': 'Site',
  'reports.headers.configuration.time': 'Configuration time',
  'reports.headers.unit.type': 'Unit type',
  'reports.headers.units.count': 'Units amount',
  'button.download': 'Download',
  'explication.view.details': 'View details',
  'explication.hide.details': 'Hide details',
  'explication.total': 'Total',
  'form.number': 'Number',
  'add.site.form.number': 'Number',
  'reports.button.unit.report': 'Unit report',
  'reports.button.system.events.report': 'System event report',
  'reports.header.select.type': 'Select type of report',
  'reports.form.label.system.name': 'System name',
  'reports.form.input.enter.system.name': 'Enter system name...',
  'reports.headers.unit.name': 'Unit name',
  'reports.headers.unit.id': 'Unit ID',
  'reports.headers.unit.class': 'Unit class',
  'reports.headers.unit.hardware': 'Hardware',
  'reports.headers.event.name': 'Event name',
  'reports.headers.system.name': 'System name',
  'reports.headers.timestamp': 'Timestamp',
  'reports.headers.orginal.message': 'Orginal message',
  'reports.header.system.events': 'System events reports',
  'reports.header.units': 'Unit report',
  'link.raw': 'raw',
  'statistics.units': 'Units',
  'reports.button.install.base.report': 'Install base report',
  'statistics.contracts': 'Contracts',
  'reports.header.install.base': 'Install base report',
  'reports.headers.hash': '#',
  'reports.headers.eq.number': 'EQ number',
  'reports.headers.customer.number': 'Customer number',
  'reports.headers.site.number': 'Site number',
  'reports.headers.facility.name': 'Facility name',
  'reports.headers.sys': 'SYS',
  'reports.headers.ac': 'AC',
  'reports.headers.business.unit': 'Business unit',
  'reports.headers.created.date': 'Created date',
  'reports.headers.address': 'Address',
  'reports.headers.site.name': 'Site name',
  'reports.install.base.last.imported.at': 'Last imported at',
  'reports.install.base.last.imported.by': 'by',
  'reports.headers.postal.code': 'Postal code',
  'reports.headers.city': 'City',
  'reports.install.base.import.file': 'Import file',
  'reports.install.base.import': 'Import report',
  'button.upload': 'Upload',
  'reports.header.unit.age': 'Units age report',
  'reports.headers.hardware': 'Hardware',
  'reports.headers.serial.number': 'Serial number',
  'reports.headers.production.date': 'Production date',
  'reports.headers.unit.age': 'Unit age',
  'reports.headers.system.type': 'System type',
  'reports.headers.business.site.number': 'Site number',
  'reports.headers.site.address': 'Site address',
  'reports.button.unit.age.report': 'Unit age report',
  'reports.form.input.produced.before.optional': 'Produced before (optional)',
  'reports.form.date.input.select.date': 'Select date...',
  'reports.button.system.unit.report': 'System units report',
  'reports.headers.connection': 'Connection',
  'reports.header.system.units': 'System units reports',
  'install_base_report.parsing_failed':
    'Install base report file has invalid data format',
  'reports.headers.unit.tags': 'Unit tags',
}
