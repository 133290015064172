import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Button, Typography } from 'components/atoms'
import { StyledModal } from './styled/StyledModal'

const styles = {
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  button: {
    width: '40%',
  },
}

export class ConfirmationModal extends React.Component {
  componentDidMount() {
    StyledModal.setAppElement('body')
  }

  render() {
    const {
      isOpen,
      onRequestClose,
      contentLabel,
      children,
      modalText,
      handleConfirm,
    } = this.props
    return (
      <StyledModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel={contentLabel}
        style={{ overlay: { backgroundColor: '#000000c2', zIndex: 9999 } }}
      >
        <Typography.H3 type="dark">{modalText}</Typography.H3>
        <div style={styles.buttonContainer}>
          <Button
            style={styles.button}
            onClick={handleConfirm}
            data-test-id="confirmation-modal-yes"
          >
            <FormattedMessage id="button.yes" />
          </Button>
          <Button
            style={styles.button}
            onClick={onRequestClose}
            data-test-id="confirmation-modal-no"
          >
            <FormattedMessage id="button.no" />
          </Button>
        </div>
        {children}
      </StyledModal>
    )
  }
}

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.any,
  contentLabel: PropTypes.string,
  modalText: PropTypes.object,
}
