import React from 'react'
import styled from 'styled-components'
import { Icon } from 'components/atoms/Icon/Icon'
import { withRouter } from 'react-router'
import {
  ColorWhite,
  SizeFontMedium,
  FontWeightSemibold,
  SizeSpacing04Rem,
  SizeSpacing01Rem,
  ColorBlack,
  ColorMalachite,
} from 'style/design-tokens'
import { FormattedMessage } from 'react-intl'

const Container = styled.div`
  height: 100%;
`

const DropdownContainer = styled.div`
  position: relative;
  height: 0;
  width: 50;
  bottom: 10px;
   right: ${({ align }) => (align === 'right' ? '-15px' : '135px')};
  border-radius: 20px;
  z-index:5;
`

const OptionsList = styled.div`
  position: absolute;
  top: 0;
  background-color: ${ColorWhite};
  border-radius: 5px;
  jdisplay: flex;
  flex-direction: column;
  width: 140px;
`

const OptionButton = styled.button`
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  color: ${ColorMalachite};
  cursor: pointer;
  display: block;
  font-size: ${SizeFontMedium}px;
  font-weight: ${FontWeightSemibold};
  align-self: flex-start;
  font: inherit;
  outline: inherit;
  padding: ${SizeSpacing01Rem}rem ${SizeSpacing04Rem}rem;
  ${({ isActive }) => isActive && `background-color: ${ColorBlack};`}
`

class IconDropdownUnwrapped extends React.Component {
  state = {
    expanded: false,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick)
  }

  handleClick = event => {
    if (
      !this.containerEl.contains(event.target) &&
      this.state.expanded === true
    ) {
      this.setState({ expanded: false })
    }
  }

  toggleDropdown = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const { options, align, type } = this.props
    const { expanded } = this.state
    return (
      <Container
        onClick={this.toggleDropdown}
        ref={ref => (this.containerEl = ref)}
      >
        <Icon
          type={type}
          size="1x"
          onPress={this.toggleDropdown}
          data-test-id="cog-dropdown-button"
        />
        <DropdownContainer align={align}>
          {expanded && (
            <OptionsList data-test-id="dropdown-options-list">
              {options.map((option, index) => (
                <OptionButton
                  key={index}
                  isActive={this.props.match.path === option.value}
                  onClick={option.action}
                >
                  <FormattedMessage id={option.label} />
                </OptionButton>
              ))}
            </OptionsList>
          )}
        </DropdownContainer>
      </Container>
    )
  }
}

export const IconDropdown = withRouter(IconDropdownUnwrapped)
