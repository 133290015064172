export const ScannedInputLimits = {
  DefaultMaxLength: 100,
  NameMaxLength: 100,
  TypeMaxLength: 24,
  TagMaxLength: 24,
  LsiMaxLength: 30,
  DetectionZoneMaxLength: 34,
  SerialNumberMaxLength: 50,
  CommentMaxLength: 255,
}
