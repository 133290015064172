import React from 'react'
import './layout/base.css'
import 'normalize.css'
import { Provider } from 'react-redux'
import { Router } from './router/Router'
import { store } from 'rdx/store'
import { TranslationsProvider } from 'translations/TranslationsProvider'
import { SnackBar } from 'components/molecules/SnackBar/SnackBar'
import SnackbarProvider from 'react-simple-snackbar'

export const App = () => (
  <Provider store={store}>
    <SnackbarProvider>
      <TranslationsProvider>
        <Router />
        <SnackBar />
      </TranslationsProvider>
    </SnackbarProvider>
  </Provider>
)

export default App
