import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Headers } from './Headers'
import { Row } from './Row'
import { Pagination } from './Pagination/Pagination'
import { path } from 'ramda'
import {
  ColorAlmostBlack,
  ColorBlack,
  ColorWhite,
  ColorCharcoalGrey,
  SizeSpacing07Rem,
} from 'style/design-tokens.js'
import { Spin, SizedBox } from 'components/atoms'
import { PaginationSelect } from './Pagination/PaginationSelect'
import { v4 } from 'uuid'
import { FormattedMessage } from 'react-intl'

const Placeholder = styled.tr`
  & td {
    padding: 3rem;
    font-size: 1rem;
  }
`

const TableContainer = styled.table`
  border-collapse: collapse;
  border: 1px solid ${ColorBlack};
  box-sizing: border-box;
  color: ${ColorWhite};
  font-size: 1rem;
  text-align: center;
  width: 100%;
  & thead {
    & th {
      background-color: #121212;
      padding: 0;
      vertical-align: top;
    }
  }
  & tbody {
    background-color: ${ColorAlmostBlack};
    & td {
      border: 1px solid ${ColorBlack};
    }
    & tr {
      background-color: ${ColorAlmostBlack};
      &:hover {
        background-color: ${ColorCharcoalGrey};
        opacity: 90%;
      }
    }
  }
`

export const Table = ({
  testId,
  columns,
  data,
  indexKey,
  indexPath,
  refresh,
  linkPath,
  pagination,
  onChange,
  isLoading,
  sortingParams,
  filteringParams,
  history,
  hasIterator,
}) => (
  <>
    {data && pagination && pagination.total > 10 ? (
      <PaginationSelect
        onChange={onChange}
        sortingParams={sortingParams}
        filteringParams={filteringParams}
        {...pagination}
      />
    ) : (
      <SizedBox height={SizeSpacing07Rem} />
    )}
    <TableContainer data-test-id={testId}>
      <Headers
        columns={
          hasIterator
            ? [
                {
                  title: <FormattedMessage id="reports.headers.hash" />,
                },
                ...columns,
              ]
            : columns
        }
        sortingParams={sortingParams}
        filteringParams={filteringParams}
        onSortChange={sorter => onChange(undefined, filteringParams, sorter)}
        onFiltersChange={filters => {
          onChange(undefined, filters, sortingParams)
        }}
      />
      <tbody>
        {!isLoading && (data || []).length === 0 && (
          <Placeholder>
            <td colSpan={hasIterator ? columns.length + 1 : columns.length}>
              Nothing to show
            </td>
          </Placeholder>
        )}
        {(data || []).map((item, index) => {
          let key = indexPath ? path(indexPath, item) : item[indexKey]
          return (
            <Row
              index={index}
              history={history}
              columns={
                hasIterator
                  ? [
                      {
                        title: <FormattedMessage id="reports.headers.hash" />,
                        render: () => (
                          <>
                            {(pagination.current - 1) * pagination.pageSize +
                              index +
                              1}
                            .
                          </>
                        ),
                      },
                      ...columns,
                    ]
                  : columns
              }
              key={key ?? v4()}
              indexKey={key}
              item={item}
              refresh={refresh}
              linkPath={linkPath}
            />
          )
        })}
      </tbody>
    </TableContainer>
    {!data && isLoading && <Spin />}
    {data && pagination && pagination.total > 10 && (
      <Pagination
        onChange={pagination =>
          onChange(pagination, filteringParams, sortingParams)
        }
        {...pagination}
      />
    )}
  </>
)

Table.defaultProps = {
  sortingParams: {},
  filteringParams: {},
  indexKey: 'id',
}

Table.propTypes = {
  indexKey: PropTypes.string,
  indexPath: PropTypes.string,
  data: PropTypes.any,
  columns: PropTypes.any,
  sortingParams: PropTypes.any,
  filteringParams: PropTypes.any,
  testId: PropTypes.string,
  linkPath: PropTypes.string,
  refresh: PropTypes.func,
  pagination: PropTypes.any,
}
