import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { signOut, signedIn } from 'rdx/actions/authActions'
import { MainLayout } from 'layout/MainLayout'
import { Typography } from 'components/atoms'
import { FormattedMessage, injectIntl } from 'react-intl'
import { FormProvider } from 'providers/FormProvider'
import { LoginForm } from './LoginForm/LoginForm'

class LoginUnwrapped extends React.Component {
  componentDidMount() {
    this.props.signOut()
  }

  handleLogin = response => {
    this.props.signedIn(response.data)
    this.props.history.push('/')
  }

  render() {
    return (
      <MainLayout.FullPageScreen>
        <Typography.H1 type="dark">
          <FormattedMessage id="login.header" />
        </Typography.H1>
        <Typography.H3 type="dark">
          <FormattedMessage id="login.description" />
        </Typography.H3>
        <FormProvider
          onSuccess={this.handleLogin}
          url={'/v1/auth'}
          render={({ updating, handleSubmit }) => (
            <LoginForm loading={updating} onSubmit={handleSubmit} />
          )}
        />
      </MainLayout.FullPageScreen>
    )
  }
}

export const Login = connect(null, { signOut, signedIn })(
  withRouter(injectIntl(LoginUnwrapped)),
)
