import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import { Button, Form, Center, SizedBox } from 'components/atoms'
import { Input } from 'components/molecules'
import { FormattedMessage, useIntl } from 'react-intl'
import { required, email } from 'redux-form-validators'

const LoginFormUnwrapped = ({ handleSubmit, loading, pristine, invalid }) => {
  const intl = useIntl()
  return (
    <Form.Container>
      <Form onSubmit={handleSubmit}>
        <Form.Item>
          <Form.Label htmlFor="emailAddress">
            <FormattedMessage id="form.emailAddress" />
          </Form.Label>
          <Field
            component={Input}
            name="emailAddress"
            type="text"
            iconType="user"
            validate={[required(), email()]}
            testId="login-form-email-input"
            placeholder={intl.formatMessage({
              id: 'form.emailAddress',
            })}
          />
        </Form.Item>

        <Form.Item>
          <Form.Label htmlFor="password">
            <FormattedMessage id="form.password" />
          </Form.Label>
          <Field
            component={Input}
            name="password"
            type="password"
            iconType="padlock"
            validate={[required()]}
            testId="login-form-password-input"
            placeholder={intl.formatMessage({
              id: 'form.password',
            })}
          />
        </Form.Item>
        <Form.Buttons>
          <Center>
            <Button
              type="submit"
              disabled={pristine || invalid || loading}
              data-test-id="login-form-submit"
            >
              <FormattedMessage id="button.login" /> {loading ? '...' : ''}
            </Button>
          </Center>
        </Form.Buttons>
      </Form>
      <SizedBox height="1" />
      <Form.Links>
        <Link to="/forgot-password">
          <FormattedMessage id="login.retrieve-password" />
        </Link>
      </Form.Links>
      <Form.Links>
        <Link to="/register">
          <FormattedMessage id="user.register" />
        </Link>
      </Form.Links>
    </Form.Container>
  )
}

LoginFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export const LoginForm = reduxForm({
  form: 'login-form',
})(LoginFormUnwrapped)
