import React from 'react'
import { withRouter } from 'react-router'
import { Error, Link } from 'components/atoms'
import { Table } from 'components/organisms'
import { UserStatus } from 'components/atoms'
import { withAdminSystems } from 'api'

const columns = [
  {
    title: 'Name',
    key: 'name',
    sortable: true,
    searchable: true,
  },
  {
    title: 'Number',
    key: 'number',
    sortable: true,
    searchable: true,
  },
  {
    title: 'Site',
    key: 'siteName',
    sortable: true,
    searchable: true,
    render: record => (
      <Link to={`/sites/${record.siteId}`}>{record.siteName || 'Site'}</Link>
    ),
    externalLink: true,
  },
  {
    title: 'Issues count',
    key: 'issuesCount',
    sortable: true,
  },
  {
    title: 'Status',
    key: 'status',
    sortable: true,
    searchable: true,
    render: record => <UserStatus status={record.status} />,
  },
]

export const SystemsList = withRouter(({ history }) => {
  const {
    data,
    isLoading,
    error,
    pagination,
    sorter,
    filters,
    ...rest
  } = withAdminSystems()
  return (
    <>
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns}
        data={data}
        pagination={pagination}
        sortingParams={sorter}
        filteringParams={filters}
        linkPath={'/systems'}
        history={history}
        {...rest}
      />
    </>
  )
})
