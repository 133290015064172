import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormProvider } from 'providers/FormProvider'
import { Typography, Form } from 'components/atoms'
import { StyledModal } from './styled/StyledModal'
import { SizeSpacing03Rem } from 'style/design-tokens'

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 88px;
  margin-top: 17px;
  width: 472px;
  & :first-child {
    margin-bottom: ${SizeSpacing03Rem}rem;
  }

  & h2 {
    font-weight: 500;
  }
`

export class FormModal extends React.Component {
  componentDidMount() {
    StyledModal.setAppElement('body')
  }

  render() {
    const {
      isOpen,
      onRequestClose,
      contentLabel,
      modalText,
      description,
      initialValues,
      selectOptions,
      method,
    } = this.props
    return (
      <StyledModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel={contentLabel}
        style={{ overlay: { backgroundColor: '#000000c2', zIndex: 9999 } }}
      >
        <ModalHeader>
          <Typography.H2 type="dark">{modalText}</Typography.H2>
          {description && (
            <Typography.H3 type="dark">{description}</Typography.H3>
          )}
        </ModalHeader>
        <FormProvider
          url={this.props.formUrl}
          method={method}
          render={({ updating, handleSubmit, error }) => (
            <>
              <this.props.form
                loading={updating}
                onSubmit={handleSubmit}
                onCancel={onRequestClose}
                initialValues={initialValues}
                selectOptions={selectOptions}
              />
              {error && <Form.Error>{error}</Form.Error>}
            </>
          )}
          onSuccess={this.props.onSuccess}
        />
      </StyledModal>
    )
  }
}

FormModal.defaultProps = {
  onSuccess: () => false,
  method: 'post',
}

FormModal.propTypes = {
  children: PropTypes.any,
  contentLabel: PropTypes.string,
  description: PropTypes.any,
  form: PropTypes.any,
  formUrl: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  modalText: PropTypes.object,
  onRequestClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  initialValues: PropTypes.any,
  selectOptions: PropTypes.any,
  method: PropTypes.string,
}
