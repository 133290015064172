import React from 'react'
import styled from 'styled-components'
import { ColorFireRed } from 'style/design-tokens'

const getBackgroundColor = type => {
  switch (type) {
    case 'warning':
      return ColorFireRed
    default:
      return '#5a5a5a'
  }
}

export const Container = styled.span`
  display: inline-block;
  padding: 4px 8px 1px 8px;
  font-size: 14px;
  border: 1px solid #888888;
  border-radius: 2px;
  color: #e4e4e4;
  background-color: ${({ type }) => getBackgroundColor(type)};
`

export const Tag = ({ children, title, type }) => (
  <Container title={title} type={type}>
    {children}
  </Container>
)
