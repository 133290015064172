import React from 'react'
import { Icon } from 'components/atoms'
import { InputFilterContainer } from './styled/InputFilterContainer'
import { InputFilterStyled } from './styled/InputFilterStyled'

export const InputFilter = ({
  onFiltersChange,
  filteringParams,
  field,
  testId,
}) => (
  <InputFilterContainer>
    <Icon type="search" />
    <InputFilterStyled
      defaultValue={
        filteringParams[field] ? filteringParams[field].toString() : ''
      }
      onChange={event => {
        if (event.target.value) {
          onFiltersChange({
            ...filteringParams,
            [field]: [event.target.value.toString()],
          })
        } else {
          const newFilters = Object.fromEntries(
            Object.entries(filteringParams).filter(entry => entry[0] !== field),
          )
          onFiltersChange({
            ...newFilters,
          })
        }
      }}
      placeholder="Search"
      data-test-id={testId}
    />
  </InputFilterContainer>
)
