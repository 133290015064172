import React from 'react'
import { MainLayout } from 'layout/MainLayout'
import { Link } from 'components/atoms'

export const Page404 = () => (
  <MainLayout.FullPageScreen>
    <h1>404: Page not found</h1>
    <Link to="/">Back to home page</Link>
  </MainLayout.FullPageScreen>
)
