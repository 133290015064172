import React, { useState } from 'react'
import styled from 'styled-components'
import { pathOr } from 'ramda'
import {
  ColorWhite,
  ColorPistachio,
  ColorMalachite,
  SizeSpacing02Rem,
  FontWeightSemibold,
} from 'style/design-tokens'
import { FormattedMessage } from 'react-intl'

const Input = styled.input`
  display: none;
`

const Label = styled.label`
  border: none;
  display: flex;
  cursor: pointer;
  background-color: ${({ dark }) => (dark ? ColorMalachite : ColorPistachio)};
  color: ${({ dark }) => (dark ? ColorWhite : ColorMalachite)};
  border-radius: 1px;
  border-spacing: 0;
  border: none;
  font-size: ${SizeSpacing02Rem};
  font-weight: ${FontWeightSemibold};
  justify-content: space-evenly;
  align-items: center;
  outline: none;
  height: 38px;
  text-decoration: none;
  text-indent: 0;
  & svg:first-child {
    margin-right: 0.8rem;
  }
  &:disabled {
    opacity: 0.8;
  }
`

const Filename = styled.div`
  font-size: 0.8rem;
  margin: 4px 0;
`

export const FileInput = ({ input: { onChange, onBlur }, dark, ...props }) => {
  const [fileName, onFileNameChange] = useState('')
  const adaptFileEventToValue = delegate => event => {
    const file = pathOr(null, ['target', 'files', 0], event)
    onFileNameChange(file.name)
    return delegate(file)
  }

  return (
    <>
      <Label dark={dark}>
        <FormattedMessage id="reports.install.base.import.file" />
        <Input
          onChange={adaptFileEventToValue(onChange)}
          onBlur={adaptFileEventToValue(onBlur)}
          {...props}
        />
      </Label>
      <Filename>{fileName}</Filename>
    </>
  )
}
