const errorMessages = {
  'user.not_found': 'User not found',
  'generic.unauthorized_access': 'Unauthorized access',
  'generic.not_found': 'Not found',
  'generic.bad_request': 'Bad request',
  'unit.not_found': 'Unit not found',
  'user.invalid_auth_token': 'Invalid authentication token',
  'user.invalid_confirmation_code': 'Invalid confirmation code',
  'user.confirmation_code_expired': 'Confirmation code has expired',
  'user.max_attempts_reached': 'Maximum number of attempts reached',
  'user.invalid_password_format': 'Invalid password format',
  'user.unauthorized_access':
    'User does not have permission to access this resource',
  'user.missing_permissions': 'User permissions are missing',
  'user.initial_password_already_set': 'Initial password is already set',
  'user.invitation_not_found': 'User invitation was not found',
  'user.invitation_accepted': 'User invitation is accepted',
  'user.email_address_cannot_be_changed':
    'User email address cannot be changed',
  'user.email_address_missing': 'User email address is missing',
  'user.email_address_already_confirmed':
    'User email address is already confirmed',
  'user.phone_number_already_set': 'User phone number is already set',
  'user.invalid_email_or_password': 'Email or password is invalid',
  'user.initial_password_not_set': 'Initial password is not set',
  'user.invalid_password': 'Invalid password',
  'user.redundant_request': 'Redundant request',
  'user.email_already_exists_confirmed':
    'Email address already exists and is confirmed',
  'user.email_already_exists_unconfirmed':
    'Email address already esists and is unconfirmed',
  'user.device_not_found': 'Device not found',
  'hardware.not_found': 'Hardware not found',
  'hardware.already_exists': 'Hardware already exists',
  'site.not_found': 'Site not found',
  'system_configuration.duplicate':
    'Uploaded system configuration is a duplicate',
  'system_configuration.not_found': 'System configuration not found',
  'system.not_found': 'System not found',
  'system.already_exists': 'System already exists',
  'system.already_archived': 'System is already archived',
  'system.user_already_added': 'System user is already added',
  'task.not_found': 'Task not found',
  'task.unit_already_marked_as_tested': 'Task unit is already marked as tested',
  'task.not_in_progress': 'Task is not in progress',
  'task.already_in_progress': 'Task is already in progress',
  'task.unit_not_tested': 'Task unit is not tested',
  'task.completed': 'Task is completed',
  'issue.not_found': 'Issue not found',
  'loop.not_found': 'Loop not found',
  'modem.unsupported_code_format': 'Unsupported modem code format',
  'modem.imei_already_registered': 'Modem IMEI is already registered',
  'modem.not_found': 'Modem not found',
  'site_access.not_found': 'Site access invitation not found',
  'site_access.already_exists': 'Site access invitation already exists',
  'site_access.already_accepted': 'Site access invitation is already accepted',
  'system_access.not_found': 'System access invitation not found',
  'user_invitation.rejected': 'User invitation is rejected',
  'user_invitation.already_exists': 'User invitation already exists',
  'site.has_live_systems': 'Site with live systems cannot be deleted',
  'scanned_loop.is_locked': 'Modifications in this loop are disabled',
}

export const displayError = error => {
  if (error.response) {
    return `${error.response.status}: ${errorMessages[
      error.response.data.statusText
    ] || error.response.data.statusText}`
  }
  return error.message || error.toString()
}
