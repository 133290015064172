/* global L */
import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { renderToString } from 'react-dom/server'
import { Address, Error, Icon } from 'components/atoms'
import { withAccessModemsIssued } from 'api'
import styled from 'styled-components'
import {
  ColorMalachite,
  ColorFireRed,
  ColorAckGreen,
  ColorCarrotOrange,
} from 'style/design-tokens'

const modemStateIcon = item => {
  if (!item.isConnected) {
    return '/icons/offline.png'
  } else if (item.alarmIssuesCount - item.solvedAlarmIssuesCount > 0) {
    return '/icons/alarm.png'
  } else if (item.issuesCount - item.solvedIssuesCount > 0) {
    return '/icons/warning.png'
  } else {
    return '/icons/active.png'
  }
}

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 64px);
  width: 100vw;
`

const MapContainer = styled.div`
  height: calc(100vh - 64px);
  width: '100vw';
  background-color: ${ColorMalachite};
`

const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const PopupTitle = styled.a`
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 0;
  color: ${ColorCarrotOrange};
  cursor: pointer;
`
const AddressContainer = styled.div`
  font-size: 1.2rem;
  font-weight: 300;
  margin-top: 0;
  color: 'black';
`

const PopupLabel = styled.div`
  font-size: 1.2rem;
  font-weight: 300;
  margin-top: 0;
`

const Popup = ({
  siteId,
  siteName,
  address,
  issuesCount,
  solvedIssuesCount,
  alarmIssuesCount,
  solvedAlarmIssuesCount,
  isConnected,
}) => {
  return (
    <PopupContainer>
      <PopupTitle href={`/sites/${siteId}`}>{siteName}</PopupTitle>
      <AddressContainer>{Address({ address }).props.children}</AddressContainer>
      <br />
      <PopupLabel
        style={{
          color: ColorCarrotOrange,
        }}
      >
        Issues: {issuesCount - solvedIssuesCount}
      </PopupLabel>
      <PopupLabel
        style={{
          color: ColorFireRed,
        }}
      >
        Alarms: {alarmIssuesCount - solvedAlarmIssuesCount}
      </PopupLabel>
      <PopupLabel
        style={{
          color: isConnected ? ColorAckGreen : ColorMalachite,
        }}
      >
        {isConnected ? 'Connected' : 'Disconnected'}
      </PopupLabel>
    </PopupContainer>
  )
}

export const LeafletMapsUnwrapped = ({ user }) => {
  const mapRef = useRef(null)
  const { data, isLoading, error } = withAccessModemsIssued(user.id, {})()

  useEffect(() => {
    if (!data || isLoading) {
      return
    }
    const position = [62.2386637, 9.0431588]
    const map = L.map(mapRef.current).setView(position, 5)
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map)
    data.forEach(item => {
      const marker = L.marker([item.lat, item.lng], {
        icon: L.icon({
          iconUrl: modemStateIcon(item),
          iconSize: [40, 50],
        }),
      })
      marker.bindPopup(renderToString(Popup(item)))
      marker.addTo(map)
    })
  }, [data, isLoading])

  return (
    <>
      {error && <Error>{error}</Error>}
      {isLoading && (
        <LoadingWrapper>
          <Icon type="sync" size="5x" spin={isLoading} />
        </LoadingWrapper>
      )}
      {!isLoading && <MapContainer ref={mapRef} />}
    </>
  )
}

export const LeafletMaps = connect(state => ({
  user: state.authentication.user,
}))(LeafletMapsUnwrapped)
