import React from 'react'
import { Tag, Link } from 'components/atoms'
import { Table } from 'components/organisms'

const columns = [
  {
    title: 'Name',
    render: record => <Tag>{record.name}</Tag>,
  },
  {
    title: 'Description',
    key: 'description',
  },
  {
    title: 'Timestamp',
    key: 'timestamp',
  },
]

const logs = [
  {
    id: '1',
    name: 'EMAIL_ADDRESS_CHANGED',
    description: (
      <>
        User{' '}
        <Link to="/users/1">
          <Tag>U/00001</Tag>
        </Link>{' '}
        updated email address to john.doe@gmail.com
      </>
    ),
    timestamp: '01-06-2020 15:20',
  },
  {
    id: '1',
    name: 'USER_REGISTERED',
    description: (
      <>
        User{' '}
        <Link to="/users/1">
          <Tag>U/00001</Tag>
        </Link>{' '}
        updated personal details
      </>
    ),
    timestamp: '01-06-2020 15:20',
  },
  {
    id: '1',
    name: 'SYSTEM_CREATED',
    description: (
      <>
        User{' '}
        <Link to="/users/1">
          <Tag>U/00001</Tag>
        </Link>{' '}
        created new system{' '}
        <Link to="/systems/1">
          <Tag>S/00001</Tag>
        </Link>{' '}
      </>
    ),
    timestamp: '01-06-2020 15:20',
  },
]

export const UserLogs = () => {
  return <Table columns={columns} data={logs}></Table>
}
