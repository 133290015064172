import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { Button, Form, Spacing } from 'components/atoms'
import { Input } from 'components/molecules'
import { FormattedMessage } from 'react-intl'
import { required, format } from 'redux-form-validators'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { store } from 'rdx/store'
import { reset } from 'redux-form'

const ChangePasswordFormUnwrapped = withRouter(
  ({ handleSubmit, loading, pristine, invalid, password, newPassword }) => {
    return (
      <Form.Dark onSubmit={handleSubmit}>
        <Form.Item width="400px">
          <Form.Label htmlFor="currentPassword">
            <FormattedMessage id="form.currentPassword" />
          </Form.Label>
          <Field
            component={Input}
            name="password"
            type="password"
            validate={[
              required(),
              format({
                with: password ? password.length < 8 : true,
                message:
                  'Current password is too short (should be at least 8 characters)',
              }),
            ]}
            testId="change-password-form_current-password"
          />
        </Form.Item>
        <Form.Item width="400px">
          <Form.Label htmlFor="newPassword">
            <FormattedMessage id="form.newPassword" />
          </Form.Label>
          <Field
            component={Input}
            name="newPassword"
            type="password"
            validate={[
              required(),
              format({
                with: password === newPassword,
                message: 'New password cannot be the same as the current one',
              }),
              format({
                with: newPassword ? newPassword.length < 8 : true,
                message:
                  'New password is too short (should be at least 8 characters)',
              }),
            ]}
            testId="change-password-form_new-password"
          />
        </Form.Item>
        <Form.Item width="400px">
          <Form.Label htmlFor="passwordConfirmation">
            <FormattedMessage id="form.passwordConfirmation" />
          </Form.Label>
          <Field
            component={Input}
            name="passwordConfirmation"
            type="password"
            validate={[
              required(),
              format({
                with: new RegExp(`^${newPassword}$`, 'i'),
                message:
                  'Password confirmation must be the same as new password',
              }),
            ]}
            testId="change-password-form_-password-confirmation"
          />
        </Form.Item>
        <Spacing mt="3">
          <Form.Buttons>
            <Button
              loading={loading}
              disabled={pristine}
              buttonType="secondary"
              data-test-id="change-password-form-cancel"
              onClick={() => {
                store.dispatch(reset('change-password-form'))
              }}
            >
              <FormattedMessage id="button.cancel" />
            </Button>
            <Button
              type="submit"
              disabled={
                pristine || invalid || loading || password === newPassword
              }
              data-test-id="change-password-form_submit"
            >
              <FormattedMessage id="button.submit" /> {loading ? '...' : ''}
            </Button>
          </Form.Buttons>
        </Spacing>
      </Form.Dark>
    )
  },
)

ChangePasswordFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

const fieldSelector = formValueSelector('change-password-form')

export const ChangePasswordForm = compose(
  reduxForm({
    form: 'change-password-form',
  }),
  connect(state => ({
    newPassword: fieldSelector(state, 'newPassword'),
    password: fieldSelector(state, 'password'),
  })),
)(ChangePasswordFormUnwrapped)
