import React from 'react'
import PropTypes from 'prop-types'
import { reset } from 'redux-form'
import { store } from 'rdx/store'
import { Field, reduxForm } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import { required } from 'redux-form-validators'
import { Button, Form } from 'components/atoms'
import { Input } from 'components/molecules'

const EditLoopFormUnwrapped = ({
  handleSubmit,
  invalid,
  loading,
  pristine,
}) => {
  return (
    <Form.Dark onSubmit={handleSubmit}>
      <Form.Item width="400px">
        <Form.Label>
          <FormattedMessage id="form.name" />
        </Form.Label>
        <Field
          component={Input}
          name="name"
          validate={[required()]}
          testId="edit-loop-form-name-input"
        />
      </Form.Item>
      <Form.Buttons>
        <Button
          loading={loading}
          disabled={pristine}
          buttonType="secondary"
          data-test-id="edit-loop-form-cancel"
          onClick={() => {
            store.dispatch(reset('edit-loop-form'))
          }}
        >
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button
          loading={loading}
          type="submit"
          disabled={pristine || invalid || loading}
          data-test-id="edit-loop-form-save"
        >
          <FormattedMessage id="button.save" />
        </Button>
      </Form.Buttons>
    </Form.Dark>
  )
}

EditLoopFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export const EditLoopForm = reduxForm({
  form: 'edit-loop-form',
})(EditLoopFormUnwrapped)
