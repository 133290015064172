import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from 'components/atoms'
import { StyledModal } from './styled/StyledModal'
import { SizeSpacing03Rem } from 'style/design-tokens'

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 88px;
  margin-top: 17px;
  width: 472px;
  & :first-child {
    margin-bottom: ${SizeSpacing03Rem}rem;
  }
`

export class Modal extends React.Component {
  componentDidMount() {
    StyledModal.setAppElement('body')
  }

  render() {
    const {
      isOpen,
      onRequestClose,
      contentLabel,
      title,
      description,
      children,
      width,
    } = this.props
    return (
      <StyledModal
        width={width}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel={contentLabel}
        style={{
          overlay: { backgroundColor: '#000000c2', zIndex: 9999 },
        }}
      >
        <ModalHeader>
          <Typography.H2 type="dark">{title}</Typography.H2>
          {description && (
            <Typography.H3 type="dark">{description}</Typography.H3>
          )}
        </ModalHeader>
        {children}
      </StyledModal>
    )
  }
}

Modal.defaultProps = {
  onSuccess: () => false,
  method: 'post',
}

Modal.propTypes = {
  children: PropTypes.any,
  contentLabel: PropTypes.string,
  description: PropTypes.any,
  isOpen: PropTypes.bool.isRequired,
  modalText: PropTypes.object,
  onRequestClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
}
