import React from 'react'
import { Redirect } from 'react-router-dom'
import { SizedBox, Divider } from 'components/atoms'
import { Tabs } from 'components/molecules/Tabs/Tabs'
import { MainLayout } from 'layout/MainLayout'
import { LiveSystems } from './LiveSystems/LiveSystems'
import { UsersList } from './UsersList/UsersList'
import { SitesList } from './SitesList/SitesList'
import { SystemsList } from './SystemsList/SystemsList'
import { connect } from 'react-redux'
import { ModemsList } from './ModemsList/ModemsList'
import { Statistics } from './Statistics/Statistics'

const AdministrationTabs = {
  LiveConnections: '0',
  Users: '1',
  Sites: '2',
  Systems: '3',
  Modems: '4',
}

export const Administration = connect(state => ({
  isSuperUser: state.authentication.user?.permissions?.isSuperUser ?? false,
}))(({ isSuperUser }) => {
  if (!isSuperUser) {
    return <Redirect to="/sites" />
  }
  const tab = (
    window.location.hash || AdministrationTabs.LiveConnections
  ).replace('#', '')
  const setIndex = value => (window.location.hash = value)
  return (
    <MainLayout header="Administration">
      <Statistics />
      <SizedBox height="1" />
      <Tabs>
        <Tabs.Tab
          onClick={() => setIndex(AdministrationTabs.LiveConnections)}
          selected={tab === AdministrationTabs.LiveConnections}
          testId="live-connections-tab"
        >
          Live&nbsp;connections
        </Tabs.Tab>
        <Tabs.Tab
          onClick={() => setIndex(AdministrationTabs.Users)}
          selected={tab === AdministrationTabs.Users}
          testId="users-tab"
        >
          Users
        </Tabs.Tab>
        <Tabs.Tab
          onClick={() => setIndex(AdministrationTabs.Sites)}
          selected={tab === AdministrationTabs.Sites}
          testId="sites-tab"
        >
          Sites
        </Tabs.Tab>
        <Tabs.Tab
          onClick={() => setIndex(AdministrationTabs.Systems)}
          selected={tab === AdministrationTabs.Systems}
          testId="systems-tab"
        >
          Systems
        </Tabs.Tab>
        <Tabs.Tab
          onClick={() => setIndex(AdministrationTabs.Modems)}
          selected={tab === AdministrationTabs.Modems}
          testId="modems-tab"
        >
          Modems
        </Tabs.Tab>
      </Tabs>
      <Divider />
      {tab === AdministrationTabs.LiveConnections && <LiveSystems />}
      {tab === AdministrationTabs.Users && <UsersList />}
      {tab === AdministrationTabs.Sites && <SitesList />}
      {tab === AdministrationTabs.Systems && <SystemsList />}
      {tab === AdministrationTabs.Modems && <ModemsList />}
    </MainLayout>
  )
})
