import React from 'react'
import {
  Error,
  Tag,
  Link,
  Space,
  Spacing,
  Protected,
  Button,
  Icon,
  Right,
  Typography,
  Explication,
  TextWrapper,
} from 'components/atoms'
import { Table } from 'components/organisms'
import { UploadConfigurationModal } from './UploadConfigurationModal/UploadConfigurationModal'
import { withSystemConfigurations, withConfigurationSummary } from 'api'
import { formatDateTime } from 'utils/formatters'
import { UploadClassReasonModal } from './UploadClassReasonModal/UploadClassReasonModal'
import { AccessLevels } from 'utils/AccesLevels'
import { DownloadRawConfigurationFile } from './DownloadRawConfigurationFile/DownloadRawConfigurationFile'
import { FormattedMessage } from 'react-intl'

const getUrl = (systemId, hash, extenstion) =>
  `${systemId}/configurations/${hash}.${extenstion}`

const columns = (systemId, refresh, systemType, isArchived) => [
  {
    title: 'Id',
    key: 'hash',
    render: record => (
      <span onClick={() => alert(record.hash)} title={record.hash}>
        {(record.hash || '').slice(-12)}
      </span>
    ),
  },
  {
    title: 'Comment',
    key: 'comment',
    render: record => <TextWrapper>{record.comment}</TextWrapper>,
  },
  {
    title: 'Class / reason',
    render: record =>
      systemType === 'autroprime' ? (
        '-'
      ) : record.classReason ? (
        <>
          <Spacing mb="0.6">
            <Link
              to={{
                pathname: getUrl(record.systemId, record.hash, 'xml'),
                state: { data: record.classReason },
              }}
            >
              View XML
            </Link>
          </Spacing>
          {isArchived ? null : (
            <Protected resourceId={systemId} accessLevel={AccessLevels.Admin}>
              <UploadClassReasonModal
                title="Update"
                configurationId={record.id}
                refresh={refresh}
              />
            </Protected>
          )}
        </>
      ) : isArchived ? null : (
        <Protected resourceId={systemId} accessLevel={AccessLevels.Admin}>
          <UploadClassReasonModal
            title="Upload"
            configurationId={record.id}
            refresh={refresh}
          />
        </Protected>
      ),
  },
  {
    title: 'Created at',
    render: record => formatDateTime(record.createdAt),
  },
  {
    title: 'Status',
    render: record => (
      <Tag title={record.parsingError}>
        {record.parsingError
          ? 'ERROR'
          : record.isProcessed
          ? 'Ready'
          : 'Processing'}
      </Tag>
    ),
  },
  {
    title: 'Files',
    render: record => (
      <Space>
        {record.xml && (
          <Link
            to={{
              pathname: getUrl(record.systemId, record.hash, 'xml'),
              state: { data: record.xml },
            }}
          >
            xml
          </Link>
        )}
        {record.hasRaw && (
          <DownloadRawConfigurationFile configurationId={record.id} />
        )}
      </Space>
    ),
  },
  {
    title: 'Summary',
    render: record => getHardwareSummaryFormatted(record.id),
    width: '15rem',
  },
]

const getHardwareSummaryFormatted = configurationId => {
  const { data } = withConfigurationSummary(configurationId)()
  if (data == null) {
    return
  }
  return (
    <Explication
      total={data.reduce(
        (total, hwitem) => (total = total + parseInt(hwitem.quantity)),
        0,
      )}
      content={
        <Typography.P>
          {data.map(hwitem => (
            <Typography.P mb="0">
              {hwitem.hardwareTag + ': ' + hwitem.quantity}
            </Typography.P>
          ))}
        </Typography.P>
      }
    />
  )
}

export const SystemConfigurations = ({ systemId, systemType, isArchived }) => {
  const { data, isLoading, error, refresh, ...rest } = withSystemConfigurations(
    systemId,
  )()
  return (
    <>
      <Right>
        <Button
          data-test-id="refresh-configurations-statuses-button"
          buttonType="primary"
          onClick={refresh}
        >
          <Icon type="sync" /> <FormattedMessage id={'button.refresh'} />
        </Button>
      </Right>
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns(systemId, refresh, systemType, isArchived)}
        data={data}
        {...rest}
      ></Table>
      {isArchived ? null : (
        <Protected resourceId={systemId} accessLevel={AccessLevels.Admin}>
          <Spacing mt="1" width="100%">
            <Space right>
              <UploadConfigurationModal systemId={systemId} refresh={refresh} />
            </Space>
          </Spacing>
        </Protected>
      )}
    </>
  )
}
