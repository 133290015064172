import styled from 'styled-components'
import {
  ColorWhite,
  ColorPistachio,
  ColorBlack,
  ColorMalachite,
} from 'style/design-tokens'

export const StyledInputWithSuggestions = styled.div`
  .react-autosuggest__container {
    position: absolute;
  }

  .react-autosuggest__input {
    width: ${({ width }) => (width ? width : '150px')};
    height: 24px;
    padding: 5px 3px;
    font-family: inherit;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid ${ColorPistachio};
    border-radius: 2px;
    z-index: 3;
    color: ${ColorBlack};
  }

  .react-autosuggest__input:focus {
    outline: none;
  }

  .react-autosuggest__container--open .react-autosuggest__input {
    border-radius: 4px;
  }

  .react-autosuggest__suggestions-container {
    position: absolute;
    top: 9px;
    width: ${({ width }) => (width ? width : '150px')};
    max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : '125px')};
    padding: 0;
    margin: 0;
    list-style-type: none;
    background-color: ${ColorWhite};
    color: ${ColorMalachite};
    font-size: 14px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: auto;
    list-style-type: none;
    z-index: 1;
  }
  .react-autosuggest__suggestions-container:has(.react-autosuggest__suggestion) {
    border: 1px solid ${ColorPistachio};
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: ${ColorPistachio};
    }

    ::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    }
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    width: ${({ width }) => (width ? width : '150px')};
    padding: 5px 3px;
    margin-left: -40px;
    list-style-type: none;
    text-align: left;
    border-top: 1px solid ${ColorPistachio};
  }
`
