import styled from 'styled-components'
import {
  ColorWhite,
  SizeSpacing01Rem,
  SizeSpacing07Rem,
  ColorAlmostBlack,
  ColorBlack,
} from 'style/design-tokens'

export const InputFilterContainer = styled.div`
  align-items: center;
  background-color: ${ColorAlmostBlack};
  border-radius: ${SizeSpacing07Rem}rem;
  border: 1px solid ${ColorBlack};
  color: ${ColorWhite};
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  padding: 0.1rem;
  max-width: 15rem;
  > * {
    margin-left: ${SizeSpacing01Rem / 2}rem;
  }
`
