import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router'
import { MainLayout } from 'layout/MainLayout'
import { Tabs } from 'components/molecules/Tabs/Tabs'
import {
  SizedBox,
  IconDropdown,
  Field,
  Link,
  Icon,
  TextWrapper,
} from 'components/atoms'
import { Redirect } from 'react-router-dom'
import { formatDateTime } from 'utils/formatters'
import { withScannedLoop, deleteLoop, updateLoop } from 'api'
import { ConfirmationModal } from 'components/atoms'
import { Protected } from 'components/atoms'
import { AccessLevels } from 'utils/AccesLevels'
import { ScannedUnitsList } from './ScannedUnitsList/ScannedUnitList'
import { FormattedMessage, useIntl } from 'react-intl'
import { EditLoop } from './EditLoop/EditLoop'

const LoopStatus = styled.div`
  items-align: center;
  font-size: 14px;
  margin-bottom: 1rem;
  color: #bdbdbd;
  display: flex;
  flex-direction: row;
  & > * {
    margin-right: 1rem;
    items-align: center;
  }
`
const LoopTabs = {
  Units: '0',
  Edit: '1',
}

export const LoopPage = compose(
  withRouter,
  connect(state => ({
    user: state.authentication.user,
  })),
)(({ match, history, user }) => {
  let intl = useIntl()
  const {
    isInitialLoading,
    pick,
    refresh,
    isLoading,
    errorStatus,
  } = withScannedLoop(match.params.id)

  const tab = (window.location.hash || LoopTabs.Units).replace('#', '')
  const setIndex = value => (window.location.hash = value)

  const getOperatorDropDownOptions = () => [
    {
      label: pick(['isReady'])
        ? 'button.mark.as.unready'
        : 'button.mark.as.ready',
      action: () => {
        ConfirmationModal.show({
          title: intl.formatMessage({
            id: 'modal.confirmation.default',
          }),
          text: intl.formatMessage({
            id: pick(['isReady'])
              ? 'modal.mark.loop.as.unready.confirmation'
              : 'modal.mark.loop.as.ready.confirmation',
          }),
          onSubmit: async () => {
            const { error } = await updateLoop(pick(['id']), {
              isReady: !pick(['isReady']),
            })
            if (!error) {
              refresh()
            } else {
              throw error
            }
          },
        })
      },
    },
  ]

  const getAdminDropDownOptions = () => [
    ...getOperatorDropDownOptions(),
    {
      label: pick(['isLocked'])
        ? 'button.enable.modifications'
        : 'button.disable.modifications',
      action: () => {
        ConfirmationModal.show({
          title: intl.formatMessage({
            id: 'modal.confirmation.default',
          }),
          text: intl.formatMessage({
            id: pick(['isLocked'])
              ? 'modal.mark.loop.as.unlocked.confirmation'
              : 'modal.mark.loop.as.locked.confirmation',
          }),
          onSubmit: async () => {
            const { error } = await updateLoop(pick(['id']), {
              isLocked: !pick(['isLocked']),
            })
            if (!error) {
              refresh()
            } else {
              throw error
            }
          },
        })
      },
    },
    {
      label: 'button.delete',
      action: () => {
        ConfirmationModal.show({
          title: intl.formatMessage({
            id: 'modal.confirmation.default',
          }),
          text: intl.formatMessage({
            id: 'modal.delete.scanned.loop.confirmation',
          }),
          onSubmit: async () => {
            const { error } = await deleteLoop(pick(['id']))
            if (!error) {
              history.push(`/scanned-systems/${pick(['scannedSystemId'])}`)
            } else {
              throw error
            }
          },
        })
      },
    },
  ]

  if (errorStatus) {
    return <Redirect to={`/${errorStatus}`} />
  }
  return (
    <MainLayout
      isLoading={isInitialLoading}
      header={<>{pick(['name'])}</>}
      extra={
        user &&
        user.permissions.resources[pick(['siteId'])] === AccessLevels.Admin ? (
          <Protected
            resourceId={pick(['siteId'])}
            accessLevel={AccessLevels.Admin}
          >
            <IconDropdown type={'cog'} options={getAdminDropDownOptions()} />
          </Protected>
        ) : (
          <Protected
            resourceId={pick(['siteId'])}
            accessLevel={AccessLevels.Operator}
          >
            <IconDropdown type={'cog'} options={getOperatorDropDownOptions()} />
          </Protected>
        )
      }
    >
      <Field title="System">
        <Link to={`/scanned-systems/${pick(['scannedSystemId'])}`}>
          <TextWrapper>{pick(['scannedSystemName']) || 'System'}</TextWrapper>
        </Link>
      </Field>
      <LoopStatus>
        <div>
          <FormattedMessage id={'scanned.loop.locked.for.modification'} />
        </div>
        {pick(['isLocked']) ? (
          <Icon type="padlock" />
        ) : (
          <Icon type="padlock-open" />
        )}
      </LoopStatus>

      <LoopStatus>
        <div>
          <FormattedMessage id={'scanned.loop.ready.for.production'} />
        </div>
        {pick(['isReady']) ? <Icon type="check" /> : <Icon type="xmark" />}
      </LoopStatus>

      <Field title="Created at">{formatDateTime(pick(['createdAt']))}</Field>
      <SizedBox height="1" />

      <Tabs>
        <Tabs.Tab
          onClick={() => setIndex(LoopTabs.Units)}
          selected={tab === LoopTabs.Units}
          testId="units-tab"
        >
          <FormattedMessage id="scanned.loop.tab.units" />
        </Tabs.Tab>
        <Protected
          resourceId={match.params.id}
          accessLevel={AccessLevels.Admin}
        >
          <Tabs.Tab
            onClick={() => setIndex(LoopTabs.Edit)}
            selected={tab === LoopTabs.Edit}
            testId="edit-tab"
          >
            <FormattedMessage id="scanned.loop.tab.edit" />
          </Tabs.Tab>
        </Protected>
      </Tabs>
      {tab === LoopTabs.Units && (
        <ScannedUnitsList systemId={match.params.id} refresh={refresh} />
      )}
      {tab === LoopTabs.Edit && !isLoading && (
        <EditLoop refresh={refresh} loopId={pick(['id'])} pick={pick} />
      )}
    </MainLayout>
  )
})
