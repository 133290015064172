import React from 'react'
import { reset } from 'redux-form'
import { store } from 'rdx/store'
import { Spacing, Typography, Divider } from 'components/atoms'
import { FormProvider } from 'providers/FormProvider'
import { EditSystemForm } from './EditSystemForm'

const getInitialValue = pick => ({
  name: pick(['name']),
  comment: pick(['comment']),
})

export const EditSystem = ({ refresh, systemId, pick }) => (
  <>
    <Divider />
    <Spacing width="100%" mt="3" ml="2">
      <Typography.H3>Details</Typography.H3>
      <FormProvider
        method="put"
        onSuccess={() => {
          refresh()
          store.dispatch(reset('edit-system-form'))
        }}
        url={`/v1/systems/${systemId}`}
        render={({ updating, handleSubmit }) => (
          <EditSystemForm
            loading={updating}
            onSubmit={handleSubmit}
            initialValues={getInitialValue(pick)}
          />
        )}
      />
    </Spacing>
  </>
)
