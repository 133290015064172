/**
 * Do not edit directly
 * Generated on Mon, 23 Sep 2019 11:02:57 GMT
 */

export const ColorOceanBlue = '#0043a2'
export const ColorWhite = '#ffffff'
export const ColorFireRed = '#de0000'
export const ColorCarrotOrange = '#f89024'
export const ColorAckGreen = '#7ed321'
export const ColorSunnyYellow = '#fff917'
export const ColorSeaBlue = '#0960ee'
export const ColorSacramento = '#0d383c'
export const ColorMalachite = '#14555a'
export const ColorSage = '#b4c0c4'
export const ColorPistachio = '#d7dddf'
export const ColorMisty = '#ebebeb'
export const ColorCloudy = '#fafafa'
export const ColorKoalaGrey = '#bfbfbf'
export const ColorCharcoalGrey = '#323232'
export const ColorAlmostBlack = '#1c1c1c'
export const ColorBlack = '#000000'
export const ColorGreenOnion = '#64A71B'
export const ColorRichCarmine = '#DE0038'
export const ColorLegacyBaseGrayLight = '#cccccc'
export const ColorLegacyBaseGrayMedium = '#999999'
export const ColorLegacyBaseGrayDark = '#111111'
export const ColorLegacyBaseGrayButton = '#323232'
export const ColorLegacyBaseGrayBackground = '#3b3b3b'
export const ColorLegacyBaseGrayIsabelline = '#ebebeb'
export const ColorLegacyBaseGrayAsh = '#b2b2b2'
export const ColorLegacyBaseRed = '#d83f3c'
export const ColorLegacyBaseGreen = '#74b945'
export const ColorLegacyBaseBlue = '#258cff'
export const ColorLegacyBaseOrange = '#ea9541'
export const ColorLegacyBaseCarrot = '#f89024'
export const ColorLegacyBaseWhite = '#ffffff'
export const ColorLegacyBaseWhiteSmoke = '#f3f4f8'
export const ColorLegacyBaseBlack = '#000000'
export const ColorLegacyBaseBoulder = '#777777'
export const ColorLegacyBaseJungleMedium = '#2a2a2a'
export const ColorLegacyBaseJungleDark = '#1f1f1f'
export const ColorLegacyBaseAzure = '#0080ff'
export const ColorLegacyBaseAthensGray = '#f2f4f7'
export const ColorLegacyFontBase = '#d83f3c'
export const ColorLegacyFontSecondary = '#74b945'
export const ColorLegacyFontTertiary = '#cccccc'
export const ColorLegacyFontLink = '#258cff'
export const ColorLegacyFontWarning = '#ea9541'
export const ColorLegacyFontWhite = '#ffffff'
export const SizeFontXSmall = 12 // px
export const SizeFontXSmallRem = 0.7 // rem
export const SizeFontSmall = 16 // px
export const SizeFontSmallRem = 1 // rem
export const SizeFontMedium = 20 // px
export const SizeFontMediumRem = 1.25 // rem
export const SizeFontLarge = 24 // px
export const SizeFontLargeRem = 1.5 // rem
export const SizeFontXlarge = 32 // px
export const SizeFontXlargeRem = 2 // rem
export const SizeLegacyFontSmall = 14 // the small size of the font in px
export const SizeLegacyFontMedium = 16 // the medium size of the font in px
export const SizeLegacyFontLarge = 24 // the large size of the font in px
export const SizeLegacyFontBase = 16 // the base size of the font in px
export const SizeLineHeightXsmall = 16 // px
export const SizeLineHeightSmall = 20 // px
export const SizeLineHeightMedium = 22 // px
export const SizeLineHeightLarge = 24 // px
export const SizeLineHeightXlarge = 28 // px
export const SizeLineHeightXsmallRem = 1 // px
export const SizeLineHeightSmallRem = 1.25 // px
export const SizeLineHeightMediumRem = 1.35 // px
export const SizeLineHeightLargeRem = 1.5 // px
export const SizeLineHeightXlargeRem = 1.75 // px
export const SizeSpacing07 = 32 // px
export const SizeSpacing07Rem = 2 // rem
export const SizeSpacing06 = 28 // px
export const SizeSpacing06Rem = 1.75 // rem
export const SizeSpacing05 = 24 // px
export const SizeSpacing05Rem = 1.5 // rem
export const SizeSpacing04 = 20 // px
export const SizeSpacing04Rem = 1.25 // rem
export const SizeSpacing03 = 16 // px
export const SizeSpacing03Rem = 1 // rem
export const SizeSpacing02 = 12 // px
export const SizeSpacing02Rem = 0.75 // rem
export const SizeSpacing01 = 8 // px
export const SizeSpacing01Rem = 0.5 // rem
export const FontWeightSemibold = '600'
export const FontWeightRegular = '400'
export const FontRegular = 'MyriadProRegular'
export const FontSemibold = 'MyriadProSemibold'
