import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: inline-block;
  margin-bottom: 1.2rem;
  width: 100%;
`

const Title = styled.div`
  font-size: 14px;
  margin-bottom: 3px;
  color: #bdbdbd;
`

export const Field = ({ title, children }) => (
  <Container>
    <Title>{title}</Title>
    {children}
  </Container>
)
