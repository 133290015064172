import React, { useState } from 'react'
import { FormProvider } from 'providers/FormProvider'
import { Icon, Button } from 'components/atoms'
import { Modal } from 'components/molecules'
import { AddScannedSystemForm } from './AddScannedSystemForm'
import { FormattedMessage } from 'react-intl'

export const AddScannedSystemModal = ({ refresh, siteId }) => {
    const [isOpen, onChange] = useState(false)
    return (
        <>
            <Button
                data-test-id="add-system-button"
                buttonType="primary"
                onClick={() => onChange(true)}
            >
                <Icon type="plus" /> <FormattedMessage id={'modal.add.button'} />
            </Button>
            <Modal
                title={<FormattedMessage id={'add.scanned.system.modal.title'} />}
                isOpen={isOpen}
                onRequestClose={() => {
                    onChange(false)
                }}
            >
                {isOpen && (
                    <FormProvider
                        onSuccess={() => {
                            refresh()
                            onChange(false)
                        }}
                        url={`/v1/sites/${siteId}/scanned-systems`}
                        render={({ updating, handleSubmit }) => (
                            <AddScannedSystemForm
                                onSubmit={values => {
                                    handleSubmit({ ...values, siteId })
                                }}
                                onCancel={() => {
                                    onChange(false)
                                }}
                                loading={updating}
                                siteId={siteId}
                            />
                        )}
                    />
                )}
            </Modal>
        </>
    )
}
