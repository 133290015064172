import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ColorAlmostBlack, ColorMisty } from 'style/design-tokens'

export const Input = styled.input`
  padding: 0 0.5rem;
  font-size: 16px;
  color: ${ColorAlmostBlack};
  border-radius: 1px;
  width: 100%;
  max-width: 500px;
  outline: none;
  border: none;
  background-color: ${ColorMisty};
  height: 38px;
`

Input.propTypes = {
  'data-test-id': PropTypes.string.isRequired,
}
