import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: row;
  margin-bottom: 0.4rem;
`

export const Tab = styled.div`
  padding: 12px 24px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  ${({ selected }) =>
    selected &&
    `
    text-decoration: underline;
  `}
`

export const Tabs = ({ children }) => {
  return <Container>{children}</Container>
}

Tabs.Tab = ({ children, onClick, selected, testId}) => (
  <Tab selected={selected} onClick={onClick} data-test-id={testId}>
    {children}
  </Tab>
)
