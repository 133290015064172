import React from 'react'
import { Header } from './Header'

export const Headers = ({
  columns,
  sortingParams,
  filteringParams,
  onSortChange,
  onFiltersChange,
}) => (
  <thead>
    <tr>
      {columns.map((column, index) => (
        <th key={index}>
          <Header
            id={index}
            column={column}
            sortable={column.sortable}
            searchable={column.searchable}
            checkable={column.checkable}
            sortingParams={sortingParams}
            filteringParams={filteringParams}
            onSortChange={onSortChange}
            onFiltersChange={onFiltersChange}
          >
            {column.title}
          </Header>
        </th>
      ))}
    </tr>
  </thead>
)
