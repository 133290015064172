import React, { useState } from 'react'
import { Link, Error, SizedBox, Row } from 'components/atoms'
import { MainLayout } from 'layout/MainLayout'
import { Table } from 'components/organisms'
import { formatDateTime } from 'utils/formatters'
import { omitUndefinedKeys } from 'utils/helpers'
import { withSystemEventsReports, withAvailableSystems } from 'api'
import { withRouter } from 'react-router-dom'
import { GenerateSystemEventReportForm } from './GenerateSystemEventReportForm'
import { FormattedMessage, useIntl } from 'react-intl'
import { DownloadSystemEventReport } from './DownloadSystemEventReport/DownloadSystemEventReport'

const columns = [
  {
    title: <FormattedMessage id="reports.headers.unit.name" />,
    key: 'unitName',
  },
  {
    title: <FormattedMessage id="reports.headers.unit.id" />,
    key: 'unitId',
  },
  {
    title: <FormattedMessage id="reports.headers.unit.class" />,
    key: 'unitClass',
  },
  {
    title: <FormattedMessage id="reports.headers.unit.hardware" />,
    key: 'hardwareTag',
  },
  {
    title: <FormattedMessage id="reports.headers.event.name" />,
    key: 'eventName',
  },
  {
    title: <FormattedMessage id="reports.headers.system.name" />,
    render: record => (
      <Link to={`/systems/${record.systemId}`}>{record.systemName}</Link>
    ),
    externalLink: true,
  },
  {
    title: <FormattedMessage id="reports.headers.timestamp" />,
    key: 'timestamp',
    render: record => formatDateTime(record.timestamp),
  },
  {
    title: <FormattedMessage id="reports.headers.orginal.message" />,
    key: 'orginalMessage',
  },
]

export const SystemEventReportsPage = withRouter(({ history }) => {
  const [formValues, setFormValues] = useState(null)
  const {
    data,
    isLoading,
    refresh,
    error,
    filters,
    onChange,
    sorter,
    pagination,
    ...rest
  } = withSystemEventsReports()
  const availableSystems = withAvailableSystems()
  const intl = useIntl()

  const serialize = data =>
    omitUndefinedKeys({
      systemId: data.systemId,
      beginDate: formatDateTime(data.startDate),
      endDate: formatDateTime(data.endDate),
    })

  const handleSubmit = values => {
    setFormValues({
      systemId: values.systemId,
      beginDate: formatDateTime(values.startDate),
      endDate: formatDateTime(values.endDate),
    })
    onChange(
      undefined,
      {
        ...serialize(values),
      },
      sorter,
    )
  }

  const refreshTable = () => {
    onChange(undefined, undefined, sorter)
  }

  const createSuggestion = element => ({
    id: element.id,
    value: element.name,
  })

  const prepareSuggestions = data => {
    let temp = []
    if (data) {
      temp = data.map(element => ({
        id: element.systemId,
        name: element.systemName,
      }))
    }
    return temp
  }

  return (
    <MainLayout
      header={intl.formatMessage({
        id: 'reports.header.system.events',
      })}
    >
      <SizedBox height="1" />
      <Row>
        <GenerateSystemEventReportForm
          onSubmit={values => {
            handleSubmit(values)
          }}
          data={prepareSuggestions(availableSystems.data)}
          createSuggestion={createSuggestion}
          refresh={refreshTable}
        />
        <SizedBox width="0.5" />
        <div>
          <SizedBox height="0.5" />
          <DownloadSystemEventReport formValues={formValues} />
        </div>
      </Row>
      <SizedBox height="1" />
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns}
        data={data}
        history={history}
        pagination={pagination}
        onChange={onChange}
        {...rest}
      />
    </MainLayout>
  )
})
