export const UserTypes = {
  Regular: 0,
  Super: 1,
  toLabel: userType => {
    switch (userType) {
      case UserTypes.Super:
        return 'Super'
      case UserTypes.Regular:
      default:
        return 'Regular'
    }
  },
  toLangKey: userType => {
    switch (userType) {
      case UserTypes.Super:
        return 'user.permissions.type.super'
      case UserTypes.Regular:
      default:
        return 'user.permissions.type.regular'
    }
  },
}
