import React, { useState } from 'react'
import styled from 'styled-components'
import { updateAccessLevel } from 'api'
import { showError } from 'layout/notifications'
import { displayError } from 'api/displayError'

const Select = styled.select`
  border: none;
  padding: 4px 8px;
  background: #121212;
  border-radius: 15px;
  font-size: 14px;
  color: white;
`

export const SelectAccessLevel = ({ type, id, accessLevel }) => {
  const [value, setValue] = useState(accessLevel)
  const handleSubmit = async e => {
    const prev = value
    setValue(e.target.value)
    const { error } = await updateAccessLevel(type, id, e.target.value)
    if (error) {
      showError(displayError(error))
      setValue(prev)
    }
  }
  return (
    <Select value={value} onChange={handleSubmit}>
      <option value={0}>Monitor Only</option>
      <option value={1}>System Operator</option>
      <option value={2}>Administrator</option>
    </Select>
  )
}
