import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { required } from 'redux-form-validators'
import { Button, Form, Center, Spacing } from 'components/atoms'
import { Input } from 'components/molecules'
import { FormattedMessage, useIntl } from 'react-intl'

const validate = values => {
  const errors = {}
  if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation =
      'Password and password confirmation must be the same'
  }
  if (!values.password || values.password.length < 8) {
    errors.password = 'Password must be at least 8 characters long'
  }
  return errors
}

const NewPasswordFormUnwrapped = ({ handleSubmit, loading, pristine }) => {
  const intl = useIntl()
  return (
    <Form.Container>
      <Form onSubmit={handleSubmit}>
        <Field
          component={Input}
          name="password"
          placeholder={intl.formatMessage({
            id: 'new.password.field.placeholder',
          })}
          type="password"
          validate={[required()]}
          label="Password"
          testId="new-password-form-input-password"
        />
        <Field
          component={Input}
          name="passwordConfirmation"
          placeholder={intl.formatMessage({
            id: 'new.password.confirmation.field.placeholder',
          })}
          type="password"
          validate={[required()]}
          label="Confirm Password"
          testId="new-password-form-input-passwordconfirmation"
        />
        <Spacing mt="3">
          <Center>
            <Button
              disabled={loading || pristine}
              type="submit"
              variant="primary"
              data-test-id="new-password-form-submit"
            >
              <FormattedMessage id="new.password.form.button.submit" />
              {loading ? '...' : ''}
            </Button>
          </Center>
        </Spacing>
      </Form>
    </Form.Container>
  )
}
NewPasswordFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export const NewPasswordForm = reduxForm({
  form: 'new-password-form',
  validate,
})(NewPasswordFormUnwrapped)
