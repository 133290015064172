import React from 'react'
import { withRouter } from 'react-router-dom'
import { AccessLevels } from 'utils/AccesLevels'
import { Space, Spacing, Error, Protected, IconButton, ConfirmationModal, TextWrapper } from 'components/atoms'
import { Table } from 'components/organisms'
import { withSiteScannedSystems, deleteScannedSystem } from 'api'
import { AddScannedSystemModal } from './AddScannedSystemModal/AddScannedSystemModal'
import { formatDateTime } from 'utils/formatters'
import { FormattedMessage, useIntl } from 'react-intl'


export const SiteScannedSystems = withRouter(({ siteId, history }) => {
    const { data, isLoading, error, refresh, ...rest } = withSiteScannedSystems(siteId)()
    let intl = useIntl()
    const columns = [
        {
            title: <FormattedMessage id={'scanned.systems.list.header.name'} />,
            key: 'name',
            render: record => <TextWrapper>{record.name}</TextWrapper>
        },
        {
            title: <FormattedMessage id={'scanned.systems.list.header.loops.count'} />,
            key: 'loopsCount',
        },
        {
            title: <FormattedMessage id={'scanned.systems.list.header.created.at'} />,
            render: record => formatDateTime(record.createdAt),
        },
        {
            title: <FormattedMessage id="table.header.actions" />,
            notClickable: true,
            render: record => (
                <Protected
                    resourceId={siteId}
                    accessLevel={AccessLevels.Admin}
                    renderWithoutPermission={<>-</>}
                >
                    <IconButton
                        type="trash"
                        onClick={() => {
                            ConfirmationModal.show({
                                title: intl.formatMessage({
                                    id: 'modal.confirmation.default',
                                }),
                                text: intl.formatMessage({
                                    id: 'modal.delete.scanned.system.confirmation',
                                }),
                                onSubmit: async () => {
                                    const { error } = await deleteScannedSystem(
                                        record.id,
                                    )
                                    if (!error) {
                                        refresh()
                                    } else {
                                        throw error
                                    }
                                },
                            })
                        }}
                    />
                </Protected>
            ),
        },
    ]

    return (
        <>
            {error && <Error error={error} />}
            <Table
                isLoading={isLoading}
                columns={columns}
                data={data}
                history={history}
                linkPath={'/scanned-systems'}
                {...rest}
            />
            <Protected resourceId={siteId} accessLevel={AccessLevels.Admin}>
                <Spacing mt="1" width="100%">
                    <Space right>
                        <AddScannedSystemModal siteId={siteId} refresh={refresh} />
                    </Space>
                </Spacing>
            </Protected>
        </>
    )
})
