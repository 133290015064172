import React from 'react'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import { MainLayout } from 'layout/MainLayout'
import { Table } from 'components/organisms'
import {
  Error,
  SizedBox,
  Link,
  Space,
  Spacing,
  Address,
  TextWrapper,
} from 'components/atoms'
import { formatDateTime } from 'utils/formatters'
import { withUserSites } from 'api'
import { connect } from 'react-redux'
import { updateInfo } from 'rdx/actions/authActions'
import { AddSiteModal } from './AddSiteModal/AddSiteModal'
import { withUserUpdater } from 'api'
import { AccessLevels } from 'utils/AccesLevels'
import { Protected } from 'components/atoms/Protected/Protected'

const columns = [
  {
    title: 'Name',
    key: 'name',
    render: record => <TextWrapper>{record.name}</TextWrapper>,
  },
  {
    title: 'Number',
    key: 'number',
  },
  {
    title: 'Address',
    render: record => <Address address={record.address} />,
  },
  {
    title: 'Systems',
    render: record => `${record.systemIds.length}`,
  },
  {
    title: 'Created at',
    render: record => `${formatDateTime(record.createdAt)}`,
  },
  {
    title: 'Created by',
    render: record => (
      <Link to={`/users/${record.createdById}`}>
        {record.createdBy.emailAddress}
      </Link>
    ),
    externalLink: true,
  },
]

export const SitesList = compose(
  withRouter,
  connect(
    state => ({
      userId: state.authentication.user.id,
    }),
    { updateInfo },
  ),
)(({ userId, history, updateInfo }) => {
  withUserUpdater(updateInfo)

  const {
    data,
    isLoading,
    error,
    onChange,
    pagination,
    refresh,
    ...rest
  } = withUserSites(userId)()

  return (
    <MainLayout header="Sites">
      <SizedBox height="1" />
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns}
        data={data}
        linkPath={'/sites'}
        pagination={pagination}
        onChange={onChange}
        history={history}
        {...rest}
      />
      <Protected accessLevel={AccessLevels.Admin}>
        <Spacing mt="1" width="100%">
          <Space right>
            <AddSiteModal refresh={refresh} />
          </Space>
        </Spacing>
      </Protected>
    </MainLayout>
  )
})
