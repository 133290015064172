import React from 'react'
import { Row, Spacing } from 'components/atoms'
import { MainLayout } from 'layout/MainLayout'
import { withInvitation } from 'api'
import { withRouter } from 'react-router'
import { RequestButton } from '../../components/atoms/RequestButton/RequestButton'
import { FormattedMessage, useIntl } from 'react-intl'
import { showError } from '../../layout/notifications'
import { Typography } from '../../components/atoms/Typography/Typography'
import { Center } from '../../components/atoms/Center/Center'
import { ColorCharcoalGrey } from 'style/design-tokens'
import styled from 'styled-components'

const InvitationInfo = styled.div`
  border: 1px solid ${ColorCharcoalGrey};
  width: 60%;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  padding: 4rem 5rem;
`

export const Invitation = withRouter(({ match, history }) => {
  const intl = useIntl()
  const invitationId = match.params.id
  sessionStorage.setItem('invitation_id', invitationId)

  const authToken = localStorage.getItem('auth_token')
  if (!authToken || authToken.length === 0) {
    history.push('/login')
  }

  const { isInitialLoading, pick, error } = withInvitation(match.params.id)

  const clearInvitationId = () => {
    sessionStorage.removeItem('invitation_id')
  }

  if (!error) {
    return (
      <MainLayout isLoading={isInitialLoading}>
        <InvitationInfo>
          <Spacing mt="1" width="100%">
            {pick(['siteName']) ? (
              <>
                <Center>
                  <Typography.H2 style={{ opacity: '0.6' }}>
                    <FormattedMessage id="header.user.invitation.site" />
                  </Typography.H2>
                </Center>
                <Center>
                  <Typography.H1>{pick(['siteName'])}</Typography.H1>
                </Center>
              </>
            ) : (
              <>
                <Center>
                  <Typography.H2>
                    <FormattedMessage id="header.user.invitation.system" />
                  </Typography.H2>
                </Center>
                <Center>
                  <Typography.H1>{pick(['systemName'])}</Typography.H1>
                </Center>
              </>
            )}
            <Spacing mb="4"></Spacing>
            <Row>
              <Center>
                <RequestButton
                  style={{ width: '30%' }}
                  url={`v1/invitations/${invitationId}/reject`}
                  method="post"
                  onSuccess={() => {
                    clearInvitationId()
                    history.push('/')
                  }}
                  buttonType="secondary"
                  testId="reject-user-invitation-button"
                >
                  <FormattedMessage id="button.user.ivitation.reject" />
                </RequestButton>
                <Spacing mr="1" width="15%"></Spacing>
                <RequestButton
                  style={{ width: '30%' }}
                  url={`v1/invitations/${invitationId}/accept`}
                  method="post"
                  onSuccess={() => {
                    clearInvitationId()
                    history.push('/')
                  }}
                  testId="accept-user-invitation-button"
                >
                  <FormattedMessage id="button.user.ivitation.accept" />
                </RequestButton>
              </Center>
            </Row>
          </Spacing>
        </InvitationInfo>
      </MainLayout>
    )
  }

  return (
    <MainLayout>
      {showError(
        intl.formatMessage({
          id: error,
        }),
      )}
    </MainLayout>
  )
})
