import React from 'react'
import styled from 'styled-components'
import { Icon, Row, Spacing, SizedBox, Checkbox } from 'components/atoms'
import {
  ColorWhite,
  FontWeightSemibold,
  SizeFontSmall,
} from 'style/design-tokens'
import { InputFilter } from 'components/organisms/Table/Filter/InputFilter'

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  padding: 0.7rem 0.6rem 0.3rem 0.6rem;
  width: 100%;
  ${({ align }) => {
    switch (align) {
      case 'right':
        return 'justify-content: flex-end;'
      default:
        return ''
    }
  }}
`

const SortButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const SortButton = styled.div`
  display: flex;
  align-items: center;
  color: ${ColorWhite};
  font-size: ${SizeFontSmall}px;
  font-weight: ${FontWeightSemibold};
  height: 15px;
  opacity: ${({ isActive }) => (isActive ? '1' : '0.5')};

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`

export const Header = ({
  children,
  column,
  sortable,
  checkable,
  searchable,
  sortingParams,
  filteringParams,
  onSortChange,
  onFiltersChange,
}) => {
  const isSortingActive = sortingParams?.orderBy === column.key
  const isAscending = sortingParams?.order === 'ASC'

  return (
    <HeaderContainer isActive={isSortingActive} align={column.align}>
      <Row justifyContent="space-between" isFilter={sortable || checkable}>
        {children}

        {sortable && (
          <>
            <Spacing mr={1} />
            <SortButtonsContainer>
              <SortButton
                isActive={isSortingActive && isAscending}
                data-test-id="sort-up-arrow"
                onClick={() => {
                  if (sortable) {
                    onSortChange({
                      orderBy: column.key,
                      order: 'ASC',
                    })
                  }
                }}
              >
                <Icon type="angleUp" />
              </SortButton>
              <SortButton
                isActive={isSortingActive && !isAscending}
                data-test-id="sort-down-arrow"
                onClick={() => {
                  if (sortable) {
                    onSortChange({
                      orderBy: column.key,
                      order: 'DESC',
                    })
                  }
                }}
              >
                <Icon type="angleDown" />
              </SortButton>
            </SortButtonsContainer>
          </>
        )}
      </Row>

      {searchable && (
        <>
          <SizedBox height="0.5" />
          <InputFilter
            onFiltersChange={onFiltersChange}
            filteringParams={filteringParams}
            field={column.key}
          />
        </>
      )}
      {checkable && (
        <>
          <SizedBox height="0.5" />
          <Checkbox
            dark
            onFiltersChange={onFiltersChange}
            filteringParams={filteringParams}
            field={column.key}
          />
        </>
      )}
    </HeaderContainer>
  )
}
