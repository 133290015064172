import { pathOr } from 'ramda'
import { useState, useEffect } from 'react'
import { displayError } from './displayError'
import { request } from './request'

export const withSingleResource = (url, normalizer = val => val) => id => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [errorStatus, setErrorStatus] = useState(null)
  const [data, setData] = useState(null)
  const [refreshCount, setRefreshCount] = useState(0)
  let onRefresh
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const response = await request.get(id ? `${url}/${id}` : url)
        setData(normalizer(response.data.data))
        if (onRefresh) {
          onRefresh()
        }
      } catch (err) {
        if (err.response) {
          setError(displayError(err))
          setErrorStatus(err.response.status)
        }
      }
      setIsLoading(false)
    }
    fetchData()
  }, [id, refreshCount, onRefresh])

  return {
    isLoading,
    isInitialLoading: isLoading && !data,
    error,
    errorStatus,
    data,
    refresh: callback => {
      onRefresh = callback
      setRefreshCount(refreshCount + 1)
    },
    pick: (path, or = '') => pathOr(or, path, data),
  }
}
