import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { MainLayout } from 'layout/MainLayout'
import { withRouter } from 'react-router-dom'
import {
  Field,
  UserStatus,
  Spacing,
  SizedBox,
  Divider,
  Row,
} from 'components/atoms'
import { Tabs } from 'components/molecules/Tabs/Tabs'
import { UserSites } from './UserSites/UserSites'
import { UserSystems } from './UserSystems/UserSystems'
import { EditUser } from './EditUser/EditUser'
import { UserModems } from './UserModems/UserModems'
import { Permissions } from './Permissions/Permissions'
import { UserLogs } from './UserLogs/UserLogs'
import { withUser } from 'api'
import { FormattedMessage } from 'react-intl'
import { AccessLevels } from 'utils/AccesLevels'
import { UserTypes } from 'utils/UserTypes'

const UserTabs = {
  Sites: '0',
  Systems: '1',
  Modems: '2',
  Edit: '3',
  Permissions: '4',
  Logs: '5',
}

export const UserPage = connect(state => ({
  user: state.authentication.user,
}))(
  withRouter(({ match, user }) => {
    const {
      isInitialLoading,
      pick,
      refresh,
      isLoading,
      errorStatus,
    } = withUser(match.params.id)
    const tab = (window.location.hash || UserTabs.Sites).replace('#', '')
    const setIndex = value => (window.location.hash = value)
    if (errorStatus) {
      return <Redirect to={`/${errorStatus}`} />
    }
    return (
      <MainLayout
        isLoading={isInitialLoading}
        header={
          <>
            {`${pick(['firstName'])} ${pick(['lastName'])}`}
            <Spacing.Span ml="1">
              <UserStatus status={pick(['status'])} />
            </Spacing.Span>
          </>
        }
      >
        <SizedBox height="2" />
        <Divider />
        <SizedBox height="1" />
        <Row>
          <Field title="E-mail">{pick(['emailAddress'])}</Field>
          <Field title="Telephone">{pick(['phoneNumber'])}</Field>
          {user.permissions.isSuperUser ?? false ? (
            <Field title="Permissions">
              {pick(['permissions', 'isSuperUser']) ? (
                <FormattedMessage id={UserTypes.toLangKey(UserTypes.Super)} />
              ) : (
                <FormattedMessage id={UserTypes.toLangKey(UserTypes.Regular)} />
              )}
              {' - '}
              <FormattedMessage
                id={AccessLevels.toLangKey(
                  pick(['permissions', 'globalAccessLevel']),
                )}
              />
            </Field>
          ) : (
            <></>
          )}
        </Row>
        <Divider />
        <SizedBox height="3" />
        <Tabs>
          <Tabs.Tab
            onClick={() => setIndex(UserTabs.Sites)}
            selected={tab === UserTabs.Sites}
            testId="sites-tab"
          >
            Sites
          </Tabs.Tab>
          <Tabs.Tab
            onClick={() => setIndex(UserTabs.Systems)}
            selected={tab === UserTabs.Systems}
            testId="systems-tab"
          >
            Systems
          </Tabs.Tab>
          <Tabs.Tab
            onClick={() => setIndex(UserTabs.Modems)}
            selected={tab === UserTabs.Modems}
            testId="modems-tab"
          >
            Modems
          </Tabs.Tab>
          {user.permissions.isSuperUser &&
            user.permissions.globalAccessLevel === AccessLevels.Admin &&
            user.id !== match.params.id && (
              <>
                <Tabs.Tab
                  onClick={() => setIndex(UserTabs.Edit)}
                  selected={tab === UserTabs.Edit}
                  testId="edit-tab"
                >
                  Edit
                </Tabs.Tab>
                <Tabs.Tab
                  onClick={() => setIndex(UserTabs.Permissions)}
                  selected={tab === UserTabs.Permissions}
                  testId="permissions-tab"
                >
                  Permissions
                </Tabs.Tab>
              </>
            )}
        </Tabs>
        {tab === UserTabs.Sites && <UserSites userId={match.params.id} />}
        {tab === UserTabs.Systems && <UserSystems userId={match.params.id} />}
        {tab === UserTabs.Modems && <UserModems userId={match.params.id} />}
        {tab === UserTabs.Edit && !isLoading && (
          <EditUser userId={match.params.id} pick={pick} refresh={refresh} />
        )}
        {tab === UserTabs.Permissions && !isLoading && (
          <Permissions userId={match.params.id} pick={pick} refresh={refresh} />
        )}
        {tab === UserTabs.Logs && <UserLogs />}
      </MainLayout>
    )
  }),
)
