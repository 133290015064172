import { isValid, format } from 'date-fns'

export const formatDate = dateString => {
  const date = new Date(dateString)
  if (!isValid(date)) {
    return '-'
  }
  return format(date, 'yyyy-MM-dd')
}

export const formatDateTime = dateString => {
  const date = new Date(dateString)
  if (!isValid(date)) {
    return '-'
  }
  return format(date, 'yyyy-MM-dd HH:mm')
}

export const formatTime = dateString => {
  const date = new Date(dateString)
  if (!isValid(date)) {
    return '-'
  }
  return format(date, 'HH:mm')
}

export const capitalize = (string = '') =>
  (string[0] || '').toUpperCase() + string.slice(1).toLowerCase()
