import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { Button, Form, Center, Spacing } from 'components/atoms'
import { Input } from 'components/molecules'
import { FormattedMessage } from 'react-intl'
import { required, email } from 'redux-form-validators'

const ForgotPasswordFormUnwrapped = ({
  handleSubmit,
  loading,
  pristine,
  invalid,
  history,
}) => {
  return (
    <Form.Container>
      <Form onSubmit={handleSubmit}>
        <Form.Item>
          <Form.Label htmlFor="emailAddress">
            <FormattedMessage id="form.emailAddress" />
          </Form.Label>
          <Field
            component={Input}
            name="emailAddress"
            type="text"
            validate={[required(), email()]}
            testId="forgot-password-form_email-address"
          />
        </Form.Item>
        <Spacing mt="3">
          <Center>
            <Button
              data-test-id="forgot-password-form_back"
              onClick={() => {
                history.push('/login')
              }}
            >
              <FormattedMessage id="button.back" />
            </Button>
            <Spacing mr={2} />
            <Button
              type="submit"
              disabled={pristine || invalid || loading}
              data-test-id="forgot-password-form_submit"
            >
              <FormattedMessage id="button.submit" /> {loading ? '...' : ''}
            </Button>
          </Center>
        </Spacing>
      </Form>
    </Form.Container>
  )
}

ForgotPasswordFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export const ForgotPasswordForm = reduxForm({
  form: 'forgot-password-form',
})(ForgotPasswordFormUnwrapped)
