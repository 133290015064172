import React from 'react'
import { FormProvider } from 'providers/FormProvider'
import { Modal } from 'components/molecules'
import { EditUnitForm } from './EditUnitForm'
import { withRouter } from 'react-router'
import { FormattedMessage } from 'react-intl'

export const EditUnitModal = withRouter(
  ({ isOpen, onRequestClose, refresh, unit }) => {
    const getInitialValues = data => {
      if (data) {
        return {
          tagName: data.tagName,
          detectionZone: data.detectionZone,
          lsiNumber: data.lsiNumber,
          unitType: data.unitType,
          serialNumber: data.serialNumber,
          scannedLoopName: data.scannedLoopName,
          scannedLoopId: data.scannedLoopId,
          comment: data.comment,
        }
      } else return {}
    }

    return (
      <Modal
        title={<FormattedMessage id={'edit.unit.modal.title'} />}
        isOpen={isOpen}
        onRequestClose={() => {
          onRequestClose()
        }}
      >
        {isOpen && (
          <FormProvider
            onSuccess={() => {
              refresh()
              onRequestClose()
            }}
            method="put"
            url={`/v1/scanned-units/${unit.id}`}
            render={({ updating, handleSubmit }) => (
              <EditUnitForm
                onSubmit={handleSubmit}
                initialValues={getInitialValues(unit)}
                onCancel={() => {
                  onRequestClose()
                }}
                loading={updating}
              />
            )}
          />
        )}
      </Modal>
    )
  },
)
