import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field, reduxForm } from 'redux-form'
import { required } from 'redux-form-validators'
import { Form, Button, SizedBox } from 'components/atoms'
import { Textarea, FileInput } from 'components/molecules'

const UploadSystemConfigurationFormUnwrapped = ({
  handleSubmit,
  loading,
  pristine,
  invalid,
  onCancel,
}) => (
  <Form
    onSubmit={handleSubmit}
    description={
      <FormattedMessage id="systems.configurations.upload.form.description" />
    }
  >
    <SizedBox height="1" />
    <Form.Item width="20%">
      <Form.Label>
        <FormattedMessage id="form.file" />
      </Form.Label>
      <Field
        type="file"
        accept="text/xml"
        name="configuration"
        component={FileInput}
        validate={[required()]}
        data-test-id="system-upload-configuration-form-configuration-file-input"
      />
    </Form.Item>
    <Form.Item width="100%">
      <Form.Label>
        <FormattedMessage id="form.comment" />
      </Form.Label>
      <Field
        component={Textarea}
        name="comment"
        validate={[required()]}
        testId="form.comment"
      />
    </Form.Item>
    <Form.Buttons>
      <Button
        disabled={loading}
        buttonType="secondary"
        onClick={onCancel}
        data-test-id="upload-system-configuration-form_cancel"
      >
        <FormattedMessage id="button.cancel" />
      </Button>
      <Button
        disabled={loading || pristine || invalid}
        isLoading={loading}
        type="submit"
        buttonType="primary"
        isActive
        data-test-id="upload-system-configuration-form-submit"
      >
        <FormattedMessage id="button.save" />
      </Button>
    </Form.Buttons>
  </Form>
)

UploadSystemConfigurationFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export const UploadSystemConfigurationForm = reduxForm({
  form: 'upload-system-configuration-form',
})(UploadSystemConfigurationFormUnwrapped)
