import React from 'react'
import styled from 'styled-components'
import { Spin } from 'components/atoms'
import { Navbar } from './Navbar/Navbar'
import { ColorAlmostBlack, ColorWhite } from 'style/design-tokens'
import { Logo } from './Navbar/Logo'
import { Link } from 'react-router-dom'
import { TextWrapper } from '../components/atoms/TextWrapper/TextWrapper'

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: ${ColorAlmostBlack};
  color: ${ColorWhite};
`

const Content = styled.section`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 3rem 1.6rem;
  width: 90vw;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > h1 {
    margin-bottom: 2rem;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    line-height: 12px;
  }
`

export const MainLayout = ({ children, header, isLoading, extra }) => (
  <Container>
    <Navbar />
    <Content>
      <Header>
        <TextWrapper ifDetailsPage width={'80vw'}>
          <h1> {header} </h1>
        </TextWrapper>

        {extra && <div>{extra}</div>}
      </Header>
      {isLoading && <Spin />}
      {children}
    </Content>
  </Container>
)

const FullPageScreenContainer = styled.div`
  background-color: ${ColorWhite};
  border-radius: 0rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 8rem 1.6rem 3rem 1.6rem;
  align-items: center;
  justify-content: center;
  > * {
    color: ${ColorAlmostBlack};
  }
  & img {
    position: absolute;
    left: 1.6rem;
    top: 1.6rem;
  }
`

MainLayout.FullPageScreen = ({ children, logo }) => (
  <FullPageScreenContainer>
    <Link to="/">{logo === false ? null : <Logo />}</Link>
    {children}
  </FullPageScreenContainer>
)
