import React from 'react'
import { Link, SizedBox, Row, Error } from 'components/atoms'
import { MainLayout } from 'layout/MainLayout'
import { Table } from 'components/organisms'
import { withRouter } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { InstallBaseReportStatistics } from './InstallBaseReportStatistics'
import { formatDate, formatTime, formatDateTime } from 'utils/formatters'
import { withInstalledSystems } from 'api'
import { UploadInstalBaseReportModal } from './UploadInstalBaseReportModal/UploadInstalBaseReportModal'

const columns = [
  {
    title: <FormattedMessage id="reports.headers.eq.number" />,
    key: 'equipmentNumber',
    render: record => (
      <Link to={`/systems/${record.systemId}`}>
        {record.equipmentNumber ? record.equipmentNumber : '-'}
      </Link>
    ),
    externalLink: true,
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.customer.number" />,
    key: 'customerNumber',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.site.number" />,
    key: 'siteNumber',
    externalLink: true,
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.site.name" />,
    key: 'siteName',
    render: record => (
      <Link to={`/sites/${record.siteId}`}>{record.siteName}</Link>
    ),
    externalLink: true,
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.facility.name" />,
    key: 'systemName',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.sys" />,
    key: 'panelType',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.ac" />,
    key: 'activeContract',
    sortable: true,
    checkable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.business.unit" />,
    key: 'businessUnit',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.created.date" />,
    key: 'entryCreatedDate',
    render: record =>
      record.entryCreatedDate
        ? formatDate(record.entryCreatedDate) +
          '\n' +
          formatTime(record.entryCreatedDate)
        : '-',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.address" />,
    key: 'address',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.postal.code" />,
    key: 'postalCode',
    sortable: true,
    searchable: true,
  },
  {
    title: <FormattedMessage id="reports.headers.city" />,
    key: 'city',
    sortable: true,
    searchable: true,
  },
]

export const InstallBaseReportPage = withRouter(({ history }) => {
  const {
    data,
    isLoading,
    refresh,
    error,
    filters,
    onChange,
    sorter,
    pagination,
    meta,
    ...rest
  } = withInstalledSystems()
  const intl = useIntl()

  return (
    <MainLayout
      header={intl.formatMessage({
        id: 'reports.header.install.base',
      })}
    >
      <Row>
        {meta.lastUpdatedById && (
          <Row width="30rem" height="38px">
            <FormattedMessage id="reports.install.base.last.imported.at" />{' '}
            {formatDateTime(meta.lastUpdatedAt)}{' '}
            <FormattedMessage id="reports.install.base.last.imported.by" />
            <SizedBox />
            <Link to={`/users/${meta.lastUpdatedById}`}>
              {meta.lastUpdatedBy ? meta.lastUpdatedBy : '-'}
            </Link>
          </Row>
        )}
        <UploadInstalBaseReportModal refresh={refresh} />
      </Row>
      <SizedBox height="1" />
      <InstallBaseReportStatistics meta={meta} />
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns}
        data={data}
        history={history}
        sortingParams={sorter}
        filteringParams={filters}
        pagination={pagination}
        onChange={onChange}
        refresh={refresh}
        hasIterator
        {...rest}
      />
    </MainLayout>
  )
})
