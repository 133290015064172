import styled from 'styled-components'

export const TextWrapper = styled.div`
  max-width: ${({ width }) => width || '15rem'};
  ${({ ifDetailsPage }) =>
    ifDetailsPage
      ? 'overflow-wrap: break-word;'
      : `text-overflow: ellipsis;
overflow: hidden;
`}
`