import { connect } from 'react-redux'
import { AccessLevels } from 'utils/AccesLevels'

export const Protected = connect(state => ({
  user: state.authentication.user,
}))(
  ({
    user,
    children,
    resourceId,
    globalAccessLevel,
    accessLevel,
    renderWithoutPermission,
    userType,
  }) => {
    if (
      (user.permissions.isSuperUser && globalAccessLevel === undefined) ||
      (user.permissions.isSuperUser &&
        user.permissions.globalAccessLevel === globalAccessLevel &&
        user.permissions.userType === userType) ||
      (user.permissions.globalAccessLevel === AccessLevels.Admin &&
        user.permissions.resources[resourceId] !== undefined &&
        user.permissions.resources[resourceId] >= accessLevel)
    ) {
      return children
    } else {
      if (renderWithoutPermission) {
        return renderWithoutPermission
      } else {
        return null
      }
    }
  },
)
