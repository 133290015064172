import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { required, format } from 'redux-form-validators'
import { connect } from 'react-redux'
import { Form, Button, SizedBox } from 'components/atoms'
import { Input } from 'components/molecules'
import { compose } from 'recompose'

const AddSiteFormUnwrapped = ({
  handleSubmit,
  loading,
  pristine,
  city,
  postalCode,
  siteNumber,
  country,
  invalid,
  onCancel,
}) => (
  <Form
    onSubmit={handleSubmit}
    description={<FormattedMessage id="add.site.form.description" />}
  >
    <SizedBox height="1" />
    <Form.Item width="48%">
      <Form.Label>
        <FormattedMessage id="add.site.form.name" />
      </Form.Label>
      <Field
        component={Input}
        name="name"
        validate={[required()]}
        testId="add-site-form-name-input"
      />
    </Form.Item>
    <Form.Item width="48%">
      <Form.Label>
        <FormattedMessage id="add.site.form.number" />
      </Form.Label>
      <Field
        component={Input}
        name="siteNumber"
        validate={[
          format({
            with: siteNumber ? siteNumber.length > 100 : false,
            message:
              'Site number is too long (should be at max 100 characters)',
          }),
        ]}
        testId="add-site-form-number-input"
      />
    </Form.Item>
    <Form.Item width="48%">
      <Form.Label>
        <FormattedMessage id="add.site.form.description" />
      </Form.Label>
      <Field
        component={Input}
        name="description"
        testId="add-site-form-description-input"
      />
    </Form.Item>
    <Form.Row>
      <Form.Item width="48%">
        <Form.Label>
          <FormattedMessage id="add.site.form.address1" />
        </Form.Label>
        <Field
          component={Input}
          name="addressLine1"
          validate={[required()]}
          testId="add-site-form-address1-input"
        />
      </Form.Item>
      <Form.Item width="48%">
        <Form.Label>
          <FormattedMessage id="add.site.form.address2" />
        </Form.Label>
        <Field
          component={Input}
          name="addressLine2"
          testId="add-site-form-address2-input"
        />
      </Form.Item>
    </Form.Row>
    <Form.Row>
      <Form.Item width="31%">
        <Form.Label>
          <FormattedMessage id="add.site.form.postalcode" />
        </Form.Label>
        <Field
          component={Input}
          name="postalCode"
          validate={[
            required(),
            format({
              with: postalCode ? postalCode.length < 2 : true,
              message:
                'Postal code is too short (should be at least 2 characters)',
            }),
          ]}
          testId="add-site-form-postalcode-input"
        />
      </Form.Item>
      <Form.Item width="31%">
        <Form.Label>
          <FormattedMessage id="add.site.form.city" />
        </Form.Label>
        <Field
          component={Input}
          name="city"
          validate={[
            required(),
            format({
              with: city ? city.length < 2 : true,
              message: 'City is too short (should be at least 2 characters)',
            }),
          ]}
          testId="add-site-form-city-input"
        />
      </Form.Item>
      <Form.Item width="31%">
        <Form.Label>
          <FormattedMessage id="add.site.form.country" />
        </Form.Label>
        <Field
          component={Input}
          name="country"
          validate={[
            required(),
            format({
              with: country ? country.length < 2 : true,
              message: 'Country is too short (should be at least 2 characters)',
            }),
          ]}
          testId="add-site-form-country-input"
        />
      </Form.Item>
    </Form.Row>
    <Form.Buttons>
      <Button
        buttonType="secondary"
        onClick={onCancel}
        data-test-id="cancel-button"
      >
        <FormattedMessage id="button.cancel" />
      </Button>
      <Button
        type="submit"
        disabled={pristine || invalid || loading}
        data-test-id="add-site-form-submit"
      >
        <FormattedMessage id="add.site.form.submit" /> {loading ? '...' : ''}
      </Button>
    </Form.Buttons>
  </Form>
)

AddSiteFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

const fieldSelector = formValueSelector('add-site-form')

export const AddSiteForm = compose(
  reduxForm({
    form: 'add-site-form',
  }),
  connect(state => ({
    postalCode: fieldSelector(state, 'postalCode'),
    city: fieldSelector(state, 'city'),
    country: fieldSelector(state, 'country'),
    siteNumber: fieldSelector(state, 'siteNumber'),
  })),
)(AddSiteFormUnwrapped)
