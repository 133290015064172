import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field, reduxForm } from 'redux-form'
import { required } from 'redux-form-validators'
import { Form, Button, SizedBox } from 'components/atoms'
import { Input } from 'components/molecules'

const AddModemFormUnwrapped = ({
  handleSubmit,
  loading,
  pristine,
  invalid,
  onCancel,
}) => (
  <Form
    onSubmit={handleSubmit}
    description={<FormattedMessage id="sites.systems.add.form.description" />}
  >
    <SizedBox height="1" />
    <Form.Item width="90%">
      <Form.Label>
        <FormattedMessage id="form.modem.clientId" />
      </Form.Label>
      <Field
        component={Input}
        name="clientId"
        validate={[required()]}
        testId="site-modem-add-form-clientId-input"
      />
    </Form.Item>
    <Form.Item width="90%">
      <Form.Label>
        <FormattedMessage id="form.modem.iccid" />
      </Form.Label>
      <Field
        component={Input}
        name="iccid"
        validate={[required()]}
        testId="site-modem-add-form-iccid-input"
      />
    </Form.Item>
    <Form.Item width="90%">
      <Form.Label>
        <FormattedMessage id="form.modem.imei" />
      </Form.Label>
      <Field
        component={Input}
        name="imei"
        validate={[required()]}
        testId="site-modem-add-form-imei-input"
      />
    </Form.Item>
    <Form.Item width="90%">
      <Form.Label>
        <FormattedMessage id="form.modem.imsi" />
      </Form.Label>
      <Field
        component={Input}
        name="imsi"
        validate={[required()]}
        testId="site-modem-add-form-imsi-input"
      />
    </Form.Item>
    <Form.Item width="90%">
      <Form.Label>
        <FormattedMessage id="form.modem.wgip" />
      </Form.Label>
      <Field
        component={Input}
        name="wgip"
        validate={[required()]}
        testId="site-modem-add-form-wgip-input"
      />
    </Form.Item>
    <Form.Item width="90%">
      <Form.Label>
        <FormattedMessage id="form.modem.ts" />
      </Form.Label>
      <Field
        component={Input}
        name="ts"
        validate={[required()]}
        testId="site-modem-add-form-ts-input"
      />
    </Form.Item>
    <Form.Buttons>
      <Button
        buttonType="secondary"
        onClick={onCancel}
        data-test-id="cancel-button"
      >
        <FormattedMessage id="button.cancel" />
      </Button>
      <Button
        type="submit"
        disabled={pristine || invalid || loading}
        data-test-id="site-modem-add-form-submit"
      >
        <FormattedMessage id="site.details.modem.add" /> {loading ? '...' : ''}
      </Button>
    </Form.Buttons>
  </Form>
)

AddModemFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export const AddModemForm = reduxForm({
  form: 'add-site-modem-form',
})(AddModemFormUnwrapped)
