/* global L */
import React, { useEffect, useRef } from 'react'

export const ModemLocationMap = ({ lat, lng }) => {
  const mapRef = useRef(null)

  useEffect(() => {
    const map = L.map(mapRef.current).setView([lat, lng], 6)
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
    }).addTo(map)
    L.marker([lat, lng]).addTo(map)
  }, [lat, lng])

  return <div style={{ height: '600px', width: '100%' }} ref={mapRef}></div>
}
