import React from 'react'
import { withRouter } from 'react-router-dom'
import { Tag, Error, Link, CircleStatusIndicator } from 'components/atoms'
import { Table } from 'components/organisms'
import { withSiteSystems } from 'api'
import { formatDateTime } from 'utils/formatters'
import { ColorAckGreen, ColorFireRed, ColorSage } from 'style/design-tokens'
import { ModemSystemState } from 'utils/ModemSystemState'

const columns = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Number',
    key: 'number',
  },
  {
    title: 'Type',
    key: 'type',
  },
  {
    title: 'Site',
    render: record => (
      <Link to={`/sites/${record.siteId}`}>{record.siteName}</Link>
    ),
    externalLink: true,
  },
  {
    title: 'Created at',
    render: record => formatDateTime(record.createdAt),
  },
  {
    title: 'Status',
    render: record => <Tag>{record.status}</Tag>,
  },
  {
    title: 'Connection',
    render: record =>
      record.connectionState ? (
        record.connectionState === ModemSystemState.SystemConnected ? (
          <CircleStatusIndicator color={ColorAckGreen} />
        ) : (
          <CircleStatusIndicator color={ColorFireRed} />
        )
      ) : (
        <CircleStatusIndicator color={ColorSage} />
      ),
  },
]

export const SiteSystems = withRouter(({ siteId, history }) => {
  const { data, isLoading, error, refresh, ...rest } = withSiteSystems(siteId)()
  return (
    <>
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns}
        data={data}
        history={history}
        linkPath={'/systems'}
        {...rest}
      />
    </>
  )
})
