export const AccessLevels = {
  Monitor: 0,
  Operator: 1,
  Admin: 2,
  toLabel: accessLevel => {
    switch (accessLevel) {
      case AccessLevels.Admin:
        return 'Administrator'
      case AccessLevels.Operator:
        return 'System Operator'
      case AccessLevels.Monitor:
      default:
        return 'Monitor Only'
    }
  },
  toLangKey: accessLevel => {
    switch (accessLevel) {
      case AccessLevels.Admin:
        return 'user.permissions.al.admin'
      case AccessLevels.Operator:
        return 'user.permissions.al.operator'
      case AccessLevels.Monitor:
      default:
        return 'user.permissions.al.monitor'
    }
  },
}
