import React from 'react'

export const withIsMounted = ClassComponent => {
  return class WithSetStateSafeHOC extends React.Component {
    _isMounted = false

    componentDidMount = () => {
      this._isMounted = true
    }

    componentWillUnmount = () => {
      this._isMounted = false
    }

    isComponentMounted = () => {
      return this._isMounted
    }

    render() {
      return (
        <ClassComponent {...this.props} isMounted={this.isComponentMounted} />
      )
    }
  }
}
