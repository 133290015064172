import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table } from 'components/organisms'
import { UserStatus } from 'components/atoms'
import { AccessLevels } from 'utils/AccesLevels'

const columns = [
  {
    title: 'Name',
    render: record => `${record.firstName || ''} ${record.lastName || ''}`,
  },
  {
    title: 'E-mail',
    key: 'emailAddress',
  },
  {
    title: 'Access level',
    key: 'accessLevel',
    render: record => AccessLevels.toLabel(record.accessLevel),
  },
  {
    title: 'Status',
    key: 'status',
    render: record => <UserStatus status={record.status} />,
  },
]

export const SystemOperators = withRouter(({ operators, history }) => (
  <Table
    columns={columns}
    data={operators}
    history={history}
    linkPath={'/users'}
  />
))
