import styled from 'styled-components'
import { ColorWhite, ColorKoalaGrey } from 'style/design-tokens'

export const InputFilterStyled = styled.input`
  align-items: center;
  align-self: stretch;
  background-color: inherit;
  border: none;
  color: ${ColorWhite};
  display: flex;
  outline: none;
  width: 100%;
  ::placeholder {
    color: ${ColorKoalaGrey};
  }
`
