import React, { useState } from 'react'
import { MainLayout } from 'layout/MainLayout'
import { Typography, SizedBox } from 'components/atoms'
import { FormattedMessage } from 'react-intl'
import { FormProvider } from 'providers/FormProvider'
import { withRouter } from 'react-router'
import { ForgotPasswordForm } from './ForgotPasswordForm/ForgotPasswordForm'
import { ConfirmPasswordChangeForm } from './ConfirmPasswordChangeForm/ConfirmPasswordChangeForm'

export const ForgotPassword = withRouter(({ history }) => {
  const [isDone, setDone] = useState(false)
  const [emailAddress, setEmailAddress] = useState('')
  return (
    <MainLayout.FullPageScreen>
      {!isDone && (
        <>
          <Typography.H1>
            <FormattedMessage id="forgot-password.header" />
          </Typography.H1>
          <Typography.P>
            <FormattedMessage id="forgot-password.subtitle" />
          </Typography.P>
          <SizedBox height="3" />
          <FormProvider
            onSuccess={(_, fields) => {
              setEmailAddress(fields.emailAddress)
              setDone(true)
            }}
            url={'/v1/auth/request-reset-password'}
            render={({ updating, handleSubmit }) => (
              <ForgotPasswordForm
                loading={updating}
                onSubmit={handleSubmit}
                history={history}
              />
            )}
          />
        </>
      )}
      {isDone && (
        <>
          <Typography.H1>
            <FormattedMessage id="forgot-password.header" />
          </Typography.H1>
          <Typography.P>
            <FormattedMessage id="forgot-password.success" />
          </Typography.P>
          <SizedBox height="3" />
          <FormProvider
            additionalData={{ emailAddress }}
            onSuccess={() => {
              history.push('/login')
            }}
            url={'/v1/auth/reset-password'}
            render={({ updating, handleSubmit }) => (
              <ConfirmPasswordChangeForm
                loading={updating}
                onSubmit={handleSubmit}
              />
            )}
          />
        </>
      )}
    </MainLayout.FullPageScreen>
  )
})
