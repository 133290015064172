export const CITY_MIN_LENGTH = 2
export const COUNTRY_MIN_LENGTH = 2
export const ADDRESS_MIN_LENGTH = 2

export const POSTAL_CODE_MIN_LENGTH = 2

export const PASSWORD_MIN_LENGTH = 8
export const PASSWORD_MAX_LENGTH = 50

export const NAME_MIN_LENGTH = 1
export const NAME_MAX_LENGTH = 36
