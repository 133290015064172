import React from 'react'
import PropTypes from 'prop-types'
import { showSuccess, showError } from 'layout/notifications'
import { withRouter } from 'react-router-dom'
import { request } from 'api/request'
import { compose } from 'recompose'
import { path } from 'ramda'
import { withIsMounted } from 'hocs/withIsMounted'
import { injectIntl } from 'react-intl'

class FormProviderUnwrapped extends React.Component {
  state = {
    updating: false,
    error: '',
    data: this.props.data,
  }

  updateFormState = (updateObject, cb) => {
    this.setState(
      state => ({
        data: {
          ...state.data,
          ...updateObject,
        },
      }),
      cb,
    )
  }

  handleRequest = async payload => {
    try {
      this.setState({ updating: true, error: '' })
      const response = await this.makeRequest(
        this.props.mapFormDataToPayload(payload),
        this.props.queryParams,
      )
      this.setState({ updating: false }, () => {
        showSuccess(this.props.onSuccessMessage)
        this.updateFormState(payload, () => {
          this.props.onSuccess(
            response.data,
            this.props.mapFormDataToPayload(payload),
          )
        })
      })
    } catch (err) {
      const errMessage = path(['response', 'data', 'status'], err)
        ? `${err.response.data.status}: ${this.props.intl.formatMessage({
            id: err.response.data.statusText,
          })}`
        : err.message
      this.setState({ updating: false, error: errMessage }, () => {
        showError(errMessage)
      })
    }
  }

  makeRequest = (payload, params) => {
    const requestData = {
      method: this.props.method,
      url: this.props.url,
      params,
    }
    if (this.props.multipart) {
      requestData.data = payload
      requestData.headers = {
        ...requestData.headers,
        'content-type': 'multipart/form-data',
      }
    } else {
      requestData.data = {
        ...this.props.additionalData,
        ...payload,
      }
    }
    return request(requestData)
  }

  handleSubmit = async formValues => {
    const values = path(this.props.requestDataPath, formValues)
    await this.handleRequest(values)
  }

  render() {
    const { updating, error } = this.state
    return this.props.render({
      error,
      updating,
      handleSubmit: this.handleSubmit,
      formData: this.state.data,
    })
  }
}

FormProviderUnwrapped.defaultProps = {
  multipart: false,
  method: 'post',
  onSuccessMessage: 'Success!',
  onError: () => {},
  onSuccess: () => {},
  mapFormDataToPayload: val => val,
  requestDataPath: [],
  data: {},
  additionalData: {},
}

FormProviderUnwrapped.propTypes = {
  multipart: PropTypes.bool,
  url: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
  isMounted: PropTypes.func.isRequired,
  data: PropTypes.object,
  additionalData: PropTypes.object,
  method: PropTypes.string,
  onSuccessMessage: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  mapFormDataToPayload: PropTypes.func,
  requestDataPath: PropTypes.arrayOf(PropTypes.string),
}

export const FormProvider = compose(
  withIsMounted,
  injectIntl,
  withRouter,
)(FormProviderUnwrapped)
