import styled from 'styled-components'
import { SizeSpacing07Rem } from 'style/design-tokens'

export const PaginationContainer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding: ${SizeSpacing07Rem}rem;
`
