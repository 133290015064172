import React from 'react'
import { MainLayout } from 'layout/MainLayout'
import { ChangePassword } from './ChangePassword/ChangePassword'
import { Tabs } from 'components/molecules/Tabs/Tabs'
import { EditPersonalDetails } from './EditPersonalDetails/EditPersonalDetails'
import { withRequester } from 'api'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { updateInfo } from 'rdx/actions/authActions'
import { DeleteAccount } from './DeleteAccount/DeleteAccount'

const SettingsTabs = {
  ChangePassword: '0',
  EditDetails: '1',
  DeleteAccount: '2',
}

export const Settings = connect(null, { updateInfo })(
  withRouter(({ updateInfo }) => {
    const tab = (window.location.hash || SettingsTabs.ChangePassword).replace(
      '#',
      '',
    )
    const setIndex = value => (window.location.hash = value)
    const { isInitialLoading, isLoading, pick, data, refresh } = withRequester()

    updateInfo(data)

    return (
      <MainLayout header="Settings" isLoading={isInitialLoading}>
        <Tabs>
          <Tabs.Tab
            onClick={() => setIndex(SettingsTabs.ChangePassword)}
            selected={tab === SettingsTabs.ChangePassword}
            testId="change-password-tab"
          >
            Change Password
          </Tabs.Tab>
          <Tabs.Tab
            onClick={() => setIndex(SettingsTabs.EditDetails)}
            selected={tab === SettingsTabs.EditDetails}
            testId="edit-personal-details-tab"
          >
            Edit personal details
          </Tabs.Tab>
          <Tabs.Tab
            onClick={() => setIndex(SettingsTabs.DeleteAccount)}
            selected={tab === SettingsTabs.DeleteAccount}
            testId="delete-account-tab"
          >
            Delete account
          </Tabs.Tab>
        </Tabs>
        {tab === SettingsTabs.ChangePassword && <ChangePassword />}
        {tab === SettingsTabs.EditDetails && !isLoading && (
          <EditPersonalDetails refresh={refresh} pick={pick} />
        )}
        {tab === SettingsTabs.DeleteAccount && <DeleteAccount />}
      </MainLayout>
    )
  }),
)
