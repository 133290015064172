import React from 'react'
import { reset } from 'redux-form'
import { store } from 'rdx/store'
import { Spacing, Typography, Divider } from 'components/atoms'
import { FormProvider } from 'providers/FormProvider'
import { EditScannedSystemForm } from './EditScannedSystemFrom'
import { FormattedMessage } from 'react-intl'

const getInitialValue = pick => ({
    name: pick(['name']),
})

export const EditScannedSystem = ({ refresh, systemId, pick }) => (
    <>
        <Divider />
        <Spacing width="100%" mt="3" ml="2">
            <Typography.H3><FormattedMessage id='scanned.system.tab.edit.details' /></Typography.H3>
            <FormProvider
                method="put"
                onSuccess={() => {
                    refresh()
                    store.dispatch(reset('edit-scanned-system-form'))
                }}
                url={`/v1/scanned-systems/${systemId}`}
                render={({ updating, handleSubmit }) => (
                    <EditScannedSystemForm
                        loading={updating}
                        onSubmit={handleSubmit}
                        initialValues={getInitialValue(pick)}
                    />
                )}
            />
        </Spacing>
    </>
)
