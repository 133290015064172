import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Typography } from 'components/atoms'
import { SizeSpacing07Rem, ColorWhite } from 'style/design-tokens'

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 10%;
  color: ${ColorWhite};
  & > button {
    margin-top: ${SizeSpacing07Rem}rem;
    width: 10%;
  }
  & > img {
    margin-bottom: ${SizeSpacing07Rem}rem;
  }
  & > h2 {
    opacity: 0.7;
    margin-bottom: 0;
  }
`

export const NoElementsInfo = ({
  imgPath,
  buttonModal,
  information,
  description,
}) => {
  return (
    <Container>
      <img src={imgPath} alt="" />
      <Typography.H2>
        <FormattedMessage id={information} />
      </Typography.H2>
      <Typography.H1>
        <FormattedMessage id={description} />
      </Typography.H1>
      {buttonModal}
    </Container>
  )
}
