import React from 'react'
import styled from 'styled-components'
import { Typography } from 'components/atoms/Typography/Typography'
import { Spacing } from 'components/atoms/Spacing/Spacing'
import { Icon } from 'components/atoms/Icon/Icon'
import { withRouter } from 'react-router'
import {
  ColorAlmostBlack,
  ColorCharcoalGrey,
  ColorWhite,
  SizeFontSmall,
  FontWeightSemibold,
  SizeSpacing03Rem,
  SizeSpacing07Rem,
  ColorBlack,
} from 'style/design-tokens'
import { FormattedMessage } from 'react-intl'

const Container = styled.div`
  height: 100%;
`

const DropdownContainer = styled.div`
  position: relative;
  height: 0;
  width: 100%;
  bottom: 0;
`

const OptionsList = styled.div`
  position: absolute;
  top: 0;
  background-color: ${ColorCharcoalGrey};
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Button = styled.div`
  cursor: pointer;
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-left: 1px solid black;
  background-color: ${props =>
    props.expanded ? ColorAlmostBlack : ColorCharcoalGrey};
`

const OptionButton = styled.button`
  width: 100%;
  background: none;
  border: none;
  color: ${ColorWhite};
  cursor: pointer;
  display: block;
  font-size: ${SizeFontSmall}px;
  font-weight: ${FontWeightSemibold};
  font: inherit;
  outline: inherit;
  padding: ${SizeSpacing03Rem}rem ${SizeSpacing07Rem}rem;
  border-bottom: 1px solid ${ColorBlack};
  ${({ isActive }) => isActive && `background-color: ${ColorBlack};`}
`

class MenuDropdownUnwrapped extends React.Component {
  state = {
    expanded: false,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick)
  }

  handleClick = event => {
    if (
      !this.containerEl.contains(event.target) &&
      this.state.expanded === true
    ) {
      this.setState({ expanded: false })
    }
  }

  toggleDropdown = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const { user, options, history } = this.props
    const { expanded } = this.state
    return (
      <Container ref={ref => (this.containerEl = ref)}>
        <Button
          onClick={this.toggleDropdown}
          expanded={expanded}
          data-test-id="dropdown-settings-button"
        >
          <Spacing ml="1" mr="1">
            <Icon type="userRegular" />
          </Spacing>
          <Typography.P
            style={{ margin: 0 }}
          >{`${user.firstName} ${user.lastName}`}</Typography.P>
          <Spacing ml="1" mr="1">
            <Icon type={expanded ? 'caretUp' : 'caretDown'} />
          </Spacing>
        </Button>
        <DropdownContainer>
          {expanded && (
            <OptionsList data-test-id="dropdown-options-list">
              {options.map(
                (option, index) =>
                  option.label && (
                    <OptionButton
                      key={index}
                      isActive={this.props.match.path === option.value}
                      onClick={() => {
                        history.push(option.value)
                      }}
                    >
                      <FormattedMessage id={option.label} />
                    </OptionButton>
                  ),
              )}
            </OptionsList>
          )}
        </DropdownContainer>
      </Container>
    )
  }
}

export const MenuDropdown = withRouter(MenuDropdownUnwrapped)
