import React, { useState } from 'react'
import { Button, Icon, Spacing, Row, Column } from 'components/atoms'
import { FormattedMessage } from 'react-intl'

export const Explication = ({ total, content }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <Column alignItems="start">
      <div>
        <FormattedMessage id="explication.total" />: {total}
      </div>
      <Button.Link onClick={() => setExpanded(!expanded)}>
        {expanded ? (
          <Row>
            <FormattedMessage id="explication.hide.details" />
            <Icon type="angleUp" />
          </Row>
        ) : (
          <Row>
            <FormattedMessage id="explication.view.details" />
            <Icon type="angleDown" />
          </Row>
        )}
      </Button.Link>
      {expanded && (
        <div>
          <Spacing mb={0.5} />
          <>{content}</>
        </div>
      )}
    </Column>
  )
}
