import React from 'react'
import { Select } from 'components/atoms'
import styled from 'styled-components'
import { SizeSpacing03Rem } from 'style/design-tokens'

export const PaginationSelectContainer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: ${SizeSpacing03Rem}rem;
`

export const PaginationSelect = ({
  onChange,
  sortingParams,
  filteringParams,
  pageSize,
}) => {
  return (
    <PaginationSelectContainer>
      <Select.Dark
        value={pageSize}
        width="100px"
        onChange={e => {
          onChange(
            {
              pageSize: Number(e.target.value),
              current: 0,
            },
            filteringParams,
            sortingParams,
          )
        }}
      >
        <Select.Option value={10}>10</Select.Option>
        <Select.Option value={25}>25</Select.Option>
        <Select.Option value={50}>50</Select.Option>
        <Select.Option value={100}>100</Select.Option>
      </Select.Dark>
    </PaginationSelectContainer>
  )
}
