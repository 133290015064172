import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { Field, reduxForm } from 'redux-form'
import { Form, Button, SizedBox, Row, DatePicker } from 'components/atoms'
import compose from 'recompose/compose'

const GenerateSystemUnitReportFormUnwrapped = ({
  handleSubmit,
  loading,
  pristine,
  invalid,
  refresh,
}) => {
  const nowDate = new Date()
  let intl = useIntl()
  const autronicaBirthday = new Date('1/1/98')
  return (
    <Form onSubmit={handleSubmit}>
      <Row alignItems="end">
        <Row width="60%">
          <Form.Date width="20%">
            <Form.Label>
              <FormattedMessage id="reports.form.input.produced.before.optional" />
            </Form.Label>
            <SizedBox height="1" />
            <Field
              component={DatePicker}
              maxDate={nowDate}
              minDate={autronicaBirthday}
              showMonthYearPicker
              name={'producedBefore'}
              placeholderText={intl.formatMessage({
                id: 'reports.form.date.input.select.date',
              })}
              dateFormat={'dd-MM-yyyy'}
              testId="generate-event-report-form-produced-before-date-input"
              onChange={value => {
                if (!value) {
                  refresh()
                }
              }}
            />
          </Form.Date>
        </Row>
        <Button
          type="submit"
          disabled={invalid || pristine}
          data-test-id="generate-event-report-form-submit"
        >
          <FormattedMessage id="reports.form.button.generate.report" />{' '}
          {loading ? '...' : ''}
        </Button>
      </Row>
    </Form>
  )
}

GenerateSystemUnitReportFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
}

export const GenerateSystemUnitReportForm = compose(
  reduxForm({
    form: 'generate-system-event-report-form',
  }),
)(GenerateSystemUnitReportFormUnwrapped)
