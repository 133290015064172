import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  ColorMalachite,
  ColorOceanBlue,
  ColorPistachio,
  ColorFireRed,
  ColorWhite,
  FontWeightSemibold,
  SizeSpacing01Rem,
  SizeSpacing02Rem,
  ColorSeaBlue,
} from 'style/design-tokens'
import { Spin } from '../Spin/Spin'
import { Spacing } from '../Spacing/Spacing'

const getBackgroundColor = buttonType => {
  switch (buttonType) {
    case 'secondary':
      return ColorPistachio
    case 'primary':
      return ColorMalachite
    case 'warning':
      return ColorFireRed
    default:
      return ColorMalachite
  }
}

const getTextColor = buttonType => {
  switch (buttonType) {
    case 'secondary':
      return ColorMalachite
    case 'primary':
    case 'warning':
    default:
      return ColorWhite
  }
}

const Link = styled.button`
  border: none;
  border-spacing: 0;
  border: none;
  color: ${ColorOceanBlue};
  font-size: ${SizeSpacing01Rem};
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-indent: 0;
  padding: 0;
  border: none;
  font-size: ${SizeSpacing01Rem};
  color: ${ColorSeaBlue};
  background-color: inherit;
  cursor: pointer;
`

const ButtonContainer = styled.button`
  width: ${({ width }) => (width ? width : 'auto')};
  background-color: ${({ buttonType }) => getBackgroundColor(buttonType)};
  border-radius: 1px;
  border-spacing: 0;
  border: none;
  color: ${({ buttonType }) => getTextColor(buttonType)};
  display: flex;
  font-size: ${SizeSpacing02Rem};
  font-weight: ${FontWeightSemibold};
  justify-content: space-evenly;
  align-items: center;
  outline: none;
  padding: 0 1.8rem;
  height: 38px;
  text-decoration: none;
  text-indent: 0;
  & svg:first-child {
    margin-right: 0.8rem;
  }
  &:disabled {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.5;
  }
`

export const Button = ({ onClick, children, loading, ...rest }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  return (
    <ButtonContainer
      onClick={async () => {
        const maybePromise = onClick()
        if (maybePromise?.then) {
          setIsSubmitting(true)
          try {
            await maybePromise
            setIsSubmitting(false)
          } catch (err) {
            setIsSubmitting(false)
            throw err
          }
        }
      }}
      {...rest}
    >
      <Spacing mr={isSubmitting ? '0.6' : '0'}>{children}</Spacing>
      {(loading || isSubmitting) && <Spin size="2" />}
    </ButtonContainer>
  )
}

Button.Link = Link

Button.propTypes = {
  'data-test-id': PropTypes.string.isRequired,
  buttonType: PropTypes.oneOf(['primary', 'secondary', 'warning']),
}

Button.defaultProps = {
  buttonType: 'primary',
  onClick: () => false,
}
