import React, { useState } from 'react'
import Autosuggest from 'react-autosuggest'
import { useIntl } from 'react-intl'
import { StyledInputWithSuggestions } from './StyledInputWithSuggestions'

export const InputWithSuggestions = props => {
  const {
    data,
    createSuggestion,
    placeholder,
    refresh,
    width,
    maxHeight,
  } = props
  const onValueChanged = props.input.onChange

  let intl = useIntl()
  const [suggestions, setSuggestions] = useState([])
  const [query, setQuery] = useState('')

  const getSuggestionValue = suggestion => suggestion.value
  const renderSuggestion = suggestion => (
    <div>{suggestion.value || suggestion}</div>
  )

  const prepareSuggestions = value => {
    if (data) {
      const foundedSuggestions = data.filter(element => {
        if (element) {
          if (typeof element === 'string' || element instanceof String) {
            return element.toLowerCase().includes(value.toLowerCase())
          } else if (element instanceof Object && element.name) {
            return element.name.toLowerCase().includes(value.toLowerCase())
          } else {
            return false
          }
        }
        return false
      })
      setSuggestions(
        foundedSuggestions.map(element =>
          createSuggestion ? createSuggestion(element) : element.name,
        ),
      )
    }
  }

  const findObjectIdByQuery = query => {
    const result = suggestions.find(suggestion => suggestion.value === query)
    if (result) {
      return result.id
    } else return ''
  }

  return (
    <StyledInputWithSuggestions width={width} maxHeight={maxHeight}>
      <Autosuggest
        value={query}
        suggestions={suggestions}
        highlightFirstSuggestion={true}
        onSuggestionsFetchRequested={({ value }) => prepareSuggestions(value)}
        onSuggestionsClearRequested={() => setSuggestions([])}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
          placeholder: placeholder
            ? placeholder
            : intl.formatMessage({
                id: 'button.input.search',
              }),
          value: query,
          onChange: (_e, { newValue }) => {
            if (!newValue) {
              refresh()
            }
            setQuery(newValue)
            onValueChanged(findObjectIdByQuery(newValue))
          },
        }}
      />
    </StyledInputWithSuggestions>
  )
}
