import React from 'react'
import { withRouter } from 'react-router-dom'
import { Error, Tag, Link } from 'components/atoms'
import { Table } from 'components/organisms'
import { withUserSystems } from 'api'
import { formatDateTime } from 'utils/formatters'

const columns = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Number',
    key: 'number',
  },

  {
    title: 'Type',
    key: 'type',
  },
  {
    title: 'Site',
    render: record => (
      <Link to={`/sites/${record.siteId}`}>{record.siteName}</Link>
    ),
    externalLink: true,
  },
  {
    title: 'Created at',
    render: record => formatDateTime(record.createdAt),
  },
  {
    title: 'Status',
    render: record => <Tag>{record.status}</Tag>,
  },
]

export const UserSystems = withRouter(({ userId, history }) => {
  const { data, isLoading, error, refresh, ...rest } = withUserSystems(userId)()
  return (
    <>
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns}
        data={data}
        history={history}
        linkPath={'/systems'}
        {...rest}
      />
    </>
  )
})
