import React from 'react'
import { Spacing, Divider } from 'components/atoms'
import { FormProvider } from 'providers/FormProvider'
import { store } from 'rdx/store'
import { reset } from 'redux-form'
import { EditModemForm } from './EditModemForm'

const getInitialValues = pick => ({
  clientId: pick(['clientId']),
  iccid: pick(['iccid']),
  imei: pick(['imei']),
  imsi: pick(['imsi']),
  wgip: pick(['wgip']),
  ts: pick(['ts']),
  lat: pick(['lat']),
  lng: pick(['lng']),
})

export const EditModem = ({ refresh, modemId, pick }) => (
  <>
    <Divider />
    <Spacing width="100%" mt="3" ml="2">
      <FormProvider
        method="put"
        onSuccess={() => {
          refresh(() => {
            store.dispatch(reset('edit-modem-form'))
          })
        }}
        url={`/v1/modems/${modemId}`}
        render={({ updating, handleSubmit }) => (
          <EditModemForm
            loading={updating}
            onSubmit={handleSubmit}
            initialValues={getInitialValues(pick)}
          />
        )}
      />
    </Spacing>
  </>
)
