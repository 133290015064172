import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table } from 'components/organisms'
import { Error, UserStatus } from 'components/atoms'
import { withUsers } from 'api'

const columns = [
  {
    title: 'Name',
    key: 'firstName',
    sortable: true,
    searchable: true,
    render: record => <>{`${record.firstName} ${record.lastName}`}</>,
  },
  {
    title: 'Number',
    key: 'number',
    sortable: true,
    searchable: true,
  },
  {
    title: 'E-mail',
    key: 'emailAddress',
    sortable: true,
    searchable: true,
  },
  {
    title: 'Status',
    key: 'status',
    sortable: true,
    searchable: true,
    render: record => <UserStatus status={record.status} />,
  },
]

export const UsersList = withRouter(({ history }) => {
  const {
    data,
    isLoading,
    error,
    pagination,
    sorter,
    filters,
    onChange,
    ...rest
  } = withUsers()
  return (
    <>
      {error && <Error error={error} />}
      <Table
        isLoading={isLoading}
        columns={columns}
        data={data}
        pagination={pagination}
        sortingParams={sorter}
        filteringParams={filters}
        onChange={onChange}
        linkPath={'/users'}
        history={history}
        {...rest}
      />
    </>
  )
})
