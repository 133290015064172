import React from 'react'
import { FormattedMessage } from 'react-intl'
import { exportRawConfiguration } from 'api'
import { Button } from 'components/atoms'

const downloadBz2File = ({ data, fileName }) => {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

export const DownloadRawConfigurationFile = ({ configurationId }) => (
  <Button.Link
    data-test-id="download-raw-link"
    onClick={async () => {
      const { data, attachmentName, error } = await exportRawConfiguration(
        configurationId,
      )
      let fileName = attachmentName || 'raw.tar.bz2'
      if (!error) {
        downloadBz2File({
          data: data,
          fileName: fileName,
        })
      } else {
        throw error
      }
    }}
  >
    <FormattedMessage id="link.raw" />
  </Button.Link>
)
