import styled from 'styled-components'
import {
  ColorCloudy,
  ColorSage,
  FontWeightSemibold,
  SizeFontSmall,
  SizeSpacing03Rem,
} from 'style/design-tokens'

export const PaginationButton = styled.button`
  -webkit-appearance: none;
  align: center;
  background-color: inherit;
  border: none;
  color: ${({ active }) => (active ? ColorCloudy : ColorSage)};
  display: inline-block;
  font-size: ${SizeFontSmall};
  font-weight: ${FontWeightSemibold};
  outline: none;
  padding: ${SizeSpacing03Rem}rem;
  text-align: center;

  :disabled {
    opacity: ${({ active }) => (active ? '0.8' : '0.5')};
  }
`
