import React from 'react'
import styled from 'styled-components'
import { ColorCharcoalGrey, ColorCloudy } from 'style/design-tokens'

const Container = styled.div`
  padding: 1rem 1.5rem;
  background-color: ${ColorCharcoalGrey};
  color: ${ColorCloudy};
`

const Title = styled.div`
  padding-top: 0.5rem;
  color: ${ColorCloudy};
  font-size: 14px;
`

const Value = styled.div`
  padding-top: 0.5rem;
  padding-left: 0.1rem;
  font-size: 3rem;
  font-size: 34px;
`

export const Statistic = ({ title, value }) => (
  <Container>
    <Title>{title}</Title>
    <Value>{value}</Value>
  </Container>
)
