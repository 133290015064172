import React, { useState } from 'react'
import { FormProvider } from 'providers/FormProvider'
import { Icon, Button } from 'components/atoms'
import { Modal } from 'components/molecules'
import { AddLoopForm } from './AddLoopForm'
import { FormattedMessage } from 'react-intl'

export const AddLoopModal = ({ refresh, systemId }) => {
    const [isOpen, onChange] = useState(false)
    return (
        <>
            <Button
                data-test-id="add-loop-button"
                buttonType="primary"
                onClick={() => onChange(true)}
            >
                <Icon type="plus" /> <FormattedMessage id={'modal.add.button'} />
            </Button>
            <Modal
                title={<FormattedMessage id={'add.loop.modal.title'} />}
                isOpen={isOpen}
                onRequestClose={() => {
                    onChange(false)
                }}
            >
                {isOpen && (
                    <FormProvider
                        onSuccess={() => {
                            refresh()
                            onChange(false)
                        }}
                        url={`/v1/scanned-systems/${systemId}/scanned-loops`}
                        render={({ updating, handleSubmit }) => (
                            <AddLoopForm
                                onCancel={() => {
                                    onChange(false)
                                }}
                                onSubmit={values => {
                                    handleSubmit(values)
                                }}
                                loading={updating}
                            />
                        )}
                    />
                )}
            </Modal>
        </>
    )
}
