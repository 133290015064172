import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { Field, reduxForm } from 'redux-form'
import { Form, Button, SizedBox, Row, DatePicker } from 'components/atoms'
import { InputWithSuggestions } from 'components/molecules'
import compose from 'recompose/compose'

const GenerateUnitReportFormUnwrapped = ({
  handleSubmit,
  loading,
  pristine,
  invalid,
  data,
  createSuggestion,
  refresh,
}) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const nowDate = new Date()
  const autronicaBirthday = new Date('1/1/98')
  let intl = useIntl()
  return (
    <Form onSubmit={handleSubmit}>
      <Row alignItems="end">
        <Row width="80%">
          <Form.Item width="20%">
            <Form.Label>
              <FormattedMessage id="reports.form.label.unit.type" />
            </Form.Label>
            <SizedBox height="1" />
            <Field
              component={InputWithSuggestions}
              name={'unitType'}
              data={data}
              createSuggestion={createSuggestion}
              testId="generate-report-to-user-group-form-object-input"
              placeholder={intl.formatMessage({
                id: 'reports.form.input.enter.unit.type',
              })}
              refresh={() => {
                refresh()
              }}
            />
          </Form.Item>
          <Form.Date width="20%">
            <Form.Label>
              <FormattedMessage id="reports.form.input.start.date" />
            </Form.Label>
            <SizedBox height="1" />
            <Field
              component={DatePicker}
              onChange={date => {
                setStartDate(date)
              }}
              value={startDate}
              minDate={autronicaBirthday}
              maxDate={endDate ?? nowDate}
              placeholderText={intl.formatMessage({
                id: 'reports.form.date.input.select.start',
              })}
              name={'startDate'}
              testId="generate-report-to-user-group-form-object-input"
            />
          </Form.Date>
          <SizedBox width="2" />
          <Form.Date width="20%">
            <Form.Label>
              <FormattedMessage id="reports.form.input.end.date" />
            </Form.Label>
            <SizedBox height="1" />
            <Field
              component={DatePicker}
              selected={endDate ?? null}
              value={endDate ?? null}
              minDate={startDate}
              maxDate={nowDate}
              onChange={date => {
                if (date) {
                  date.setHours(23, 59, 59)
                }
                setEndDate(date)
              }}
              name={'endDate'}
              placeholderText={intl.formatMessage({
                id: 'reports.form.date.input.select.end',
              })}
              dateFormat={'dd-MM-yyyy'}
              testId="generate-report-to-user-group-form-object-input"
            />
          </Form.Date>
        </Row>
        <Button
          type="submit"
          disabled={invalid || pristine}
          data-test-id="generate-report-to-user-group-form-submit"
        >
          <FormattedMessage id="reports.form.button.generate.report" />{' '}
          {loading ? '...' : ''}
        </Button>
      </Row>
    </Form>
  )
}

GenerateUnitReportFormUnwrapped.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
}

export const GenerateUnitReportForm = compose(
  reduxForm({
    form: 'generate-unit-report-form',
  }),
)(GenerateUnitReportFormUnwrapped)
