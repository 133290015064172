import React from 'react'
import { Typography } from 'components/atoms/Typography/Typography'
import { Spacing } from 'components/atoms/Spacing/Spacing'
import { StyledDropdown } from './styled/StyledDropdown'
import { DropdownIndicator, Option } from './DropdownComponents'

export const FormDropdown = ({
  meta: { dirty, error, warning },
  input,
  options,
  label,
  mapOptions,
  testId,
  ...rest
}) => (
  <div data-test-id={testId}>
    <StyledDropdown
      {...rest}
      disabled={input.disabled}
      onChange={option => input.onChange(option.value)}
      value={options.find(option => option.value === input.value)}
      options={options.map(mapOptions)}
      components={{ DropdownIndicator, Option }}
      classNamePrefix="react-select"
      className="react-select-container"
    />
    <Spacing mt="0.6">
      {dirty &&
        ((error && (
          <Typography.Small size="1.2rem" type="danger">
            {error}
          </Typography.Small>
        )) ||
          (warning && (
            <Typography.Small size="1.2rem" type="warning">
              {warning}
            </Typography.Small>
          )))}
    </Spacing>
  </div>
)

FormDropdown.defaultProps = {
  mapOptions: option => option,
}
