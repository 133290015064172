import Select from 'react-select'
import styled from 'styled-components'
import {
  ColorWhite,
  ColorPistachio,
  ColorMalachite,
  SizeFontSmall,
  FontWeightRegular,
  ColorMisty,
  FontWeightSemibold,
} from 'style/design-tokens'

export const StyledDropdown = styled(Select)`
  .react-select__control {
    background-color: ${ColorWhite};
    overflow: hidden;
    border-style: solid;
    border-width: 1px;
    border-color: ${ColorPistachio};
    box-shadow: none;
    margin: 0.2rem 0;

    &:hover {
      border-color: ${ColorPistachio};
    }

    &--is-disabled {
      opacity: 0.5;
    }

    &--menu-is-open {
      border-color: ${ColorMalachite};

      &:hover {
        border-color: ${ColorMalachite};
      }

      .react-select__placeholder {
        font-weight: ${FontWeightSemibold};
      }

      .react-select__single-value {
        font-weight: ${FontWeightSemibold};
      }
    }
  }

  .react-select__value-container {
    background-color: ${ColorWhite};
    padding-left: 2rem;
  }

  .react-select__single-value {
    font-size: ${SizeFontSmall};
    font-weight: ${FontWeightRegular};
    color: ${ColorMalachite};
  }

  .react-select__multi-value {
    background-color: ${ColorMalachite};

    &__label {
      color: ${ColorWhite};
    }

    &__remove:hover {
      background-color: ${ColorPistachio};
      color: ${ColorMalachite};
    }

    border-radius: 1rem;
    overflow: hidden;
  }

  .react-select__dropdown-indicator {
    color: ${ColorMalachite};
    padding-right: 2.5rem;

    &:hover {
      color: ${ColorMalachite};
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__clear-indicator {
    display: none;
  }

  .react-select__placeholder {
    font-size: ${SizeFontSmall};
    font-weight: ${FontWeightRegular};
    color: ${ColorMalachite};
    text-align: left;

    &--is-disabled {
      opacity: 0.5;
    }
  }

  .react-select__menu {
    margin-left: 3%;
    width: 94%;
  }

  .react-select__option {
    background-color: ${ColorWhite};
    border-color: ${ColorMisty};
    font-size: ${SizeFontSmall};
    font-weight: ${FontWeightRegular};
    color: ${ColorMalachite};
    box-shadow: 0px 2px 4px rgba(50, 50, 50, 0.2);
    line-height: 175%;

    &--is-selected {
      background-color: ${ColorMalachite};
      color: ${ColorWhite};

      svg {
        color: ${ColorWhite};
        margin-top: 3px;
        float: right;
      }
    }

    &--is-disabled {
      opacity: 0.5;
    }
  }
`
